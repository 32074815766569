import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIsMobileResolution } from '../../../hooks/useIsMobileResolution';
import MusicianImage from '../MusicianImage/MusicianImage.js';
import Icon from '../../Atoms/Icon/Icon';
import StarRating from '../../Atoms/StarRating/StarRating';
import avatarImg from '../../../../assets/images/avatar.png';
import classNames from 'classnames';
import { SessionStatus } from '../../../enums/session-status.enum';
import { customerStatusMapping, partnerStatusMapping } from '../../../views/main/components/sessions/sessions.const';
import { CustomerSessionDetailsTabs } from '../../../enums/customer-session-details-tabs.enum';
import { selectIsPartnersDashboard } from '../../../store/slices/global.slice';
import { selectUser } from '../../../store/slices/user.slice';
import { selectLimitations } from '../../../store/slices/limitations.slice';
import {
  setIsShowRatingModal
} from '../../../store/slices/modals.slice';
import styles from './SessionCard.module.scss';
import { tooltipContents } from './SessionCard.const';

const downloadFilesButton = ({
  onUploadFilesButtonClick,
  isTooltipShown,
  tooltipContent,
  statusClass
}: {
  onUploadFilesButtonClick: any;
  isTooltipShown: any;
  tooltipContent: any;
  statusClass: any;
}): ReactElement => (
  <button
    onClick={onUploadFilesButtonClick}
    data-tip={isTooltipShown ? tooltipContent : ''}
    className={classNames(styles.mobileSessionStatus, styles[statusClass])}
  >
    <span className={styles.fileUploadIcon}>Download Files <Icon name='green-download-arrow' width={18} height={18} viewBox='0 0 10 18' /></span>
  </button>
);

const renderActionItem = ({
  key,
  isMobileResolution,
  isPartnersDashboard,
  isTooltipShown,
  tooltipContent,
  statusMapping,
  onUploadFilesButtonClick,
  onOpenRatingButtonClick,
  status,
  sessionType,
  rating,
  limitations,
  mode,
  hasLimitations,
  pendingFilesDelivery,
  expiredDownload
}: {
  key?: number;
  isMobileResolution: boolean;
  isPartnersDashboard: boolean;
  isTooltipShown: boolean;
  tooltipContent: string;
  statusMapping: any;
  onUploadFilesButtonClick: any;
  onOpenRatingButtonClick: any;
  rating: any;
  limitations: any;
  status: string;
  sessionType: string;
  mode?: string;
  hasLimitations: boolean;
  pendingFilesDelivery: boolean;
  expiredDownload: boolean;
}): ReactElement => {
  const showRating = sessionType == 'past' && status != 'cancelled';
  const statusClass = Object.keys(statusMapping).includes(status) ? statusMapping[status][0] : 'withRating';
  const showDownload = [SessionStatus.PARTNER_DELIVERABLES_READY].includes(status);
  return (
    isPartnersDashboard ? (
      status === SessionStatus.PARTNER_UPLOAD_REQUIRED ? (
        <div
          className={classNames(styles.actionIcons)}
        >
          <button
            onClick={onUploadFilesButtonClick}
            className={styles.actionButton}
          >
            Upload files <span className={styles.fileUploadIcon}><Icon name='upload-icon' width={18} height={18} viewBox='0 0 10 18' /></span>
          </button>
        </div>
      ) : (
        <div
          data-tip={isTooltipShown ? tooltipContent : ''}
          className={classNames(styles.sessionStatus, styles[statusClass], styles.partnerView)}
        >
          {statusMapping[status][1]}
        </div>
      )
    ) : (
      showDownload || showRating ? (
        isMobileResolution ? (
          <div className={styles.mobileActionIcons}>
            {showDownload && !hasLimitations && (
              <button
                onClick={onUploadFilesButtonClick}
                data-tip={isTooltipShown ? tooltipContent : ''}
                className={classNames(styles.mobileSessionStatus, styles[statusClass])}
              >
                <span className={styles.fileUploadIcon}>Download Files <Icon name='green-download-arrow' width={18} height={18} viewBox='0 0 10 18' /></span>
              </button>
            )}
            {showDownload && hasLimitations && (
              pendingFilesDelivery || expiredDownload ? (
                <button
                  onClick={onUploadFilesButtonClick}
                  data-tip={isTooltipShown ? tooltipContent : ''}
                  className={classNames(styles.mobileSessionStatus, styles.restrictedDownload)}
                >
                  {pendingFilesDelivery && (
                    <>
                      <span>Processing files...</span><Icon name='grey-clock' className={styles.restrictedIcon} />
                    </>
                  )}
                  {expiredDownload && (
                    <>
                      <span>Files expired</span><Icon name='x-square' className={styles.restrictedIcon} />
                    </>
                  )}
                </button>
              ) : (
                <button
                  onClick={onUploadFilesButtonClick}
                  data-tip={isTooltipShown ? tooltipContent : ''}
                  className={classNames(styles.mobileSessionStatus, styles[statusClass])}
                >
                  <span className={styles.fileUploadIcon}>Download Files</span><Icon name='green-download-arrow' width={18} height={18} viewBox='0 0 10 18' />
                </button>
              )
            )}
            {showRating && (
              <StarRating
                key={key}
                rating={rating}
                onOpenRatingButtonClick={onOpenRatingButtonClick}
                isMobileResolution={isMobileResolution}
              />
            )}
          </div>
        ) : (
          <div className={styles.actionIcons}>
            {showDownload && !hasLimitations && (
              <button
                onClick={onUploadFilesButtonClick}
                data-tip={isTooltipShown ? tooltipContent : ''}
                className={classNames(styles.sessionStatus, styles[statusClass])}
              >
                <span className={styles.fileUploadIcon}><Icon name='green-download-arrow' width={18} height={18} viewBox='0 0 10 18' /></span>
              </button>
            )}
            {showDownload && hasLimitations && (
              pendingFilesDelivery || expiredDownload ? (
                <button
                  onClick={onUploadFilesButtonClick}
                  data-tip={isTooltipShown ? tooltipContent : ''}
                  className={classNames(styles.sessionStatus, styles.restrictedDownload)}
                >
                  {pendingFilesDelivery && (
                    <span className={styles.fileUploadIcon}><Icon name='grey-clock'/></span>
                  )}
                  {expiredDownload && (
                    <span className={styles.fileUploadIcon}><Icon name='x-square'/></span>
                  )}
                </button>
              ) : (
                <button
                  onClick={onUploadFilesButtonClick}
                  data-tip={isTooltipShown ? tooltipContent : ''}
                  className={classNames(styles.sessionStatus, styles[statusClass])}
                >
                  <span className={styles.fileUploadIcon}><Icon name='green-download-arrow' width={18} height={18} viewBox='0 0 10 18' /></span>
                </button>
              )
            )}
            {showRating && (
              <StarRating
                key={key}
                rating={rating}
                onOpenRatingButtonClick={onOpenRatingButtonClick}
              />
            )}
          </div>
        )
      ) : (
        statusMapping[status] != undefined && (
          <div
            data-tip={isTooltipShown ? tooltipContent : ''}
            className={classNames(styles.sessionStatus, styles[statusClass])}
          >
            {statusMapping[status][1]}
          </div>
        )
      )
    )
  );
};

const SessionCard = ({ id, rating, onClick, status, imageSrc, name, instrument, date, time, track, trackOptions, sessionType, start_date }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobileResolution = useIsMobileResolution();
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);
  const onUploadFilesButtonClick = () => history.push(`/sessions/${id}/${CustomerSessionDetailsTabs.FILES_MANAGEMENT}`);

  const user = useSelector(selectUser, shallowEqual);
  const limitations = useSelector(selectLimitations, shallowEqual);

  const endOfDeliveryRestriction = moment(start_date).add(parseInt(limitations?.express_files_delivery_time), 'minutes');
  const endOfDownloadRestriction = moment(start_date).add(parseInt(limitations?.files_expiration_time), 'days');
  const pendingFilesDelivery = endOfDeliveryRestriction > moment();
  const expiredDownload = endOfDownloadRestriction < moment();

  const onOpenRatingButtonClick = (e): void => {
    e.stopPropagation();
    dispatch(setIsShowRatingModal({ isShown: true, sessionId: id }));
  };

  const statusMapping = useMemo(() =>
    isPartnersDashboard ? partnerStatusMapping : customerStatusMapping, [isPartnersDashboard]);

  const isTooltipShown = useMemo(
    () => !isMobileResolution && ['files-required', SessionStatus.PARTNER_DELIVERABLES_READY].includes(status),
    [isMobileResolution, status]
  );

  return (
    <>
      <div
        key={id}
        onClick={onClick}
        className={classNames(styles.session, status === SessionStatus.CANCELLED && styles.isCancelled)}
      >
        <div className={styles.sessionMusician}>
          <div className={styles.sessionMusicianAvatar}>
            {isPartnersDashboard ? (
              <img
                src={imageSrc ? imageSrc : avatarImg}
                alt={name}
                width={40}
                height={40}
              />
            ) : (
              <MusicianImage
                id={name}
                avatar={`../../../../assets/images/musicians-avatars/${name}.png`}
                alt={name}
                width={40}
                height={40}
                altUrl={imageSrc}
              />
            )}
          </div>
          <div className={styles.sessionMusicianMeta}>
            <h3 className={styles.sessionMusicianInstrument}>{instrument}</h3>
            <p className={styles.sessionMusicianName}>{name}</p>
          </div>
        </div>
        <div className={styles.sessionTime}>
          <h3 className={styles.sessionTimeDate}>{date}</h3>
          <p className={styles.sessionTimeNumber}>{time}</p>
        </div>
        <div className={styles.sessionTrack}>
          <p className={styles.sessionTrackName}>{track}</p>
          {trackOptions && <p className={styles.trackOptions}>{trackOptions}</p>}
        </div>

        {renderActionItem({
            key: id,
            isMobileResolution: isMobileResolution,
            isPartnersDashboard: isPartnersDashboard,
            isTooltipShown: isTooltipShown,
            tooltipContent: tooltipContents[status],
            statusMapping: statusMapping,
            onUploadFilesButtonClick: onUploadFilesButtonClick,
            onOpenRatingButtonClick: onOpenRatingButtonClick,
            status: status,
            sessionType: sessionType,
            rating: rating,
            limitations: limitations,
            mode: 'oneline',
            hasLimitations: user.has_limitations ?? false,
            pendingFilesDelivery: pendingFilesDelivery,
            expiredDownload: expiredDownload
          })
        }
      </div>
    </>
  );
};

export default SessionCard;
