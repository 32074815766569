import classnames from 'classnames';
import React, { ReactElement, useMemo, useState } from 'react';

import { Colors } from '../../../enums/colors.enum';
import { StyledInputTypes } from '../../../enums/styled-input-types.enum';
import Icon from '../Icon/Icon';
import InputError from '../InputError/InputError';
import { StyledInputInterface } from './StyledInput.interface';
import styles from './StyledInput.module.scss';

const StyledInput = ({
  label,
  type,
  name,
  id,
  registerRef,
  errors,
  clearErrors,
  width,
  height,
  style,
  placeholder,
  children,
  className,
  isNoFocus
}: StyledInputInterface): ReactElement => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const toggleIsShowPassword = (): void => setIsShowPassword(!isShowPassword);

  const errs = useMemo((): string[] => errors?.filter(err => !!err), [errors]);

  return (
    <div className={classnames(styles.container, className)} style={style}>
      <label>{label}</label>
      <div className={classnames(styles.inputContainer, isNoFocus && styles.noFocus)}>
        <input
          style={{ width, height }}
          className={classnames(errs?.length && styles.hasErrors)}
          id={id}
          type={
            type === StyledInputTypes.PASSWORD && !isShowPassword ? StyledInputTypes.PASSWORD : StyledInputTypes.TEXT
          }
          placeholder={placeholder}
          name={name}
          onClick={clearErrors}
          {...registerRef}
        />
        {type === StyledInputTypes.PASSWORD && (
          <Icon
            className={styles.icon}
            style={{ bottom: isShowPassword ? '17.5px' : '19px' }}
            name={isShowPassword ? 'closed-eye' : 'open-eye'}
            onClick={toggleIsShowPassword}
          />
        )}
        {children}
      </div>
      {!!errs?.length && (
        <div className={styles.errors}>
          {errs.map((error, idx) => (
            <InputError key={`${error}_${idx}`} message={errors} color={Colors.ERROR} />
          ))}
        </div>
      )}
    </div>
  );
};

export default StyledInput;
