import moment from 'moment';

const format12 = 'h:mm a';
const format24 = 'HH:mm';

export const timeSystemConverterHelper = (time: string, isTarget24H: boolean): string => {
  let firstFormat = format12;
  let secondFormat = format24;

  if (!isTarget24H) {
    firstFormat = format24;
    secondFormat = format12;
  }

  return moment(time, firstFormat).format(secondFormat);
};
