import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { SignupSteps } from '../../../../enums/signup-steps.enum';
import { getSignupFlowStepCount } from '../../../../helpers/get-signup-flow-step-count';
import Icon from '../../../Atoms/Icon/Icon';
import { homeLink } from '../SignUpFlowNavbar.const';
import { SignUpFlowNavbarInterface } from '../SignUpFlowNavbar.interface';
import styles from './MobileSignUpFlowNavbar.module.scss';

const stepsTotalCount = Object.values(SignupSteps).length;
const getStepsCounterText = (counter: number): string => `${counter} of ${stepsTotalCount}`;

const MobileSignUpFlowNavbar = ({
  isPartnersDashboard,
  signUpStep,
  isFinalStep,
  steps
}: SignUpFlowNavbarInterface): ReactElement => (
  <div className={styles.container}>
    {isFinalStep && (
      <Link to={homeLink}>
        <span>
          <Icon name={'logo-long'} className={styles.logo} />
        </span>
      </Link>
    )}
    {!isPartnersDashboard && signUpStep && (
      <div className={styles.steps}>
        <div
          className={styles.progress}
          style={{ width: `${(getSignupFlowStepCount(signUpStep, steps) * 100) / stepsTotalCount}%` }}
        />
        <div key={signUpStep} className={classnames(styles.step, styles.activeStep)}>
          <span>{signUpStep}</span>
        </div>
        <span className={styles.counter}>{getStepsCounterText(getSignupFlowStepCount(signUpStep, steps))}</span>
      </div>
    )}
  </div>
);

export default MobileSignUpFlowNavbar;
