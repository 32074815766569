import React, { ReactElement, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Routes } from '../../../enums/routes.enum';
import { ModalInterface } from '../../../interfaces/modal.interface';
import { selectSession, setSession } from '../../../store/slices/sessions.slice';
import { setIsShowRatingModal, selectRatingModal } from '../../../store/slices/modals.slice';
import { setIsShowSidePanel, setIsFullSessionLoaded } from '../../../store/slices/sidePanel.slice';
import { getMe } from '../../../store/actions/user.actions';
import { getSingleSession } from '../../../store/actions/sessions.actions';

import RatingService from '../../../services/rating/rating.service';
import Button from '../../Atoms/Button/Button';
import StarSelector from '../../Atoms/StarSelector/StarSelector';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import Icon from '../../Atoms/Icon/Icon';
import Input from '../../Atoms/Input/Input';
import styles from './RatingModal.module.scss';

const RatingModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const { sessionId } = useSelector(selectRatingModal, shallowEqual) || {};
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [selectedRating, setSelectedRating] = useState(false);
  const [scoreToSubmit, setScoreToSubmit] = useState(0);
  const comment = '';
  const { register, handleSubmit, control, reset } = useForm<any>({
    defaultValues: {
      comment
    } as any
  });

  const onRequestClose = (): void => {
    reset();
    setScoreToSubmit(0);
    dispatch(setIsShowRatingModal(false));
    setDisabledButton(true);
    setSubmitted(false);
  };

  const onSubmitRating = async (data): void => {
    try {
      const info = {
        session_id: sessionId,
        rating: scoreToSubmit,
        comment: (data?.comment || null)
      };
      const res = await RatingService.createRating(info);

      await dispatch(getMe());
      await dispatch(getSingleSession(sessionId));
      dispatch(setIsShowSidePanel(false));

    } catch (e) {
      console.error(e);
    } finally {
      setSubmitted(true);
      setDisabledButton(true);
      reset();
    }
  };

  useEffect(() => {
    if (scoreToSubmit != 0) {
      setDisabledButton(false);
    }
  }, [scoreToSubmit]);

  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onRequestClose} className={styles.modal}>
      {!submitted ? (
        <>
          <h1>How would you rate this session?</h1>
          <div className={styles.stars}>
            <StarSelector
              rating={null}
              onOpenRatingButtonClick={null}
              selectedScore={scoreToSubmit}
              setSelectedScore={setScoreToSubmit}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmitRating)}>
            <textarea
                className={styles.inputComment}
                id='comment'
                name='comment'
                type='textarea'
                placeholder='Please provide any additional details. (optional)'
                {...register('comment')}
              />
            <div className={styles.buttonWrapper}>
              {disabledButton ? (
                <Button className={styles.buttonDisabled} disabled value={'Submit'} />
              ) : (
                <Button className={styles.button} value={'Submit'} />
              )}
            </div>
          </form>
        </>
      ) : (
        <>
          <div className={styles.submittedIcon}>
            <Icon name='greenCircleTick' />
          </div>
          <div className={styles.thankYouMessage}>
            <h1>Thank you! Your rating was submitted.</h1>
          </div>
          <div className={styles.buttonWrapper}>
            <Button onClick={onRequestClose} className={styles.button} value={'Close'} />
          </div>
        </>
      )}
    </ModalWrapper>
  );
};

export default RatingModal;
