import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectIsPartnersDashboard } from '../store/slices/global.slice';

const useIsPartnerGuard = (redirectRoute = '/login'): void => {
  const history = useHistory();
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);

  useEffect((): void => {
    if (isPartnersDashboard) {
      history.push(redirectRoute);
    }
  }, []);
};

export default useIsPartnerGuard;
