import React, { ReactElement } from 'react';
import avatarImg from '../../../../../../assets/images/avatar.png';
import Avatar from '../Avatar/Avatar';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import styles from './RatingCard.module.scss';

const MAX_STARS = 5;

interface RatingCardInterface {
  rating: number;
  comment?: string;
}

const RatingCard = ({ rating, comment }: RatingCardInterface): ReactElement => (
    <div className={styles.content}>
      <div className={styles.ratingWrapper}>
        <div className={styles.userAvatar}>
          <Avatar />
        </div>
        <div className={styles.wrapper}>
          {[...Array(rating)].map((e, i) => <Icon key={i} name='star-on' width={18} height={18} viewBox='0 0 18 18' />)}
          {[...Array(MAX_STARS - rating)].map((e, i) => <Icon key={i} name='star-off' width={18} height={18} viewBox='0 0 18 18' />)}
        </div>
      </div>
      {comment && (
        <>
          <div className={styles.divider} />
          <div className={styles.comment}>
            {comment}
          </div>
        </>
      )}
    </div>
  );

export default RatingCard;
