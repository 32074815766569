import moment from 'moment';
import React, { ReactElement, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ReferralStatus } from '../../../../../../enums/referral-status.enum';
import { referralStatusMapperHelper } from '../../../../../../helpers/referral-status-mapper.helper';
import { FAQInterface } from '../../../../../../interfaces/referral-data.interface';
import { ReferralInterface } from '../../../../../../services/referral/referral.interface';
import { sendReminder } from '../../../../../../store/actions/referral.actions';
import { selectReferrals } from '../../../../../../store/slices/referral.slice';
import { ThunkAppDispatch } from '../../../../../../store/store';
import QAContainer from '../../../../../Atoms/QAContainer/QAContainer';
import QAStyles from '../QuestionAnswer/QuestionAnswer.module.scss';
import styles from './ReferralHistory.module.scss';

interface ReferralListInterface {
  referrals: ReferralInterface[];
}

const getTimeRemaining = (deadline: string): string => {
  const diff = moment(deadline).diff(moment(), 'hour');
  const diffInDays = moment.duration(diff, 'hours').asDays();
  const roundedDiff = diffInDays > 0 && diffInDays <= 1 ? Math.ceil(diffInDays) : Math.round(diffInDays);
  return diff <= 0 ? 'Expired' : `${roundedDiff} ${roundedDiff === 1 ? 'day' : 'days'}`;
};

const isAllowedToSendReminder = ({ reminder_sent_at, createdAt }: Partial<ReferralInterface>): boolean =>
  Math.abs(moment(reminder_sent_at || createdAt).diff(moment(), 'hour', true)) > 24;

const Accepted = ({ referrals }: ReferralListInterface): ReactElement => (
  <div className={styles.invitations}>
    <h4>Contact</h4>
    <h4>Credits earned</h4>
    <h4>Status</h4>
    {referrals.map(({ email, credits_earned, status }, idx) => (
      <React.Fragment key={idx}>
        <span>{email}</span>
        <span>{credits_earned}</span>
        <span>{referralStatusMapperHelper(status)}</span>
      </React.Fragment>
    ))}
  </div>
);

const Awaiting = ({ referrals }: ReferralListInterface): ReactElement => {
  const dispatch = useDispatch<ThunkAppDispatch>();

  const onSendReminder = (referralId: number): (() => void) => async (): Promise<void> => {
    try {
      await dispatch(sendReminder(referralId));
    } catch (e) {
      console.error(e);
    }
  };

  const renderReminderButton = ({ reminder_sent_at, id, createdAt }: Partial<ReferralInterface>): ReactElement => (
    <div className={styles.reminder}>
      {isAllowedToSendReminder({ reminder_sent_at, createdAt }) ? (
        <button onClick={onSendReminder(id)}>Send reminder</button>
      ) : (
        <div>{reminder_sent_at ? 'Reminder sent!' : 'Invitation sent!'}</div>
      )}
    </div>
  );

  return (
    <div className={styles.invitations}>
      <h4>Contact</h4>
      <h4>Time remaining</h4>
      <h4 />
      {referrals.map(({ email, deadline, status, reminder_sent_at, id, createdAt }, idx) => (
        <React.Fragment key={idx}>
          <span>{email}</span>
          <span>{getTimeRemaining(deadline)}</span>
          <span>{renderReminderButton({ reminder_sent_at, id, createdAt })}</span>
        </React.Fragment>
      ))}
    </div>
  );
};

const ReferralHistory = (): ReactElement => {
  const referrals = useSelector(selectReferrals, shallowEqual);
  const awaiting = referrals
    ?.filter(({ status }) => status === ReferralStatus.AWAITING_TO_ACCEPT)
    ?.sort((a, b) => (moment(a.createdAt).unix() > moment(b.createdAt).unix() ? -1 : 1));
  const accepted = referrals
    ?.filter(({ status }) => status !== ReferralStatus.AWAITING_TO_ACCEPT)
    ?.sort((a, b) => (moment(a.createdAt).unix() > moment(b.createdAt).unix() ? -1 : 1));

  const items = useMemo(
    (): FAQInterface[] => [
      {
        faq: {
          Title: `Invitations accepted (${accepted.length})`,
          customContent: <Accepted referrals={accepted} />,
          isExpandable: !!accepted.length,
          elementsCount: accepted.length
        }
      },
      {
        faq: {
          Title: `Awaiting to accept (${awaiting.length})`,
          customContent: <Awaiting referrals={awaiting} />,
          isExpandable: !!awaiting.length,
          elementsCount: awaiting.length
        }
      }
    ],
    [awaiting, accepted]
  );

  return (
    <div className={styles.container}>
      <QAContainer title={'Referral history'} items={items} QAStyles={QAStyles} bgColor={'#F9FAFB'} />
    </div>
  );
};

export default ReferralHistory;
