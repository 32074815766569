export enum SlotStatus {
  AVAILABLE = 'available',
  PREBOOKED = 'prebooked',
  BOOKED = 'booked'
}

export enum SlotDisplayStatus {
  SHOWN = 'SHOWN',
  HIDDEN = 'HIDDEN'
}
