import jwt from 'jsonwebtoken';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';

import Button_v2 from '../../components/Atoms/Button_v2/button';
import StyledInput from '../../components/Atoms/StyledInput/StyledInput';
import SignUpFlowNavbar from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar';
import { ButtonSizes } from '../../enums/button-sizes.enum';
import { StyledInputTypes } from '../../enums/styled-input-types.enum';
import AuthService from '../../services/auth/auth.service';
import styles from './PasswordValidate.module.scss';
import {
  backToLoginText,
  backToPasswordResetText,
  buttonSubmittingText,
  buttonText,
  header,
  invalidTokenText,
  passwordChangedText
} from './password-validate.const';

const PasswordValidate = (): ReactElement => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    watch
  } = useForm();
  const password = useRef({});
  password.current = watch('password', '');
  const [submitting, setSubmitting] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(null);
  const [passwordSuccessfullyChanged, setPasswordSuccessfullyChanged] = useState(false);

  const { token } = useParams<{ token: string }>();

  useEffect((): void => {
    const decodedToken = jwt.decode(token);
    if (new Date(decodedToken.exp * 1000) < new Date()) {
      return;
    }
    const validateToken = async (): Promise<void> => {
      try {
        await AuthService.resetPasswordToken({ resetToken: token });
        setIsTokenValid(true);
      } catch (e) {
        return;
      }
    };
    validateToken().finally();
  }, [token]);

  const onSubmit = async data => {
    setSubmitting(true);

    const payload = {
      resetToken: token,
      password: data.password
    };

    try {
      const res = await AuthService.resetPasswordToken(payload);
      if (res.status === 200) {
        setSubmitting(false);
        setPasswordSuccessfullyChanged(true);
        return;
      }
    } catch (err) {
      console.error('--> ', err);
      if (err.response.status === 401) {
        setSubmitting(false);
        setError('email', {
          type: 'manual',
          message: 'Invalid email or wrong password'
        });
      } else {
        setError('server', {
          type: 'manual',
          message: 'We have some issues connecting to our servers. Please try again later.'
        });
      }
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <SignUpFlowNavbar />
      <div className={styles.leftImage}>
        <div className={styles.bg} />
      </div>
      <div className={styles.rightImage}>
        <div className={styles.bg} />
      </div>
      <h1>{header}</h1>
      {passwordSuccessfullyChanged ? (
        <>
          <p className={styles.instructionsSent}>{passwordChangedText}</p>
          <Link to={'/login'} className={styles.backToLogin}>
            {backToLoginText}
          </Link>
        </>
      ) : (
        <>
          {isTokenValid ? (
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <StyledInput
                width={'100%'}
                height={'51px'}
                label={'Password'}
                type={StyledInputTypes.PASSWORD}
                name={'password'}
                id={'password'}
                registerRef={register('password', {
                  required: 'You must specify a password',
                  minLength: {
                    value: 8,
                    message: 'Password must have at least 8 characters'
                  }
                })}
                errors={[errors.password?.message]}
                clearErrors={() => clearErrors()}
              />
              <StyledInput
                width={'100%'}
                height={'51px'}
                label={'Confirm password'}
                type={StyledInputTypes.PASSWORD}
                name={'password_confirmation'}
                id={'password_confirmation'}
                registerRef={register('password_confirmation', {
                  validate: value => value === watch('password') || 'The passwords do not match'
                })}
                errors={[errors.server?.message, errors.password_confirmation?.message]}
                clearErrors={() => clearErrors()}
              />
              <div className={styles.button}>
                <Button_v2
                  disabled={submitting}
                  width={'100%'}
                  height={'60px'}
                  buttonSize={ButtonSizes.MEDIUM}
                  buttonText={submitting ? buttonSubmittingText : buttonText}
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            </form>
          ) : (
            <>
              <p className={styles.infoText}>{invalidTokenText}</p>
              <Link to={'/reset-password'} className={styles.backToLogin}>
                {backToPasswordResetText}
              </Link>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PasswordValidate;
