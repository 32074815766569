import {
  NotificationCategories,
  UserSessionProperties,
  UserFileProperties,
  UserNewsletterProperties,
  PartnerSessionProperties,
  PartnerFileProperties
} from 'app/enums/notification-preferences.enum';

export const settingsOrder = {
  user: [
    [
      NotificationCategories.SESSIONS,
      [
        UserSessionProperties.AFTER_BOOKING_SESSION,
        UserSessionProperties.BEFORE_SESSION_24_REMINDER,
        UserSessionProperties.BEFORE_SESSION_1_REMINDER,
        UserSessionProperties.ON_SESSION_CANCELLED,
        UserSessionProperties.ON_NEW_MESSAGE_SENT
      ]
    ],
    [
      NotificationCategories.FILES,
      [
        UserFileProperties.BEFORE_SESSION_72_FILE_REMINDER,
        UserFileProperties.FILE_UPDATE_REQUIRED,
        UserFileProperties.FILES_READY_AFTER_SESSION
      ]
    ],
    [
      NotificationCategories.NEWSLETTER,
      [UserNewsletterProperties.CAMPAIGNS_AND_OFFERS, UserNewsletterProperties.MARKETING]
    ]
  ],
  partner: [
    [
      NotificationCategories.SESSIONS,
      [
        PartnerSessionProperties.AFTER_RECEIVE_BOOKING,
        PartnerSessionProperties.AFTER_RECEIVE_BOOKING_24,
        // PartnerSessionProperties.SEND_WEEKLY_DIGEST,
        PartnerSessionProperties.AFTER_BOOKING_CANCELLATION,
        // PartnerSessionProperties.AFTER_MUSIVERSAL_CANCELLATION,
        // PartnerSessionProperties.SEND_ADDITIONAL_NOTES_EMAILS,
        PartnerSessionProperties.ON_NEW_MESSAGE_SENT
      ]
    ],
    [
      NotificationCategories.FILES,
      [PartnerFileProperties.USER_FILES_UPLOADED, PartnerFileProperties.USER_FILES_UPDATED]
    ]
  ]
};

export const requiredSettings = [
  UserSessionProperties.AFTER_BOOKING_SESSION,
  UserSessionProperties.ON_SESSION_CANCELLED,
  UserNewsletterProperties.MARKETING
];

export const comingSoon = [PartnerFileProperties.USER_FILES_UPLOADED, PartnerFileProperties.USER_FILES_UPDATED];

export const settingsLabels = {
  user: {
    [NotificationCategories.SESSIONS]: {
      text: 'Sessions',
      [UserSessionProperties.AFTER_BOOKING_SESSION]: 'Notify me after I book a session',
      [UserSessionProperties.BEFORE_SESSION_24_REMINDER]: 'Remind me 24 hours before a session',
      [UserSessionProperties.BEFORE_SESSION_1_REMINDER]: 'Remind me 1 hour before a session',
      [UserSessionProperties.ON_SESSION_CANCELLED]: 'Notify me if a session is cancelled',
      [UserSessionProperties.ON_NEW_MESSAGE_SENT]: 'Notify me when an artist sends me a message'
    },
    [NotificationCategories.FILES]: {
      text: 'Files Submissions',
      [UserFileProperties.BEFORE_SESSION_72_FILE_REMINDER]:
        'Remind me to send session materials before the submission deadline',
      [UserFileProperties.FILE_UPDATE_REQUIRED]: 'Notify me when I need to update my pre-session info/files',
      [UserFileProperties.FILES_READY_AFTER_SESSION]: 'Notify me when my session files are ready to download'
    },
    [NotificationCategories.NEWSLETTER]: {
      text: 'News and updates',
      [UserNewsletterProperties.CAMPAIGNS_AND_OFFERS]: 'Receive campaigns and offers',
      [UserNewsletterProperties.MARKETING]: 'Receive news and updates'
    }
  },
  partner: {
    [NotificationCategories.SESSIONS]: {
      text: 'Sessions',
      [PartnerSessionProperties.AFTER_RECEIVE_BOOKING]: 'Notify me when I receive a booking for more than 24h',
      [PartnerSessionProperties.AFTER_RECEIVE_BOOKING_24]: 'Notify me when I receive a booking for less than 24h',
      [PartnerSessionProperties.SEND_WEEKLY_DIGEST]: 'Send me a weekly resume of my booked sessions',
      [PartnerSessionProperties.AFTER_BOOKING_CANCELLATION]: 'Notify me when a user cancels a session',
      [PartnerSessionProperties.AFTER_MUSIVERSAL_CANCELLATION]: 'Notify me when musiversal cancels a session',
      [PartnerSessionProperties.SEND_ADDITIONAL_NOTES_EMAILS]:
        'Send me an email when a user adds a comment to a session',
      [PartnerSessionProperties.ON_NEW_MESSAGE_SENT]: 'Notify me when an user sends me a message'
    },
    [NotificationCategories.FILES]: {
      text: 'Files Submissions',
      [PartnerFileProperties.USER_FILES_UPLOADED]: 'Notify me when a user submits session files',
      [PartnerFileProperties.USER_FILES_UPDATED]: 'Notify me when a user submits updated session files'
    }
  }
};
