export enum BookSessionTabs {
  CHOOSE_A_TIME = 'Choose a time',
  TRACK_DETAILS = 'Track details',
  SESSION_DETAILS = 'Session Details',
  PRE_CONFIRMATION = 'Pre Confirmation',
  THANK_YOU = 'Thank you'
}

export enum ChooseAServiceTab {
  CHOOSE_A_SERVICE = 'Choose a service'
}
