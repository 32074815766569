import React, { ReactElement, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { restartBillingCycleHelper } from '../../../helpers/restart-billing-cycle.helper';
import { ModalInterface } from '../../../interfaces/modal.interface';
import { selectPlans } from '../../../store/slices/global.slice';
import { setIsShowServiceUpdateModal } from '../../../store/slices/modals.slice';
import { selectUser } from '../../../store/slices/user.slice';
import Button from '../../Atoms/Button/Button';
import CustomCheckbox from '../../Atoms/CustomCheckbox/CustomCheckbox';
import Icon from '../../Atoms/Icon/Icon';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import styles from './ServiceUpdateModal.module.scss';

let stripePromise = null;
if (process.env.REACT_APP_STRIPE_ID) {
  import('@stripe/stripe-js').then(({ loadStripe }) => (stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ID)));
}

enum Steps {
  FIRST = '1',
  SECOND = '2'
}

const MAX_STEP = 2;

const updates = [
  { label: 'Singer sessions are 45 minutes long and are now 2 credits', value: '1' },
  { label: 'Stereo Mastering and Mastering for Vinyl sessions are 45 minutes long and are now 2 credits', value: '2' },
  { label: 'Stem Mastering is now a 90-minute session and costs 4 credits', value: '3' }
];

const reasons = [
  {
    icon: 'happy',
    text: 'We can pay our artists a fair wage that compensates with the increase in prep & session time required'
  },
  { icon: 'rocket', text: 'We can hire more artists for these services, more often!' }
];

const ServiceUpdateModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const plans = useSelector(selectPlans, shallowEqual);
  const user = useSelector(selectUser, shallowEqual);

  const updatesFields = updates.map(({ value }) => value);

  const { control, getValues, watch, reset } = useForm();

  const [step, setStep] = useState(Steps.FIRST);

  const watchCheckboxes = watch([...updatesFields]);

  const isAllCheckboxSelected = useMemo((): boolean => {
    const checkboxes = Object.values(getValues());
    return checkboxes.length && checkboxes.every(value => value);
    // eslint-disable-next-line
  }, [getValues, watchCheckboxes]);

  const onRequestClose = (): void => {
    reset();
    dispatch(setIsShowServiceUpdateModal(false));
    goToStep(Steps.FIRST)();
  };

  const goToStep = (toStep: Steps): (() => void) => (): void => setStep(toStep);

  const onNextClick = (): void => {
    if (!isAllCheckboxSelected) {
      return;
    }
    goToStep(Steps.SECOND)();
  };

  const restartBillingCycle = async () => {
    await restartBillingCycleHelper({ plans, user, dispatch, stripePromise });
  };

  const renderFirstStep = (): ReactElement => (
    <>
      <h3>Service update</h3>
      <p className={styles.description}>
        Before continuing, please acknowledge the following updates to some of our services.
      </p>
      {updates.map(({ label, value }) => (
        <CustomCheckbox
          key={value}
          required
          name={value}
          label={label}
          control={control}
          containerClassName={styles.checkbox}
          labelClassName={styles.checkboxLabel}
        />
      ))}
      <Button disabled={!isAllCheckboxSelected} className={styles.button} value={'Next'} onClick={onNextClick} />
    </>
  );

  const renderSecondStep = (): ReactElement => (
    <>
      <h3>Why we’ve made these updates</h3>
      {reasons.map(({ icon, text }) => (
        <div key={text} className={styles.reason}>
          <div className={styles.icon}>
            <Icon name={icon} />
          </div>
          <span>{text}</span>
        </div>
      ))}
      <Button onClick={restartBillingCycle} className={styles.restartButton} value={'Restart Billing Cycle'} />
    </>
  );

  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onRequestClose} className={styles.modal}>
      <p className={styles.counter}>
        {step}/{MAX_STEP}
      </p>
      <div className={styles.container}>
        {step === Steps.FIRST && renderFirstStep()}
        {step === Steps.SECOND && renderSecondStep()}
      </div>
    </ModalWrapper>
  );
};

export default ServiceUpdateModal;
