import { createAsyncThunk } from '@reduxjs/toolkit';

import { OffboardingModalInterface } from '../../interfaces/offboarding-modal.interface';
import { OnboardingModalInterface } from '../../interfaces/onboarding-modal.interface';
import CmsService from '../../services/cms/cms.service';
import { SlicesNames } from '../slices/slices-names.enum';

export const getOffboardingModalData = createAsyncThunk(
  `${SlicesNames.MODALS_SLICE}/getOffboardingModalData`,
  async (): Promise<OffboardingModalInterface> => {
    try {
      return (await CmsService.getOffboardingDataRequest()).data;
    } catch (e) {
      console.error(e);
    }
  }
);

export const getOnboardingModalData = createAsyncThunk(
  `${SlicesNames.MODALS_SLICE}/getOnboardingModalData`,
  async (): Promise<OnboardingModalInterface> => {
    try {
      return (await CmsService.getOnboardingDataRequest()).data;
    } catch (e) {
      console.error(e);
    }
  }
);
