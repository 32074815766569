import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import TimePicker from 'react-time-picker';

import { timeSystemConverterHelper } from '../../../helpers/time-system-converter.helper';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import styles from './TimeRangeInput.module.scss';

interface RangeInputInterface {
  timeRange: [string, string];
  onClear: () => void;
  setTimeRange: Dispatch<[string, string]>;
}

const TimeRangeInput = ({ timeRange, onClear, setTimeRange }: RangeInputInterface): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [range, setRange] = useState<[string, string]>(timeRange);

  useEffect((): void => {
    setRange(timeRange);
  }, [timeRange]);

  const toggleIsOpen = (): void => {
    setIsOpen(!isOpen);
  };

  useEffect((): void => {
    if (isOpen) {
      return;
    }
    setRange([timeRange[0], timeRange[1]]);
  }, [isOpen, timeRange]);

  const onTimePickerChange = (value: string, idx: 0 | 1): void => {
    range[idx] = value;
    setRange(range);
  };

  const onSave = (): void => {
    setTimeRange([...range]);
    toggleIsOpen();
  };

  const rangeToString = (): string =>
    range[0] === null && range[1] === null
      ? ''
      : `${range[0] ? timeSystemConverterHelper(range[0], false) : '--:--'} - ${
          range[1] ? timeSystemConverterHelper(range[1], false) : '--:--'
        }`;

  return (
    <div className={styles.container}>
      <OutsideClickHandler onOutsideClick={(): void => setIsOpen(false)}>
        <div className={styles.inputContainer}>
          <input
            className={styles.input}
            placeholder={'Time Range'}
            onClick={toggleIsOpen}
            value={rangeToString()}
            readOnly
          />
          <div className={styles.icons}>
            <Icon name={'xClose'} className={styles.closeIcon} onClick={onClear} />
            <Icon name={'dropdownArrow'} className={styles.dropdownIcon} onClick={toggleIsOpen} />
          </div>
        </div>
        {isOpen && (
          <div className={styles.popup}>
            <div className={styles.timePicker}>
              <label>From:</label>
              <div>
                <TimePicker
                  clearIcon={false}
                  clockIcon={false}
                  disableClock
                  format={'h:mm a'}
                  value={timeRange[0]}
                  onChange={val => onTimePickerChange(val, 0)}
                />
              </div>
            </div>
            <div className={styles.timePicker}>
              <label>To:</label>
              <div>
                <TimePicker
                  clearIcon={false}
                  clockIcon={false}
                  disableClock
                  format={'h:mm a'}
                  value={timeRange[1]}
                  onChange={val => onTimePickerChange(val, 1)}
                />
              </div>
            </div>
            <Button value={'Save'} className={styles.saveButton} onClick={onSave} />
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default TimeRangeInput;
