import axios, { AxiosPromise } from 'axios';

import { UserInterface } from '../../interfaces/user.interface';
import { LoginDataInterface, ResetPasswordTokenInterface, SignupDataInterface } from './auth.interface';

const USERS_API = `${process.env.REACT_APP_API_URL}/api/users`;
const API_V2 = `${process.env.REACT_APP_API_URL}/api/v2`;
const AUTH_API = `${process.env.REACT_APP_API_URL}/api/auth`;

const axiosUsersInstance = axios.create({
  baseURL: USERS_API,
  withCredentials: true
});

const axiosInstanceV2 = axios.create({
  baseURL: API_V2,
  withCredentials: true
});

const axiosAuthInstance = axios.create({
  baseURL: AUTH_API,
  withCredentials: true
});

const login = (loginData: LoginDataInterface): AxiosPromise<{ user: UserInterface }> =>
  axiosUsersInstance.post('/login', loginData);

const logout = (): Promise<void> => axiosUsersInstance.get('/logout');

const signup = (signupData: SignupDataInterface): AxiosPromise<{ user: UserInterface }> =>
  axiosInstanceV2.post('/users', signupData);

const resetPasswordInstructions = (email: string): AxiosPromise =>
  axiosAuthInstance.post('/reset-password-instructions', { email });

const resetPasswordToken = ({ resetToken, password }: ResetPasswordTokenInterface): AxiosPromise =>
  axiosAuthInstance.post('/validate/reset-password-token', { resetToken, password });

const ondemandSignup = (signupData: SignupDataInterface): AxiosPromise<{ user: UserInterface }> =>
  axiosInstanceV2.post('/auth/signup-ondemand', signupData);

export default {
  login,
  logout,
  signup,
  resetPasswordInstructions,
  resetPasswordToken,
  ondemandSignup
};
