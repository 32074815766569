import React, { Dispatch, ReactElement } from 'react';

import { HowItWorksSteps } from '../HowItWorksModal';
import styles from './HowItWorksStep.module.scss';
import { howItWorksDescription, howItWorksHeader, steps } from './howItWorksStep.const';

interface HowItWorksStepInterface {
  setOnboardingStep: Dispatch<HowItWorksSteps>;
}

export interface RenderStepInterface {
  numb: number;
  title: string;
  textHtml: string;
}

const renderStep = ({ numb, title, textHtml }: RenderStepInterface): ReactElement => (
  <div key={numb} className={styles.stepContainer}>
    <div className={styles.numberContainer}>
      <span>{numb}</span>
    </div>
    <div className={styles.description}>
      <h3>{title}</h3>
      <div dangerouslySetInnerHTML={{ __html: textHtml }} />
    </div>
  </div>
);

const HowItWorksStep = ({ setOnboardingStep }: HowItWorksStepInterface): ReactElement => (
  <>
    <div className={styles.howItWorkContainer}>
      <h2 className={styles.title}>{howItWorksHeader}</h2>
      <p className={styles.titleDescription}>{howItWorksDescription}</p>
      {steps.map(step => renderStep({ ...step }))}
    </div>
    <div className={styles.footer}>
      <button className={styles.button} onClick={() => setOnboardingStep(HowItWorksSteps.HELP)}>
        Next
      </button>
    </div>
  </>
);

export default HowItWorksStep;
