import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Button_v2 from '../../components/Atoms/Button_v2/button';
import FacebookLogin from '../../components/Atoms/FacebookLogin/FacebookLogin';
import GoogleLoginButton from '../../components/Atoms/GoogleLoginButton/GoogleLoginButton';
import StyledInput from '../../components/Atoms/StyledInput/StyledInput';
import SignUpFlowNavbar from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar';
import { signUpText } from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar.const';
import { ButtonSizes } from '../../enums/button-sizes.enum';
import { StyledInputTypes } from '../../enums/styled-input-types.enum';
import AuthService from '../../services/auth/auth.service';
import { getInstruments, getMusicians } from '../../store/actions/musicians.actions';
import { getMe } from '../../store/actions/user.actions';
import { selectIsPartnersDashboard } from '../../store/slices/global.slice';
import styles from './Login.module.scss';
import { buttonSubmittingText, buttonText, forgotPasswordText, orText, welcomeBack } from './login.const';

const Login = (): ReactElement => {
  const history = useHistory();
  const { loginWithFacebook, loginWithGoogle } = useFlags();
  const dispatch = useDispatch();
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors
  } = useForm();
  const [submitting, setSubmitting] = useState(false);

  const redirectRoute = isPartnersDashboard ? '/sessions' : '/explore';

  const redirectToPrivateArea = (): void => {
    setSubmitting(false);
    history.push(redirectRoute);
  };

  const onSubmit = async data => {
    setSubmitting(true);

    const user = {
      user: {
        email: data.email?.toLowerCase(),
        password: data.password
      }
    };

    try {
      await AuthService.logout();
      const res = await AuthService.login(user);
      localStorage.setItem('timezone', res.data.user.timezone ? res.data.user.timezone : moment.tz.guess());
      await dispatch(getMe());
      await redirectToPrivateArea();
    } catch (err) {
      if (err?.response?.status === 401) {
        setSubmitting(false);
        setError('email', {
          type: 'manual',
          message: 'Invalid email or wrong password'
        });
      } else if (err?.response?.status === 422) {
        setError('provider', {
          type: 'manual',
          message: 'Your need to login using your Google or Facebook account.'
        });
      } else {
        setError('server', {
          type: 'manual',
          message: 'We have some issues connecting to our servers. Please try again later or contact support.'
        });
      }
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <SignUpFlowNavbar isPartnersDashboard={isPartnersDashboard} />
      <div className={styles.leftImage}>
        <div className={styles.bg} />
      </div>
      <div className={styles.rightImage}>
        <div className={styles.bg} />
      </div>
      <h1>{welcomeBack}</h1>
      <div className={styles.form}>
        {loginWithFacebook && (
          <FacebookLogin callback={redirectToPrivateArea} setError={setError} buttonText='Login with Facebook' />
        )}
        {loginWithGoogle && (
          <GoogleLoginButton callback={redirectToPrivateArea} setError={setError} buttonText='Login with Google' />
        )}
        <form className={styles.formInner} onSubmit={handleSubmit(onSubmit)}>
          {(loginWithFacebook || loginWithGoogle) && (
            <div className={styles.divider}>
              <span>{orText}</span>
            </div>
          )}
          <StyledInput
            width={'100%'}
            height={'51px'}
            label={'Email Address'}
            type={StyledInputTypes.EMAIL}
            name={'email'}
            id={'email'}
            registerRef={register('email', {
              required: 'Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address'
              }
            })}
            errors={[errors.email?.message]}
            clearErrors={() => clearErrors()}
          />
          <StyledInput
            width={'100%'}
            height={'51px'}
            label={'Password'}
            type={StyledInputTypes.PASSWORD}
            name={'password'}
            id={'password'}
            registerRef={register('password', { required: 'Required' })}
            errors={[errors.password?.message, errors.provider?.message, errors.server?.message]}
            clearErrors={() => clearErrors()}
          />
          <Link to={'/reset-password'} className={styles.forgotPassword}>
            {forgotPasswordText}
          </Link>
          <Button_v2
            isSubmit
            disabled={submitting}
            width={'100%'}
            height={'60px'}
            buttonSize={ButtonSizes.MEDIUM}
            buttonText={submitting ? buttonSubmittingText : buttonText}
          />
        </form>

        <Link to={'/signup'}>
          <span className={styles.signupMobile}>{signUpText}</span>
        </Link>
      </div>
    </div>
  );
};

export default Login;
