import { RenderStepInterface } from './HowItWorksStep';

export const howItWorksHeader = 'How it works';
export const howItWorksDescription = 'Your 2-week free trial begins today!';
export const steps = [
  {
    numb: 1,
    title: 'Book your first session, free',
    textHtml: `<span>Choose your artist, schedule a time and upload your track material. Want help preparing your session 
material? No sweat - book <a
      href='https://help.musiversal.com/how-does-a-pre-production-session-work'
      target='_blank'
      rel='noreferrer'
    >pre-production</a> or get in touch with any questions.</span>`
  },
  {
    numb: 2,
    title: 'Attend your session, remotely',
    textHtml:
      '<span>Collaborate with your chosen artist in real-time. We use Zoom for video and AudioMovers for studio-quality audio. All you need is Zoom and a good internet connection. Prepare to get blown away.</span>'
  },
  {
    numb: 3,
    title: 'Receive your files',
    textHtml:
      '<span>Get your high quality files within 24 hours after the session, in your My Sessions area. Download with one click and drag straight into your DAW.</span>'
  }
] as RenderStepInterface[];
