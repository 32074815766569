export enum AnnouncementTemplateTypes {
  FULL = 'full',
  BIG_SMALL = 'big_small',
  SMALL_BIG = 'small_big'
}

export enum AnnouncementCategoryLabels {
  'tutorial' = 'LEARNING',
  'sales' = 'SALES',
  'product_updates' = 'PRODUCT UPDATES',
  'roster' = 'ROSTER REFRESH',
  'guest_musician' = 'GUEST MUSICIAN'
}

export enum AnnouncementButtonBehaviours {
  EXTERNAL_LINK = 'external_link',
  INTERNAL_LINK = 'internal_link',
  OPEN_PLANS_MODAL = 'open_plans_modal',
  OPEN_UPSELL_MODAL = 'open_upsell_modal',
  OPEN_TOPUP_MODAL = 'open_topup_modal',
  OPEN_DRAWER = 'open_drawer',
  PLAY_VIDEO = 'play_video'
}