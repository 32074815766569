import React, { ReactElement, useState } from 'react';

import { FAQInterface } from '../../../interfaces/referral-data.interface';
import QuestionAnswer from '../QuestionAnswer/QuestionAnswer';
import styles from './QAContainer.module.scss';

interface FaqInterface {
  items: FAQInterface[];
  QAStyles: Record<string, any>;
  title?: string;
  bgColor?: string;
}

const QAContainer = ({ title, items, QAStyles, bgColor = 'white' }: FaqInterface): ReactElement => {
  const [openedItemIdx, setOpenedItemIdx] = useState(null);

  const onItemOpened = (itemIdx: string): (() => void) => (): void => {
    setOpenedItemIdx(itemIdx);
  };

  return (
    <div className={styles.container}>
      {title && <label className={QAStyles.title}>{title}</label>}
      {items.map(({ faq: item }, idx) => (
        <QuestionAnswer
          onItemOpened={onItemOpened(`${idx}`)}
          isOpened={`${idx}` === openedItemIdx}
          key={item.id || idx}
          {...item}
          styles={QAStyles}
          bgColor={bgColor}
        />
      ))}
    </div>
  );
};

export default QAContainer;
