import moment from 'moment-timezone';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';

import styles from './Sessions.module.css';
import { RootState } from '../../../../store/store';
import Toast from '../../../../components/Atoms/Toast/Toast';
import SessionCard from '../../../../components/Atoms/SessionCard/SessionCard';
import Button from '../../../../components/Atoms/Button/Button';
import Navbar from '../../../../components/Atoms/Navbar/Navbar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SidePane from '../../../../components/Molecules/SidePane/SidePane';
import { DEFAULT_CONFIG } from './sessions.const';
import Spinner from '../../../../components/Atoms/Spinner/Spinner';
import { selectIsPartnersDashboard, setIsShowGlobalLoader } from '../../../../store/slices/global.slice';
import { SessionsTabs } from '../../../../enums/sessions-tabs.enum';
import { getThread } from '../../../../store/actions/messages.actions';
import SessionDetails from '../../../../components/Atoms/SessionDetail/SessionDetails';
import { getCurrentlyBookingArtistConfig } from '../../../../store/actions/musicians.actions';
import { getSessions, getTracks } from '../../../../store/actions/sessions.actions';
import { setSession } from '../../../../store/slices/sessions.slice';
import { setIsShowSidePanel, setIsFullSessionLoaded } from '../../../../store/slices/sidePanel.slice';
import { selectUser } from '../../../../store/slices/user.slice';
import { SessionType } from '../../../../enums/session-type.enum';
import { SessionStatus } from '../../../../enums/session-status.enum';

const parseSessions = sessions => ({
  [SessionsTabs.PENDING_REVIEW]: sessions
    .filter(x => x.status !== SessionStatus.PARTNER_READY_TO_GO && x.type === SessionType.UPCOMING)
    .sort((a, b) => moment(a.start_date) - moment(b.start_date)),
  [SessionsTabs.UPCOMING_PARTNERS]: sessions
    .filter(x => x.status === SessionStatus.PARTNER_READY_TO_GO && x.type === SessionType.UPCOMING)
    .sort((a, b) => moment(a.start_date) - moment(b.start_date)),
  [SessionsTabs.UPCOMING]: sessions.filter(x => x.type === SessionType.UPCOMING).sort((a, b) => moment(a.start_date) - moment(b.start_date)),
  [SessionsTabs.PAST]: sessions.filter(x => x.type === SessionType.PAST).sort((a, b) => moment(b.start_date) - moment(a.start_date))
});

const Sessions = () => {
  const user = useSelector(selectUser, shallowEqual);
  const { sessions, isSessionsLoading, session, tracks } = useSelector(state => state.sessionsSlice, shallowEqual);
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  const { session_id: sessionId } = useParams();

  const [selectedTab, setSelectedTab] = useState(isPartnersDashboard ? SessionsTabs.PENDING_REVIEW : SessionsTabs.UPCOMING);

  const [sessionsToShow, setSessionsToShow] = useState(parseSessions(sessions));

  const {
    isShowSidePanel,
    isFullSessionLoaded
  } = useSelector((state: RootState) => state.sidePanelSlice, shallowEqual);

  useEffect(() => {
    const getData = async () => {
      const promises = [await dispatch(getSessions())];
      if (!tracks?.length) {
        promises.push(dispatch(getTracks()));
      }
      await Promise.all(promises);
    };
    getData().finally();
  }, [dispatch, tracks?.length]);

  useEffect(() => {
    setSessionsToShow(parseSessions(sessions));
  }, [sessions]);

  const [timezone, setTimezone] = useState(user.timezone || moment.tz.guess());

  const [selectedMusicianConfiguration, setSelectedMusicianConfiguration] = useState(null);

  const getMusicianConfiguration = useCallback(async musician => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v2/partners/${musician.id}`, {
        withCredentials: true
      });
      const config = res.data;
      setSelectedMusicianConfiguration(config);
      return config;
    } catch (err) {
      setSelectedMusicianConfiguration(DEFAULT_CONFIG);
    }
  }, []);

  const showSession = useCallback(async selectedSession => {
    if (isFullSessionLoaded) {
      return;
    }
    dispatch(setIsShowGlobalLoader(true));
    const { thread_id: threadId, partner, partner: { slug } } = selectedSession;
    try {
      const res = await Promise.all([
        dispatch(getThread({ threadId })),
        getMusicianConfiguration(partner),
        dispatch(getCurrentlyBookingArtistConfig(slug))
      ]);

      // if (res.every(val => !!val)) {
        dispatch(setIsShowSidePanel(true));
      // }
      dispatch(setSession(selectedSession));
      dispatch(setIsFullSessionLoaded(true));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }
  }, [dispatch, getMusicianConfiguration]);

  const changeSelectedTab = useCallback(selection => {
    if (selection === selectedTab) {
      return;
    }
    setSelectedTab(selection);
    setSessionsToShow(parseSessions(sessions));
  }, [sessions, selectedTab]);

  const onPanelClose = () => {
    dispatch(setSession(null));
    dispatch(setIsFullSessionLoaded(false));
    history.push('/sessions');
    dispatch(setIsShowSidePanel(false));
  };

  useEffect(() => {
    if (!sessions.length || !sessionId) {
      return;
    }
    const sessionFromParam = sessions.find(({ id }) => id?.toString() === sessionId);
    if (!sessionFromParam) {
      history.push('/sessions');
      return;
    }
    const parsedSession = parseSessions([sessionFromParam]);
    let tab = Object.keys(parsedSession).filter(sessionType => parsedSession[sessionType]?.length)[0];
    if (tab !== SessionsTabs.PAST && !isPartnersDashboard) {
      tab = SessionsTabs.UPCOMING;
    }
    changeSelectedTab(tab);
    showSession(sessionFromParam).finally();
  }, [sessions, sessionId, showSession, changeSelectedTab, isPartnersDashboard, history]);

  useEffect(() => {
    dispatch({
      type: 'setUserState',
      sessionsLeft: user.sessions_left ? user.sessions_left : 0
    });
    const tz = localStorage.getItem('timezone');
    if (tz) setTimezone(tz);
  }, [dispatch, user.sessions_left]);

  const counterSessionByType = sessionType => sessionsToShow[sessionType].length;

  return (
    <>
      <div className={styles.wrapper}>
        <Navbar currentPath='sessions' isPartnersDashboard={isPartnersDashboard} isTrial={user?.status === 'trial'} />

        {/* <UpgradeYourAccount availablePlans={availablePlans} jwtToken={jwtToken} /> */}

        <div className={styles.container}>
          <div className={styles.header}>
            <h1 className={styles.headerTitle}>My Sessions</h1>
            {!isPartnersDashboard && (
              <div className={styles.headerButton}>
                <Button value='Book a session' onClick={() => history.push('/explore')} />
              </div>
            )}
          </div>

          {isSessionsLoading ? <Spinner className={styles.spinner} isBlue /> : <>
            {isPartnersDashboard ? (
              <div className={styles.headerNav}>
                <a
                  className={styles.headerNavLink + ' ' + (selectedTab === 'pending_review' ? styles.isActive : '')}
                  onClick={() => changeSelectedTab('pending_review')}
                >
                  Pending Review{' '}
                  <span className={styles.headerNavLink__counter}>{counterSessionByType('pending_review')}</span>
                </a>

                <a
                  className={styles.headerNavLink + ' ' + (selectedTab === 'upcoming_partners' ? styles.isActive : '')}
                  onClick={() => changeSelectedTab('upcoming_partners')}
                >
                  Upcoming{' '}
                  <span className={styles.headerNavLink__counter}>{counterSessionByType('upcoming_partners')}</span>
                </a>

                <a
                  className={styles.headerNavLink + ' ' + (selectedTab === 'past' ? styles.isActive : '')}
                  onClick={() => changeSelectedTab('past')}
                >
                  Completed <span className={styles.headerNavLink__counter}>{counterSessionByType('past')}</span>
                </a>
              </div>
            ) : (
              <div className={styles.headerNav}>
                <a
                  className={styles.headerNavLink + ' ' + (selectedTab === 'upcoming' ? styles.isActive : '')}
                  onClick={() => changeSelectedTab('upcoming')}
                >
                  Upcoming <span className={styles.headerNavLink__counter}>{counterSessionByType('upcoming')}</span>
                </a>
                <a
                  className={styles.headerNavLink + ' ' + (selectedTab === 'past' ? styles.isActive : '')}
                  onClick={() => changeSelectedTab('past')}
                >
                  Previous <span className={styles.headerNavLink__counter}>{counterSessionByType('past')}</span>
                </a>
              </div>
            )}

            {sessionsToShow[selectedTab].map(session => (
              <SessionCard
                key={session.id}
                id={session.id}
                rating={session.rating}
                imageSrc={isPartnersDashboard ? session?.user?.avatar : session?.partner?.avatar}
                instrument={isPartnersDashboard ? '' : session.instrument_name}
                name={isPartnersDashboard ? session?.user?.name : session?.partner?.name}
                track={session?.session_info?.title?.toString() || ''}
                trackOptions={session?.session_info?.options}
                musicianConfig={selectedMusicianConfiguration}
                date={moment(session.start_date).tz(timezone).format("ddd DD MMM 'YY")}
                time={moment(session.start_date).tz(timezone).format('HH:mm')}
                onClick={() => showSession(session)}
                status={session.status}
                sessionType={session?.type}
                start_date={session?.start_date}
                isPartnersDashboard={isPartnersDashboard}
              />
            ))}

            {/* Empty State */}
            {sessionsToShow[selectedTab].length === 0 && (
              <div className={styles.emptyState}>
                <div className={styles.emptyState__icon}>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32'>
                    <title>check all</title>
                    <g
                      className='nc-icon-wrapper'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      fill='#4B5563'
                      stroke='#4B5563'
                    >
                      <polyline points='2 20 8 25 24 4' fill='none' strokeMiterlimit='10' data-color='color-2' />
                      {' '}
                      <line x1='31' y1='11' x2='27' y2='11' fill='none' stroke='#4B5563' strokeMiterlimit='10' />
                      {' '}
                      <line x1='31' y1='18' x2='21' y2='18' fill='none' stroke='#4B5563' strokeMiterlimit='10' />
                      {' '}
                      <line x1='31' y1='25' x2='16' y2='25' fill='none' stroke='#4B5563' strokeMiterlimit='10' />
                    </g>
                  </svg>
                </div>
                <h3 className={styles.emptyState__title}>You're all caught up</h3>
                <p className={styles.emptyState__description}>
                  You don't have any{' '}
                  {selectedTab.includes('upcoming')
                    ? 'upcoming'
                    : selectedTab === 'pending_review'
                      ? 'pending'
                      : 'completed'}{' '}
                  sessions, yet!
                </p>
              </div>
            )}

            <SidePane
              isOpen={isShowSidePanel}
              onRequestClose={onPanelClose}
            >
              {session && (
                <SessionDetails
                  musicianConfiguration={selectedMusicianConfiguration}
                  onPanelClose={onPanelClose}
                />
              )}
            </SidePane>

            <Toast type='success' message='Feedback was sent successfully!' />
          </>}
        </div>
      </div>
    </>
  );
};

export default Sessions;
