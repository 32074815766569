import axios, { AxiosPromise } from 'axios';

import { UserCohorts } from '../../enums/cohorts.enum';
import { OffboardingModalInterface } from '../../interfaces/offboarding-modal.interface';
import { OnboardingModalInterface } from '../../interfaces/onboarding-modal.interface';
import { ReferralDataInterface } from '../../interfaces/referral-data.interface';
import { BillboardDataInterface } from '../../interfaces/billboard-data.interface';
import { BillingAreaDataInterface } from '../../interfaces/billing-area-data.interface';
import { PricingV3DataInterface, PricingV4DataInterface } from '../../interfaces/pricing-data.interface';

const getOffboardingDataRequest = (): AxiosPromise<OffboardingModalInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/offboarding-modal`);

const getOnboardingDataRequest = (): AxiosPromise<OnboardingModalInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/onboarding-modal`);

const getBannerContentRequest = (): AxiosPromise<{ body: string }> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/studio-banner`);

const getReferralDataRequest = (): AxiosPromise<ReferralDataInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/referral`);

const getAnnouncementsRequest = (cohort = 'default'): AxiosPromise<BillboardDataInterface> => {
  switch (cohort) {
    case UserCohorts.CANCELLED:
      return axios.get(`${process.env.REACT_APP_STRAPI}/billboard-cancelled`);
    break;
    case UserCohorts.ONDEMAND:
      return axios.get(`${process.env.REACT_APP_STRAPI}/billboard-ondemand`);
    break;
    case UserCohorts.LEGACY:
      return axios.get(`${process.env.REACT_APP_STRAPI}/billboard-legacy`);
    break;
    case UserCohorts.MEMBER:
      return axios.get(`${process.env.REACT_APP_STRAPI}/billboard-members`);
    break;
    default:
      return axios.get(`${process.env.REACT_APP_STRAPI}/billboard`);
      break;
  }
};

const getBillingAreaRequest = (): AxiosPromise<BillingAreaDataInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/billing-area`);

const getPricingV3StudioRequest = (): AxiosPromise<PricingV3DataInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/pricing-v3?mode=studio`);

const getPricingV4StudioRequest = (): AxiosPromise<PricingV4DataInterface> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/pricing-v4?mode=studio`);


export default {
  getOffboardingDataRequest,
  getBannerContentRequest,
  getOnboardingDataRequest,
  getReferralDataRequest,
  getAnnouncementsRequest,
  getBillingAreaRequest,
  getPricingV4StudioRequest
};
