import React, { ReactElement } from 'react';

import Icon from '../Icon/Icon';
import { timeParserHelper } from '../../../helpers/time-parser.helper';
import styles from './WarningV2.module.scss';

const LEARN_MORE_URL = 'https://help.musiversal.com/submitting-your-pre-session-materials';

interface WarningV2Interface {
  icon: string;
  content: string;
}

const WarningV2 = ({ icon = 'warning', content }: WarningV2Interface): ReactElement => (
    <div className={styles.content}>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <Icon name={icon} />
        </div>
        <p>
          {content}
        </p>
      </div>
    </div>
  );

export default WarningV2;
