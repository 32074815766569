import styles from './InputError.module.css';

export default function InputError({ message, color = '#C53939', fontSize = '14px' }) {
  return (
    <span className={styles.InputError}>
      <span className={styles.InputError__icon}>
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M8.5 15.5C12.366 15.5 15.5 12.366 15.5 8.5C15.5 4.63401 12.366 1.5 8.5 1.5C4.63401 1.5 1.5 4.63401 1.5 8.5C1.5 12.366 4.63401 15.5 8.5 15.5Z'
            stroke={color}
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M8.5 13C9.05228 13 9.5 12.5523 9.5 12C9.5 11.4477 9.05228 11 8.5 11C7.94772 11 7.5 11.4477 7.5 12C7.5 12.5523 7.94772 13 8.5 13Z'
            fill={color}
          />
          <path d='M8.5 4.5V9' stroke='#C53939' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      </span>
      <span style={{ color, fontSize }}>{message}</span>
    </span>
  );
}
