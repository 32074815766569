import React, { useState, useCallback } from 'react';
import Modal from 'react-modal';

const CLOSE_TIMEOUT = 500;

const SidePane = props => {
  const paneState = useState(false);
  const wasOpen = paneState[0];
  const setWasOpen = paneState[1];

  /* Larger version of the Sidepane */
  const isLarge = props.isLarge;

  const handleAfterOpen = useCallback(() => {
    setTimeout(() => {
      setWasOpen(true);
      props.onAfterClose === null || props.onAfterClose === void 0 ? void 0 : onAfterClose();
    }, 0);
  }, [props.onAfterOpen]);

  const handleAfterClose = useCallback(() => {
    setTimeout(() => {
      setWasOpen(false);
      props.onAfterClose === null || props.onAfterClose === void 0 ? void 0 : onAfterClose();
    }, 0);
  }, [props.onAfterClose]);

  return (
    <Modal
      ariaHideApp={false}
      overlayClassName={{
        base: 'slide-pane__overlay',
        afterOpen: wasOpen ? 'overlay-after-open' : '',
        beforeClose: 'overlay-before-close'
      }}
      className={{
        base: isLarge ? 'slide-pane slide-pane_from_right isLarge' : 'slide-pane slide-pane_from_right',
        afterOpen: wasOpen ? 'content-after-open' : '',
        beforeClose: 'content-before-close'
      }}
      closeTimeoutMS={CLOSE_TIMEOUT}
      isOpen={props.isOpen}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      onRequestClose={props.onRequestClose}
      contentLabel={'Modal'}
    >
      {/*<div className={styles.slidePane__header}>
        <div className={styles.slidePane__close} onClick={props.onRequestClose}>
          <p>Close</p>
        </div>
        <div className={styles.slidePane__titleWrapper}>
          <h2 className={styles.slidePane__title}>{props.title}</h2>
          <div className={styles.slidePane__subtitle}>{props.subtitle}</div>
        </div>
      </div>
    <div className={styles.slidePane__content}>{props.children}</div>*/}

      {props.children}
    </Modal>
  );
};

export default SidePane;
