import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { Dispatch, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import Diogo from '../../../../../assets/images/Diogo.png';
import { selectUser } from '../../../../store/slices/user.slice';
import BackButton from '../../../Atoms/BackButton/BackButton';
import { HowItWorksSteps } from '../HowItWorksModal';
import styles from './BookACallStep.module.scss';
import {
  bookACallStepDescription,
  bookACallStepFooter,
  bookACallStepHeader,
  bookACallStepMessage,
  webinarMessage
} from './bookACallStep.const';

interface BookCallStepInterface {
  setOnboardingStep: Dispatch<HowItWorksSteps>;
  onRequestClose: () => void;
}

const BookACallStep = ({ setOnboardingStep, onRequestClose }: BookCallStepInterface): ReactElement => {
  const { email, name } = useSelector(selectUser);
  const firstName = name.split(' ')[0];
  const { showWebinarLink } = useFlags();

  return (
    <>
      <div className={styles.bookACallContainer}>
        <h2 className={styles.title}>{bookACallStepHeader}</h2>
        <p className={styles.titleDescription}>{bookACallStepDescription}</p>
        <div className={styles.messageContainer}>
          <div className={styles.avatar}>
            <img src={Diogo} alt='avatar' width={88} height={88} />
          </div>
          <div className={styles.message}>
            <p>
              Hi {firstName},{"I'm"} Diogo :)
            </p>
            <span>{showWebinarLink ? webinarMessage : bookACallStepMessage}</span>
          </div>
        </div>
        <p className={styles.personTitle}>
          {bookACallStepFooter} <span className={styles.pipe}>|</span>{' '}
          {showWebinarLink ? (
            <a
              href={`https://share.hsforms.com/1dhQ2dtEQTPKtmkZxk55APQ4zn1a?email=${email}&full_name=${name}`}
              target='_blank'
              rel='noreferrer'
            >
              Save my seat
            </a>
          ) : (
            <a
              href='https://meetings.hubspot.com/diogo-melo-de-carvalho/start-up-call'
              target='_blank'
              rel='noreferrer'
            >
              Book a call
            </a>
          )}
        </p>
      </div>
      <div className={styles.footer}>
        <BackButton onClick={(): void => setOnboardingStep(HowItWorksSteps.HOW_IT_WORKS)} />
        <button className={styles.button} onClick={onRequestClose}>
          Finish
        </button>
      </div>
    </>
  );
};

export default BookACallStep;
