export enum MixPanelChoseSignUpMethodProperties {
  METHOD = 'Method'
}

export enum MixPanelChangedPlanProperties {
  PLAN = 'Plan'
}

export enum MixPanelAddedPaymentMethodProperties {
  PLAN = 'Plan',
  COUPON_CODE = 'Coupon Code',
  COUPON_OFFER = 'Coupon Offer'
}
