import axios, { AxiosPromise } from 'axios';

import { CancelReasonInterface } from '../../interfaces/cancel-reason.interface';
import { PricingPlanInterface } from '../../views/payment-details/pricing-slider/pricing-slider.interface';
import {
  CreateSubscriptionPayloadInterface,
  GetCurrentPlanParamsInterface,
  ValidateCouponInterface
} from './subscriptions.interface';

const SUBSCRIPTIONS_API = `${process.env.REACT_APP_API_URL}/api/subscriptions`;
const SUBSCRIPTIONS_API_V2 = `${process.env.REACT_APP_API_URL}/api/v2/subscriptions`;

const axiosInstance = axios.create({
  baseURL: SUBSCRIPTIONS_API,
  withCredentials: true
});

const axiosInstanceV2 = axios.create({
  baseURL: SUBSCRIPTIONS_API_V2,
  withCredentials: true
});

const getPlansRequest = (): AxiosPromise<{ pricing_plans: PricingPlanInterface[] }> =>
  axios.get(encodeURI(`${process.env.REACT_APP_STRAPI}/pricing-v4?mode=studio`));

const getCurrentPlanRequest = (params: Partial<GetCurrentPlanParamsInterface>): AxiosPromise<PricingPlanInterface[]> =>
  axios.get(`${process.env.REACT_APP_STRAPI}/pricing-plans`, {
    params
  });

const createSubscription = (payload: CreateSubscriptionPayloadInterface): AxiosPromise =>
  axiosInstanceV2.post('/create-subscription', payload);

const validateCoupon = (payload: ValidateCouponInterface): AxiosPromise => axiosInstance.post('/check_coupon', payload);

const createCustomerPortalSession = (returnUrl: string): AxiosPromise =>
  axiosInstance.post('/create-customer-portal-session', { returnUrl });

const createCheckoutSession = (priceId: string): AxiosPromise =>
  axiosInstance.post('/create-checkout-session', { priceId });

const downgradeSubscription = (priceId: string): AxiosPromise =>
  axiosInstance.post('/downgrade-subscription', { priceId });

const joinSubscriptionNow = (): AxiosPromise => axiosInstance.post('/start-subscription', {});

const renewSubscription = (): AxiosPromise => axiosInstance.post('/renew-subscription', {});

const cancelSubscription = (reason: CancelReasonInterface): AxiosPromise =>
  axiosInstance.post('/cancel-subscription', { reason });

const createTopUp = (amount: number): AxiosPromise => axiosInstanceV2.post('/stripe/create-topup', { amount });

const purchaseBundle = (bundle_id: number): AxiosPromise => axiosInstanceV2.post('/stripe/purchase-bundle', { bundle_id });

const stripeCreateSubscriptionWithCheckoutSession = (priceId: string): AxiosPromise<{ url: string }> =>
  axiosInstanceV2.post('/stripe/create-subscription-v2', { priceId });

export default {
  getPlansRequest,
  createSubscription,
  validateCoupon,
  createCustomerPortalSession,
  joinSubscriptionNow,
  createCheckoutSession,
  downgradeSubscription,
  getCurrentPlanRequest,
  renewSubscription,
  cancelSubscription,
  createTopUp,
  purchaseBundle,
  stripeCreateSubscriptionWithCheckoutSession
};
