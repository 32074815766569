import moment from 'moment-timezone';
import React, { ReactElement, useEffect, useState } from 'react';
import { Control, useForm, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ModalInterface } from '../../../interfaces/modal.interface';
import { getOnboardingModalData } from '../../../store/actions/modals.actions';
import { selectIsPartnersDashboard, setIsShowGlobalLoader } from '../../../store/slices/global.slice';
import { selectOnboardingModalData, setIsShowOnboardingModal } from '../../../store/slices/modals.slice';
import { selectUser } from '../../../store/slices/user.slice';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import styles from './OnboardingModal.module.scss';
import InterestsStep from './steps/InterestsStep/InterestsStep';
import PersonaDiyStep from './steps/PersonaDiyStep/PersonaDiyStep';
import PersonaLevelStep from './steps/PersonaLevelStep/PersonaLevelStep';
import PersonaTypeStep from './steps/PersonaTypeStep/PersonaTypeStep';
import ThankYouStep from './steps/ThankYouStep/ThankYouStep';
import TimezoneStep from './steps/TimezoneStep/TimezoneStep';
import WelcomeStep from './steps/WelcomeStep/WelcomeStep';

export enum Steps {
  WELCOME = 'WELCOME',
  TIMEZONE = 'TIMEZONE',
  PERSONA_TYPE = 'PERSONA_TYPE',
  PERSONA_LEVEL = 'PERSONA_LEVEL',
  PERSONA_DIY = 'PERSONA_DIY',
  INTERESTS = 'INTERESTS',
  THANK_YOU = 'THANK_YOU',
  HOW_PLATFORM_WORKS = 'HOW_PLATFORM_WORKS'
}

export interface OnboardingStepInterface {
  goToStep: (step: Steps) => () => void;
  register?: UseFormRegister<any>;
  control?: Control<FormInterface>;
  getValues?: () => FormInterface;
  setValue?: UseFormSetValue<any>;
  watch?: UseFormWatch<any>;
}

interface FormInterface {
  timezone: {
    label: string;
    value: string;
  };
  personaType: string;
  personaLevel: string;
  personaDiy: string;
  comments: string;
}

const OnboardingModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);
  const onboardingModalData = useSelector(selectOnboardingModalData, shallowEqual);

  const checkboxOptions = onboardingModalData?.Step6.options.map(({ value }) => value) || [];

  const guessedTimezone = user.timezone || moment.tz.guess();

  useEffect((): void => {
    if (user.isOnboardingCompleted || isPartnersDashboard) {
      return;
    }
    const getOnboardingData = async (): Promise<void> => {
      dispatch(setIsShowGlobalLoader(true));
      await dispatch(getOnboardingModalData());
      dispatch(setIsShowGlobalLoader(false));
    };
    getOnboardingData().finally();
  }, [dispatch, user.isOnboardingCompleted, isPartnersDashboard]);

  const { control, getValues, watch, register, setValue } = useForm<FormInterface>({
    defaultValues: {
      timezone: {
        value: guessedTimezone,
        label: guessedTimezone
      }
    }
  });

  watch(['personaType', 'personaLevel', 'personaDiy', 'comments', ...checkboxOptions]);

  const [step, setStep] = useState(Steps.WELCOME);

  if (!onboardingModalData) {
    return <></>;
  }

  const onRequestClose = (): void => {
    dispatch(setIsShowOnboardingModal(false));
  };

  const goToStep = (toStep: Steps): (() => void) => (): void => setStep(toStep);

  return (
    <ModalWrapper
      isOpen={isOpen}
      isPreventClosing={step !== Steps.THANK_YOU}
      className={styles.modal}
      onRequestClose={onRequestClose}
    >
      <div hidden={step !== Steps.WELCOME}>
        <div className={styles.container}>
          <WelcomeStep goToStep={goToStep} />
        </div>
      </div>
      <div hidden={step !== Steps.TIMEZONE}>
        <div className={styles.container}>
          <TimezoneStep goToStep={goToStep} control={control} />
        </div>
      </div>
      <div hidden={step !== Steps.PERSONA_TYPE}>
        <div className={styles.container}>
          <PersonaTypeStep goToStep={goToStep} control={control} getValues={getValues} />
        </div>
      </div>
      <div hidden={step !== Steps.PERSONA_LEVEL}>
        <div className={styles.container}>
          <PersonaLevelStep goToStep={goToStep} control={control} getValues={getValues} />
        </div>
      </div>
      <div hidden={step !== Steps.PERSONA_DIY}>
        <div className={styles.container}>
          <PersonaDiyStep goToStep={goToStep} control={control} getValues={getValues} />
        </div>
      </div>
      <div hidden={step !== Steps.INTERESTS}>
        <div className={styles.container}>
          <InterestsStep
            goToStep={goToStep}
            control={control}
            getValues={getValues}
            register={register}
            setValue={setValue}
            watch={watch}
          />
        </div>
      </div>
      <div hidden={step !== Steps.THANK_YOU}>
        <div className={styles.container}>
          <ThankYouStep />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default OnboardingModal;
