import axios, { AxiosPromise } from 'axios';

import { RatingCodeResponse, RatingInterface } from './referral.interface';

const RATING_API = `${process.env.REACT_APP_API_URL}/api/v2/ratings`;

const axiosReferralInstance = axios.create({
  baseURL: RATING_API,
  withCredentials: true
});

const createRating = (payload: RatingInterface): AxiosPromise<ReferralInterface> => axiosReferralInstance.post('', payload);

export default {
  createRating
};
