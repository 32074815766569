import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './SpeedLimitModal.module.scss';
import BillingModal from '../BillingModal/BillingModal';
import { setIsShowSpeedLimitModal } from '../../../store/slices/modals.slice';

const SpeedLimitModal = ({ isOpen }) => {
  const MAX_SESSIONS_ALLOWED = 30;

  const dispatch = useDispatch();

  const closeSpeedLimitModal = () => {
    dispatch(setIsShowSpeedLimitModal(false));
  };

  return (
    <>
      <BillingModal isOpen={isOpen} onRequestClose={closeSpeedLimitModal}>
        <div className={styles.container}>
          <button className={styles.close} onClick={closeSpeedLimitModal}>
            Close
          </button>
          <h1 className={styles.title}>Ooops!</h1>
          <p className={styles.description}>
            It looks like you already have {MAX_SESSIONS_ALLOWED} sessions booked at this moment, which is the maximum speed limit. This is in place to ensure that there's fair availability for all users at any given time.
          </p>
          <p className={styles.description}>
            You’ll be able to book more sessions as soon as you’re under the 30 sessions limit. Thank you for your understanding.
          </p>
        </div>
      </BillingModal>
    </>
  );
};

export default SpeedLimitModal;
