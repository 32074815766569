import { unwrapResult } from '@reduxjs/toolkit';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ToastTypes } from '../../../../../../enums/toast-types.enum';
import { useToast } from '../../../../../../hooks/useToast';
import { ReferralDataInterface } from '../../../../../../interfaces/referral-data.interface';
import { createReferral } from '../../../../../../store/actions/referral.actions';
import { selectReferralData } from '../../../../../../store/slices/referral.slice';
import { ThunkAppDispatch } from '../../../../../../store/store';
import Button from '../../../../../Atoms/Button/Button';
import InputError from '../../../../../Atoms/InputError/InputError';
import styles from './CreateReferral.module.scss';

const CreateReferral = (): ReactElement => {
  const dispatch = useDispatch<ThunkAppDispatch>();
  const { referralTabButtonCopy } = useSelector(selectReferralData, shallowEqual) as ReferralDataInterface;

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    clearErrors,
    watch,
    getValues
  } = useForm();
  const { sendToast } = useToast();

  watch('email');

  const onRefer = async ({ email }: { email: string }): Promise<void> => {
    try {
      unwrapResult(await dispatch(createReferral(email)));
      setValue('email', '');
      sendToast({ message: 'Your invitation was sent!', toastType: ToastTypes.SUCCESS });
    } catch (e) {
      const message = e.response?.data?.msg || 'It was not possible to send your invitation.';
      sendToast({ message, toastType: ToastTypes.DANGER });
    }
  };

  return (
    <form onSubmit={handleSubmit(onRefer)} className={styles.form}>
      <label className={styles.label}>
        Refer a friend
        <div className={styles.inputContainer}>
          <input
            onClick={(): void => clearErrors()}
            className={styles.input}
            type='text'
            maxLength={255}
            placeholder='Enter your friend’s email.'
            {...register('email', {
              required: 'Email address is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address'
              }
            })}
          />
          <Button type='submit' value={referralTabButtonCopy} className={styles.button} disabled={!getValues().email} />
        </div>
        {errors.email && <InputError message={errors.email.message} />}
      </label>
    </form>
  );
};

export default CreateReferral;
