import { useEffect, useState } from 'react';

export const MOBILE_DEVICE_WIDTH_BREAKPOINT = 767;
export const SMALL_SCREEN_WIDTH_BREAKPOINT = 1367;
export const MOBILE_GRID_SCREEN_WIDTH_BREAKPOINT = 949;

export const useIsMobileResolution = (): boolean => {
  const [isMobileResolution, setIsMobileResolution] = useState(false);

  useEffect((): VoidFunction => {
    const checkIfMobileResolution = (): void => {
      setIsMobileResolution(
        window.innerWidth < MOBILE_DEVICE_WIDTH_BREAKPOINT || window.innerWidth < window.innerHeight
      );
    };
    checkIfMobileResolution();
    window.addEventListener('resize', checkIfMobileResolution);
    return (): void => {
      window.removeEventListener('resize', checkIfMobileResolution);
    };
  }, []);

  return isMobileResolution;
};

export const useIsMobileResolutionGrid = (): boolean => {
  const [isMobileResolution, setIsMobileResolution] = useState(false);

  useEffect((): VoidFunction => {
    const checkIfMobileResolution = (): void => {
      setIsMobileResolution(
        window.innerWidth < MOBILE_GRID_SCREEN_WIDTH_BREAKPOINT
      );
    };
    checkIfMobileResolution();
    window.addEventListener('resize', checkIfMobileResolution);
    return (): void => {
      window.removeEventListener('resize', checkIfMobileResolution);
    };
  }, []);

  return isMobileResolution;
};

export const useSmallScreenResolution = (): boolean => {
  const [isSmallScreenResolution, setIsSmallScreenResolution] = useState(false);

  useEffect((): VoidFunction => {
    const checkIfSmallScreenResolution = (): void => {
      setIsSmallScreenResolution(
        window.innerWidth < SMALL_SCREEN_WIDTH_BREAKPOINT// || window.innerWidth < window.innerHeight
      );
    };
    checkIfSmallScreenResolution();
    window.addEventListener('resize', checkIfSmallScreenResolution);
    return (): void => {
      window.removeEventListener('resize', checkIfSmallScreenResolution);
    };
  }, []);

  return isSmallScreenResolution;
};
