const oneHourInMinutes = 60;
const twentyFourHoursInMinutes = 24 * oneHourInMinutes;

export const timeParserHelper = (timeInMinutes: number): string => {
  const minutes = +timeInMinutes;
  if (minutes < twentyFourHoursInMinutes && timeInMinutes >= oneHourInMinutes) {
    const hours = (minutes / 60).toFixed(2);
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  } else if (minutes >= twentyFourHoursInMinutes) {
    const days = (minutes / 60 / 24).toFixed(2);
    return `${days} ${days === 1 ? 'day' : 'days'}`;
  }
  return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
};
