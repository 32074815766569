import classnames from 'classnames';
import React, { ReactElement, useMemo } from 'react';

import { Colors } from '../../../enums/colors.enum';
import Icon from '../Icon/Icon';
import InputError from '../InputError/InputError';
import { StyledSelectInterface } from './StyledSelect.interface';
import styles from './StyledSelect.module.scss';

const StyledSelect = ({
  options = [],
  label,
  name,
  id,
  registerRef,
  errors,
  clearErrors,
  width,
  height,
  style,
  placeholder,
  className,
  isNoFocus,
  labelValueReplacements = ['label', 'value'],
  defaultValue
}: StyledSelectInterface): ReactElement => {
  const errs = useMemo((): string[] => errors?.filter(err => !!err), [errors]);

  return (
    <div className={classnames(styles.container, className)} style={style}>
      <label>{label}</label>
      <div className={classnames(styles.inputContainer, isNoFocus && styles.noFocus)}>
        <Icon className={styles.arrow} name='arrow-left' />
        <select
          defaultValue={defaultValue}
          style={{ width, height }}
          className={classnames(errs?.length && styles.hasErrors)}
          id={id}
          placeholder={placeholder}
          name={name}
          onClick={clearErrors}
          {...registerRef}
        >
          {options.map(({ [labelValueReplacements[0]]: optionLabel, [labelValueReplacements[1]]: value }) => (
            <option key={value} value={value}>
              {optionLabel}
            </option>
          ))}
        </select>
      </div>
      {!!errs?.length && (
        <div className={styles.errors}>
          {errs.map((error, idx) => (
            <InputError key={`${error}_${idx}`} message={errors} color={Colors.ERROR} />
          ))}
        </div>
      )}
    </div>
  );
};

export default StyledSelect;
