import React, { ReactElement } from 'react';

import { UserStatuses } from '../../../enums/user-statuses.enum';
import Icon from '../Icon/Icon';
import { cancelledAccountMessage } from './AttentionMessage.const';
import styles from './AttentionMessage.module.scss';

interface AttentionMessageInterface {
  userStatus: UserStatuses;
}

const AttentionMessage = ({ userStatus }: AttentionMessageInterface): ReactElement => {
  switch (userStatus) {
    case UserStatuses.CANCELLED:
      return (
        <div className={styles.container}>
          <Icon name={'alert-triangle-outline'} className={styles.alertIcon} /> {cancelledAccountMessage}
        </div>
      );
    default:
      return <></>;
  }
};

export default AttentionMessage;
