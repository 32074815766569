import React, { ReactElement } from 'react';

import Icon from '../Icon/Icon';
import styles from './PreSessionSpecs.module.scss';

const CANCELLATION_POLICY_URL = 'https://help.musiversal.com/how-can-i-cancel-my-upcoming-session';

interface PreSessionSpecsInterface {
  // children: ReactElement;
  timezone: string;
  sessionDuration: number;
  sessionCreditsResult: number;
  offlineService: boolean;
}

const PreSessionSpecs = ({ timezone, sessionCreditsResult, sessionDuration, offlineService }: PreSessionSpecsInterface): ReactElement => (
  <div className={styles.content}>
    <div className={styles.wrapper}>
      {sessionDuration == 0 ? (
        <>
          <div className={styles.preSessionSpecsIcon}>
            <Icon name={offlineService ? 'offline' : 'stopWatch'} />
          </div>
          <p>
            Select one or multiple slots to book a session
          </p>
        </>
      ) : (
        <>
          <div className={styles.preSessionSpecsIcon}>
            <Icon name={offlineService ? 'offline' : 'stopWatch'} />
          </div>
          <div className={styles.description}>
            { offlineService ? (
              <span className={styles.duration}>
                Offline session
              </span>
            ) : (
              <span className={styles.duration}>
                {sessionDuration} min. session
              </span>
            )}
            <span className={styles.totalCredits}>
              ({sessionCreditsResult} {sessionCreditsResult <= 1 ? 'credit' : 'credits'})
            </span>
          </div>
          <a className={styles.cancellationPolicyLink}
            href={CANCELLATION_POLICY_URL}
            rel='noreferrer'
            target='_blank'>
            Cancelation policy
          </a>
        </>
      )}
    </div>
  </div>
);

export default PreSessionSpecs;
