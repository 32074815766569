import classNames from 'classnames';
import React, { ReactElement } from 'react';
import Checkbox from 'react-custom-checkbox';
import { Control, Controller } from 'react-hook-form';

import Icon from '../Icon/Icon';
import styles from './CustomCheckbox.module.scss';

interface CheckboxInterface {
  name: string;
  label: string;
  control: Control<any>;
  className?: string;
  containerClassName?: string;
  checkedContainerClassName?: string;
  labelClassName?: string;
  required?: boolean;
}

const CustomCheckbox = ({
  name,
  label,
  className,
  containerClassName,
  checkedContainerClassName,
  labelClassName,
  control
}: CheckboxInterface): ReactElement => (
  <Controller
    control={control}
    name={name}
    rules={{
      required: true
    }}
    render={({ field: { onChange, value } }) => (
      <Checkbox
        icon={
          <div className={styles.icon}>
            <Icon name={'checkboxTick'} />
          </div>
        }
        name={name}
        checked={value}
        onChange={onChange}
        label={label}
        borderColor={'#DE6139'}
        borderRadius={5}
        size={19}
        style={{ overflow: 'hidden' }}
        className={classNames(styles.checkbox, className)}
        labelClassName={labelClassName}
        containerClassName={classNames(styles.container, containerClassName, value && checkedContainerClassName)}
      />
    )}
  />
);

export default CustomCheckbox;
