import moment from 'moment-timezone';
import React, { ReactElement } from 'react';
import { Control } from 'react-hook-form';

import CustomSelect from '../../Select/Select';

const timezones = moment.tz.names().map(timezone => ({ value: timezone, label: timezone }));

interface TimezoneSelectInterface {
  control: Control<any>;
  isNoLabel?: boolean;
  className?: string;
  containerClassName?: string;
}

const TimezoneSelect = ({
  control,
  isNoLabel,
  className,
  containerClassName
}: TimezoneSelectInterface): ReactElement => (
  <CustomSelect
    control={control}
    label={isNoLabel ? '' : 'Timezone'}
    options={timezones}
    required
    name={'timezone'}
    isLongList
    className={className}
    containerClassName={containerClassName}
    menuPortalTarget={document.querySelector('body')}
  />
);

export default TimezoneSelect;
