import React from 'react';

import styles from './Button.module.css';
import classNames from 'classnames';

const Button = props => (
  <button
    onClick={props.onClick}
    className={classNames(styles.button, props.className)}
    disabled={props.disabled}
    type={props.type}
    data-tip={props['data-tip']}
  >
    {props.children ?
      <div className={styles.container}>
        {props.value}
        {props.children}
      </div> :
      props.value
    }
  </button>
);
export default Button;
