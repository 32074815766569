import axios, { AxiosPromise } from 'axios';

import { TrackInterface } from '../../interfaces/track.interface';

const BASE_API = `${process.env.REACT_APP_API_URL}/api/v2/tracks`;

const axiosInstance = axios.create({
  baseURL: BASE_API,
  withCredentials: true
});

const createTrack = (track: TrackInterface): AxiosPromise<TrackInterface> => axiosInstance.post('', track);

const getTracksRequest = (): AxiosPromise<TrackInterface[]> => axiosInstance.get('');

export default {
  createTrack,
  getTracksRequest
};
