import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useRef, useState } from 'react';

import Navbar from '../../../../components/Atoms/Navbar/Navbar.js';
import BasicInfoForm from '../../../../components/Molecules/Profile/BasicInfoForm/BasicInfoForm.js';
import Billing from '../../../../components/Molecules/Profile/Billing/Billing.js';
import NotificationsForm from '../../../../components/Molecules/Profile/NotificationsForm/NotificationsForm.js';
import SecurityForm from '../../../../components/Molecules/Profile/SecurityForm/SecurityForm.js';
import { localStorageKeys } from '../../../../constants/localStorageKeys.const';
import styles from './Profile.module.scss';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../../store/slices/user.slice';
import { getMe } from '../../../../store/actions/user.actions';
import { useHistory, useLocation } from 'react-router-dom';
import { selectIsPartnersDashboard, setIsShowGlobalLoader } from '../../../../store/slices/global.slice';
import { UserStatuses } from '../../../../enums/user-statuses.enum';
import { PaymentProviders } from '../../../../enums/payment-providers.enum';
import { ToastTypes } from '../../../../enums/toast-types.enum';
import { getCurrentPlan } from '../../../../store/actions/global.actions';
import { useToast } from '../../../../hooks/useToast';
import { ProfileTabs } from '../../../../enums/profile-tabs.enum';
import Invoice from '../../../../components/Molecules/Profile/Invoice/Invoice';
import MusicianCalendar from '../../../../components/Molecules/MusicianCalendar/MusicianCalendar';
import { setIsShowHowItWorksModal } from '../../../../store/slices/modals.slice';
import { isAllowedReferralHelper } from '../../../../helpers/is-allowed-referral.helper';
import classNames from 'classnames';
import { Routes } from '../../../../enums/routes.enum';
import Referral from '../../../../components/Molecules/Profile/Referral/Referral';
import { getReferrals } from '../../../../store/actions/referral.actions';
import { getUserCreditsLeftHelper } from '../../../../helpers/get-user-credits-left.helper';

const INTERVAL_PERIOD_IN_MS = 1000;
const GET_ME_LIMIT = 20;

const Profile = () => {
  const user = useSelector(selectUser, shallowEqual);
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(ProfileTabs.PROFILE);
  const { profileInfoButton } = useFlags();
  const location = useLocation();
  const history = useHistory();
  const { sendToast } = useToast();

  const getMeInterval = useRef();
  const getMeRequestCounter = useRef(0);

  useEffect(() => {
    const getPlan = async () => {
      await dispatch(getCurrentPlan(user));
    };
    getPlan().finally();
  }, [user, dispatch]);

  useEffect(() => {
    if (!isAllowedReferralHelper(user.status, isPartnersDashboard)) {
      return;
    }
    const getReferralsWrapper = async () => {
      await dispatch(getReferrals());
    };
    getReferralsWrapper().finally();
  }, [dispatch, user.status, isPartnersDashboard]);

  const selectTab = tab => () => {
    history.push(tab === ProfileTabs.REFERRAL ? Routes.REFERRAL : Routes.PROFILE);
    setSelectedTab(tab);
  };

  useEffect(() => {
    if (location.pathname !== Routes.REFERRAL) {
      return;
    }
    if (!isAllowedReferralHelper(user.status, isPartnersDashboard)) {
      selectTab(ProfileTabs.PROFILE)();
      return;
    }
    setSelectedTab(ProfileTabs.REFERRAL);
  }, [location.pathname, selectTab, user.status]);

  useEffect(() => {
    if (!location.hash) {
      return;
    }
    const hashWithoutParams = location.hash.split('?')[0];
    setSelectedTab(hashWithoutParams.split('#')[1]);
    history.push(location.pathname);
  }, [location, history]);

  useEffect(() => {
    if (!location.hash) {
      return;
    }
    const params = ['billing', 'session_id'];
    const isTopUpParam = 'purchase=topup';
    const isTopUp = location.hash?.includes(isTopUpParam);
    if (isTopUp) {
      sendToast({ message: 'Credits added', toastType: ToastTypes.SUCCESS });
    }
    const areParamsIncluded = params.every(param => location.hash?.includes(param));
    if (!areParamsIncluded) {
      return;
    }

    dispatch(setIsShowGlobalLoader(true));

    getMeInterval.current = setInterval(() => {
      getMeRequestCounter.current += 1;

      if (getMeRequestCounter.current > GET_ME_LIMIT && user.subscription.paymentsProvider !== PaymentProviders.PAYPAL) {
        clearInterval(getMeInterval.current);
        dispatch(setIsShowGlobalLoader(false));
        return;
      }

      dispatch(getMe());
    }, INTERVAL_PERIOD_IN_MS);
  }, [location.hash, dispatch, user.subscription]);

  useEffect(() => {
    const currentPlanId = localStorage.getItem(localStorageKeys.CURRENT_PLAN_ID);
    const currentUserStatus = localStorage.getItem(localStorageKeys.USER_STATUS);
    const currentSessionsLeft = parseInt(localStorage.getItem(localStorageKeys.SESSIONS_LEFT));

    const stopInterval = () => {
      clearInterval(getMeInterval.current);
      history.push(location.pathname);
      dispatch(setIsShowGlobalLoader(false));
    };

    if (currentUserStatus && user.status !== currentUserStatus) {
      if (user.status === UserStatuses.SUBSCRIBER) {
        sendToast({ message: 'Subscription enabled', toastType: ToastTypes.SUCCESS });
      }
      clearInterval(getMeInterval.current);
      localStorage.setItem(localStorageKeys.USER_STATUS, user.status);
      stopInterval();
    }
    if (user.subscription?.currentPrice !== currentPlanId) {
      localStorage.setItem(localStorageKeys.CURRENT_PLAN_ID, user.subscription?.currentPrice);
      stopInterval();
    }
    const userCredits = getUserCreditsLeftHelper(user);
    if (userCredits !== currentSessionsLeft) {
      localStorage.setItem(localStorageKeys.SESSIONS_LEFT, userCredits.toString());
      stopInterval();
    }
    //eslint-disable-next-line
  }, [user, location.pathname]);

  const onHelpClick = () => {
    dispatch(setIsShowHowItWorksModal(true));
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Navbar
          isTrial={selectedTab !== ProfileTabs.BILLING && user?.status === 'trial'}
        />

        <div className={styles.container}>
          <div className={styles.header}>
            <h1 className={styles.headerTitle}>My Profile</h1>
          </div>

          <div className={styles.headerNav}>
            <a
              className={classNames(styles.headerNavLink, selectedTab === ProfileTabs.PROFILE && styles.isActive)}
              onClick={selectTab(ProfileTabs.PROFILE)}
            >
              Profile
            </a>
            {isPartnersDashboard && (
              <>
                <a
                  className={classNames(styles.headerNavLink, selectedTab === ProfileTabs.CALENDAR && styles.isActive)}
                  onClick={selectTab(ProfileTabs.CALENDAR)}
                >
                  My Calendar
                </a>
                <a
                  className={classNames(styles.headerNavLink, selectedTab === ProfileTabs.INVOICE && styles.isActive)}
                  onClick={selectTab(ProfileTabs.INVOICE)}
                >
                  Invoice
                </a>
              </>
            )}
            <a
              className={classNames(styles.headerNavLink, selectedTab === ProfileTabs.NOTIFICATIONS && styles.isActive)}
              onClick={selectTab(ProfileTabs.NOTIFICATIONS)}
            >
              Notifications
            </a>
            <a
              className={classNames(styles.headerNavLink, selectedTab === ProfileTabs.SECURITY && styles.isActive)}
              onClick={selectTab(ProfileTabs.SECURITY)}
            >
              Security
            </a>
            {!isPartnersDashboard && (
              <a
                className={classNames(styles.headerNavLink, selectedTab === ProfileTabs.BILLING && styles.isActive)}
                onClick={selectTab(ProfileTabs.BILLING)}
              >
                Billing
              </a>
            )}
            {isAllowedReferralHelper(user.status, isPartnersDashboard) && (
              <a
                className={classNames(styles.headerNavLink, selectedTab === ProfileTabs.REFERRAL && styles.isActive)}
                onClick={selectTab(ProfileTabs.REFERRAL)}
              >
                Referral
              </a>
            )}
          </div>

          {selectedTab === ProfileTabs.PROFILE && (
            <div className={styles.profileContentSmall}>
              <BasicInfoForm user={user} />
            </div>
          )}
          {selectedTab === ProfileTabs.NOTIFICATIONS && (
            <div className={styles.profileContentSmall}>
              <NotificationsForm />
            </div>
          )}
          {selectedTab === ProfileTabs.SECURITY && (
            <div className={styles.profileContentSmall}>
              <SecurityForm user={user} />
            </div>
          )}
          {selectedTab === ProfileTabs.CALENDAR && (
            <MusicianCalendar isFullWidth />
          )}
          {selectedTab === ProfileTabs.INVOICE && (
            <Invoice />
          )}
          {selectedTab === ProfileTabs.BILLING && (
            <div className={styles.profileContentLarge}>
              <Billing />
            </div>
          )}
          {selectedTab === ProfileTabs.REFERRAL && (
            <div className={styles.profileContentLarge}>
              <Referral />
            </div>
          )}
          {profileInfoButton && user?.status === UserStatuses.TRIAL && (
            <div onClick={onHelpClick} className={styles.profileInfoButton}>
              i
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
