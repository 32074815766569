import { createAsyncThunk } from '@reduxjs/toolkit';

import { PaymentProviders } from '../../enums/payment-providers.enum';
import { UserInterface } from '../../interfaces/user.interface';
import CmsService from '../../services/cms/cms.service';
import SubscriptionService from '../../services/subscriptions/subscriptions.service';
import { getUserCohort } from '../../helpers/cohorts.helper';
import { UserCohorts } from '../../enums/cohorts.enum';
import { PricingPlanInterface } from '../../views/payment-details/pricing-slider/pricing-slider.interface';
import { SlicesNames } from '../slices/slices-names.enum';

export const getPlans = createAsyncThunk(
  `${SlicesNames.GLOBAL_SLICE}/getPlans`,
  async (user: UserInterface): Promise<PricingPlanInterface[]> => {
    try {
      if (!user) {
        return (await SubscriptionService.getPlansRequest()).data.pricing_plans;
      }
      const cohort = getUserCohort(user);
      const { data } = await SubscriptionService.getPlansRequest();
      const response = {
        memberships: [],
        bundles: [],
        orchestras: []
      };
      switch (cohort) {
        case UserCohorts.PUBLIC:
          response.memberships = data.public.memberships.map(x => {
            const pp = x.pricing_plan;
            pp.bullets = x.bullets.map(b => b.studio_pricing_bullet);
            return pp;
          });
          response.bundles = data.public.bundles.map(x => {
            const pp = x.pricing_bundle;
            pp.bullets = x.bullets.map(b => b.studio_pricing_bullet);
            return pp;
          });
          response.orchestras = data.public.orchestras.map(x => {
            const pp = x.pricing_external;
            pp.bullets = x.bullets.map(b => b.studio_pricing_bullet);
            return pp;
          });
          return response;
        break;
        case UserCohorts.CANCELLED:
          return data.cancelled.memberships.map(x => {
            const pp = x.pricing_plan;
            pp.bullets = x.bullets.map(b => b.studio_pricing_bullet);
            return pp;
          });
        break;
        case UserCohorts.ONDEMAND:
          return data.ondemand.memberships.map(x => {
            const pp = x.pricing_plan;
            pp.bullets = x.bullets.map(b => b.studio_pricing_bullet);
            return pp;
          });
        break;
        case UserCohorts.LEGACY:
          return data.legacy.memberships.map(x => {
            const pp = x.pricing_plan;
            pp.bullets = x.bullets.map(b => b.studio_pricing_bullet);
            return pp;
          });
        break;
        case UserCohorts.MEMBER:
          return data.member.memberships.map(x => {
            const pp = x.pricing_plan;
            pp.bullets = x.bullets.map(b => b.studio_pricing_bullet);
            return pp;
          });
        break;
        default:
          return data.pricing_plans;
          break;
      }
    } catch (e) {
      console.error(e);
    }
  }
);

export const getCurrentPlan = createAsyncThunk(
  `${SlicesNames.GLOBAL_SLICE}/getCurrentPlan`,
  async (user: UserInterface): Promise<PricingPlanInterface> => {
    if (!user?.subscription) {
      return;
    }
    try {
      const { currentPrice, paymentsProvider } = user.subscription;
      if (!currentPrice) {
        return null;
      }
      const params = {};
      if (paymentsProvider === PaymentProviders.PAYPAL) {
        params[
          process.env.REACT_APP_NODE_ENV === 'production' ? 'paypalProdPriceId_eq' : 'paypalDevPriceId_eq'
        ] = currentPrice;
      } else {
        params[
          process.env.REACT_APP_NODE_ENV === 'production' ? 'stripeProdPriceId_eq' : 'stripeDevPriceId_eq'
        ] = currentPrice;
      }
      return (await SubscriptionService.getCurrentPlanRequest(params)).data?.[0] || null;
    } catch (e) {
      console.error(e);
    }
  }
);

export const getBannerContent = createAsyncThunk(
  `${SlicesNames.GLOBAL_SLICE}/getBannerContent`,
  async (): Promise<string> => {
    try {
      return (await CmsService.getBannerContentRequest()).data.body;
    } catch (e) {
      console.error(e);
    }
  }
);
