import React, { ReactElement, useState, useEffect } from 'react';

import { UserStatuses } from '../../../../../enums/user-statuses.enum';
import Icon from '../../../../Atoms/Icon/Icon.js';
import strapiService from '../../../../../services/cms/cms.service';
import { cancelledAccountContent, pendingCancellationAccountContent } from './TopNote.const';
import styles from './TopNote.module.scss';

interface TopNoteInterface {
  userStatus: UserStatuses;
}

const TopNote = ({ userStatus }: TopNoteInterface): ReactElement => {

  const [benefits, setBenefits] = useState([]);

  useEffect((): void => {
    const getBenefits = async () => {
      const { cancellationArea } = (await strapiService.getBillingAreaRequest()).data;
      if (cancellationArea?.missingBenefits && cancellationArea?.missingBenefits.length > 0) {
        const bts = cancellationArea?.missingBenefits.map(bf => bf.benefit);
        setBenefits(bts);
      }
      return;
    };
    if (userStatus === UserStatuses.CANCELLED) {
      getBenefits();
      return;
    }
    if (userStatus === UserStatuses.ONDEMAND) {
      getBenefits();
      return;
    }
    console.log('Loaded');
  }, []);

  switch (userStatus) {
    case UserStatuses.PENDING_CANCELLATION:
      return (
        <div className={styles.container} dangerouslySetInnerHTML={{ __html: pendingCancellationAccountContent }} />
      );
    case UserStatuses.CANCELLED:
      return (
      <div className={styles.containerV2}>
        <div className={styles.header}>
          Restart the membership and get access to:
        </div>

        {benefits && benefits.length > 0 && (
          <div className={styles.benefits}>
            <div className={styles.benefitsCol}>
              { benefits.slice(0, Math.ceil(benefits.length / 2)).map((benefit, index) => (
                <div key={index} className={styles.benefitItem}>
                  <Icon name={benefit.icon} className={styles.alertIcon} /> {benefit.body}
                </div>
              ))}
            </div>
            <div className={styles.benefitsCol}>
              { benefits.slice(Math.ceil(benefits.length / 2)).map((benefit, index) => (
                <div key={index} className={styles.benefitItem}>
                  <Icon name={benefit.icon} className={styles.alertIcon} /> {benefit.body}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className={styles.footer}>
          If you have any questions about starting a new membership, we&apos;d be happy to help. <a href='https://share.hsforms.com/18FoFHA41TZ-c-Dku-Wpcpg4zn1a'>Contact our team here</a>.
        </div>
      </div>
      );
      case UserStatuses.ONDEMAND:
        return (
        <div className={styles.containerV2}>
          <div className={styles.header}>
            Become a member and get unlimited access to the Studio
          </div>

          {benefits && benefits.length > 0 && (
            <div className={styles.benefits}>
              <div className={styles.benefitsCol}>
                { benefits.slice(0, Math.ceil(benefits.length / 2)).map((benefit, index) => (
                  <div key={index} className={styles.benefitItem}>
                    <Icon name={benefit.icon} className={styles.alertIcon} /> {benefit.body}
                  </div>
                ))}
              </div>
              <div className={styles.benefitsCol}>
                { benefits.slice(Math.ceil(benefits.length / 2)).map((benefit, index) => (
                  <div key={index} className={styles.benefitItem}>
                    <Icon name={benefit.icon} className={styles.alertIcon} /> {benefit.body}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={styles.footer}>
            If you have any questions about starting a new membership, we&apos;d be happy to help. <a href='https://share.hsforms.com/18FoFHA41TZ-c-Dku-Wpcpg4zn1a'>Contact our team here</a>.
          </div>
        </div>
        );
    default:
      return <></>;
  }
};

export default TopNote;
