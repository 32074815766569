import { Dispatch } from 'redux';

import { localStorageKeys } from '../constants/localStorageKeys.const';
import { PaymentProviders } from '../enums/payment-providers.enum';
import { UserInterface } from '../interfaces/user.interface';
import PaypalService from '../services/subscriptions/paypal.service';
import SubscriptionsService from '../services/subscriptions/subscriptions.service';
import { setIsShowGlobalLoader } from '../store/slices/global.slice';
import { PricingPlanInterface } from '../views/payment-details/pricing-slider/pricing-slider.interface';
import { getPlanPriceIdByEnvHelper } from './get-plan-price-id-by-env.helper';
import { getUserCreditsLeftHelper } from './get-user-credits-left.helper';
import { translateUserSubscription } from './translate-user-subscription';

interface RestartBillingCycleHelperInterface {
  plans: PricingPlanInterface[];
  user: UserInterface;
  dispatch: Dispatch;
  stripePromise: Promise<any>;
}

export const restartBillingCycleHelper = async ({
  plans,
  user,
  dispatch,
  stripePromise
}: RestartBillingCycleHelperInterface): Promise<void> => {
  const { sessionsLeft, tierNumber, paymentsProvider, isAnnual, currentPrice } = translateUserSubscription(user);

  const planSubstitute = plans.find(plan => plan.isAnnual === isAnnual && +plan.tier === +tierNumber);
  if (!planSubstitute) {
    return;
  }
  const priceId = getPlanPriceIdByEnvHelper(planSubstitute, paymentsProvider);
  dispatch(setIsShowGlobalLoader(true));
  localStorage.setItem(localStorageKeys.CURRENT_PLAN_ID, currentPrice);
  localStorage.setItem(localStorageKeys.SESSIONS_LEFT, getUserCreditsLeftHelper(user).toString());
  try {
    if (paymentsProvider === PaymentProviders.PAYPAL) {
      const { url } = (await PaypalService.upgradeSubscription(priceId)).data;
      window.location.href = url;
    } else {
      const res = await SubscriptionsService.createCheckoutSession(priceId);
      const stripe = await stripePromise;
      const { sessionId } = res.data;
      stripe
        .redirectToCheckout({
          sessionId
        })
        .finally();
    }
  } catch (err) {
    console.error(err);
    dispatch(setIsShowGlobalLoader(false));
  }
};
