import React, { useState, useEffect } from 'react';
import { FacebookProvider, Login } from 'react-facebook';
import TagManager from 'react-gtm-module';

import { MixPanelSignUpEvents } from '../../../enums/mixpanel-events.enum';
import { MixPanelChoseSignUpMethodProperties } from '../../../enums/mixpanel-properties.enum';
import { SignupMethods } from '../../../enums/signup-methods.enum';
import { Mixpanel } from '../../../helpers/mixpanel.helper';
import Icon from '../Icon/Icon';
import styles from './FacebookLogin.module.scss';

const FacebookLogin = props => {
  const [error, setError] = useState(null);
  const [userToken, setUserToken] = useState(null);

  const handleResponse = async data => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v2/auth/facebook/callback`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        data: data,
        referral_code: props.referral_code
      }),
      withCredentials: true
    });
    try {
      const response = await res.json();
      if (response.status === 422) {
        props.setError('provider', {
          type: 'manual',
          message: 'You need to use a different login provider.'
        });
      }
      // We want to fire it only when user is creating an account
      if (props.isSignUp) {
        Mixpanel.track(MixPanelSignUpEvents.COMPLETED_SIGN_UP);

        TagManager.dataLayer({
          dataLayer: {
            event: 'createAccountFacebook',
            signupType: 'user'
          }
        });
      }
      props.callback();
    } catch (err) {
      console.error(err);
    }
  };

  const handleError = error => {
    setError({ error });
  };

  useEffect(() => {
    if (userToken) {
      props.callback();
    }
  }, [userToken]);

  const onButtonClick = () => {
    if (!props.buttonText) {
      Mixpanel.track(MixPanelSignUpEvents.CHOSE_SIGN_UP_METHOD, {
        [MixPanelChoseSignUpMethodProperties.METHOD]: SignupMethods.FACEBOOK
      });
    }
  };

  return (
    <div onClick={onButtonClick}>
      <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_ID}>
        <Login scope='email' onCompleted={handleResponse} onError={handleError}>
          {({ loading, handleClick, error, data }) => (
            <button className={styles.button} onClick={handleClick}>
              <div className={styles.buttonIcon}>
                <Icon name='facebook' />
              </div>
              {loading ? <span>Loading...</span> : props.buttonText ? props.buttonText : 'Sign Up with Facebook'}
            </button>
          )}
        </Login>
      </FacebookProvider>
    </div>
  );
};

export default FacebookLogin;
