import { createAsyncThunk } from '@reduxjs/toolkit';

import { parseThreadMessagesHelper } from '../../helpers/parse-thread-messages.helper';
import { GetThreadPayloadInterface, ThreadInterface } from '../../interfaces/messages.interface';
import MessagesService from '../../services/messages/messages.service';
import { SlicesNames } from '../slices/slices-names.enum';

export const getThread = createAsyncThunk(
  `${SlicesNames.MESSAGES_SLICE}/getThread`,
  async ({ threadId, page, size }: GetThreadPayloadInterface): Promise<ThreadInterface> => {
    try {
      const { thread } = (await MessagesService.getThreadRequest({ threadId, page, size })).data;
      return parseThreadMessagesHelper(thread);
    } catch (e) {
      throw new Error(e);
    }
  }
);
