import { ReferralDisplayStatus } from '../enums/referral-display-status.enum';
import { ReferralStatus } from '../enums/referral-status.enum';

export const referralStatusMapperHelper = (status: ReferralStatus): ReferralDisplayStatus => {
  switch (status) {
    case ReferralStatus.ACCEPTED_SIGNUP:
      return ReferralDisplayStatus.SIGN_UP;
    case ReferralStatus.ACCEPTED_TRIAL:
      return ReferralDisplayStatus.TRIALIST;
    case ReferralStatus.WAITING_FOR_APPROVAL:
      return ReferralDisplayStatus.AWAITING_VERIFICATION;
    case ReferralStatus.REWARD_DELIVERED:
      return ReferralDisplayStatus.COMPLETED;
    case ReferralStatus.REJECTED:
      return ReferralDisplayStatus.REJECTED;
    default:
      return ReferralDisplayStatus.AWAITING_VERIFICATION;
  }
};
