import moment from 'moment-timezone';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ModalInterface } from '../../../interfaces/modal.interface';
import UserService from '../../../services/user/user.service';
import { setIsShowGlobalLoader } from '../../../store/slices/global.slice';
import { setIsShowTimezoneModal } from '../../../store/slices/modals.slice';
import { selectUser, setUser } from '../../../store/slices/user.slice';
import Button from '../../Atoms/Button/Button';
import Icon from '../../Atoms/Icon/Icon';
import TimezoneSelect from '../../Atoms/TimezonePicker/v2/TimezoneSelect';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import styles from './TimezoneModal.module.scss';

const TimezoneModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);
  const guessedTimezone = moment.tz.guess();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      timezone: {
        value: guessedTimezone,
        label: guessedTimezone
      }
    }
  });

  const onSubmit = async data => {
    dispatch(setIsShowGlobalLoader(true));
    try {
      const info = {
        user: {
          timezone: data.timezone.value
        }
      };
      const res = await UserService.updateMe(info);
      await dispatch(setUser({ ...user, ...res.data.user }));
      dispatch(setIsShowTimezoneModal(false));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }
  };

  return (
    <ModalWrapper isOpen={isOpen} className={styles.modal} isPreventClosing>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <Icon name='bigGlobe' />
        <p className={styles.title}>Please select your timezone to continue:</p>
        <div className={styles.timezoneSelect}>
          <TimezoneSelect control={control} isNoLabel />
        </div>
        <Button type='submit' className={styles.button} value='Save changes' />
      </form>
    </ModalWrapper>
  );
};

export default TimezoneModal;
