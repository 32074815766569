import moment from 'moment-timezone';
import React, { ReactElement } from 'react';

import { MessageInterface } from '../../../../interfaces/messages.interface';
import Avatar from '../../../Atoms/Avatar/Avatar';
import styles from './SingleMessage.module.scss';

interface SingleMessageInterface extends MessageInterface {
  timezone: string;
}

const SingleMessage = ({ body, createdAt, user: { name, avatar }, timezone }: SingleMessageInterface): ReactElement => (
  <div className={styles.container}>
    <div className={styles.avatar}>
      <Avatar url={avatar} />
    </div>
    <div className={styles.body}>
      <div className={styles.header}>
        <span className={styles.name}>{name}</span>
        <span>{moment(createdAt).tz(timezone).format('DD/MM/YYYY - HH:mm')}</span>
      </div>
      <p className={styles.message}>{body}</p>
    </div>
  </div>
);

export default SingleMessage;
