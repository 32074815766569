export enum SlicesNames {
  GLOBAL_SLICE = 'GLOBAL_SLICE',
  USER_SLICE = 'USER_SLICE',
  TOAST_SLICE = 'TOAST_SLICE',
  MUSICIANS_SLICE = 'MUSICIANS_SLICE',
  UPLOADS_SLICE = 'UPLOADS_SLICE',
  MESSAGES_SLICE = 'MESSAGES_SLICE',
  MODALS_SLICE = 'MODALS_SLICE',
  SESSIONS_SLICE = 'SESSIONS_SLICE',
  REFERRAL_SLICE = 'REFERRAL_SLICE',
  SIDE_PANEL_SLICE = 'SIDE_PANEL_SLICE',
  LIMITATIONS_SLICE = 'LIMITATIONS_SLICE'
}
