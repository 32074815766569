import classnames from 'classnames';
import React, { CSSProperties, ReactElement } from 'react';

import Icon from '../Icon/Icon';
import styles from './Spinner.module.scss';

const Spinner = ({
  style,
  className,
  isBlue
}: {
  style?: CSSProperties;
  className?: string;
  isBlue?: boolean;
}): ReactElement => (
  <Icon
    className={classnames(styles.spinner, className)}
    name={isBlue ? 'spinner-blue' : 'spinner'}
    style={{ style }}
  />
);

export default Spinner;
