import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';

import styles from './Radio.module.scss';

interface RadioInterface {
  name: string;
  id: string;
  value: any;
  label: string;
  control: Control<any>;
  containerClassName?: string;
  labelClassName?: string;
  required?: boolean;
}

const Radio = ({
  name,
  id,
  value,
  label,
  containerClassName,
  labelClassName,
  control,
  required
}: RadioInterface): ReactElement => (
  <Controller
    control={control}
    name={name}
    rules={{
      required
    }}
    render={({ field: { onChange } }) => (
      <div key={id} className={classNames(styles.radio, containerClassName)}>
        <input type='radio' name={name} id={id} value={value} onChange={val => onChange(val)} />
        <label htmlFor={id}>
          <span className={labelClassName}>{label}</span>
        </label>
      </div>
    )}
  />
);

export default Radio;
