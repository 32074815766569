import React, { useEffect, useState } from 'react';
import avatar from '../../../../assets/images/avatar.png';

import styles from './Avatar.module.css';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUser } from '../../../store/slices/user.slice';

const Avatar = ({ url }) => {
  const user = useSelector(selectUser, shallowEqual);
  const [avatarUrl, setAvatarUrl] = useState(url ?? user.avatar ?? avatar);

  useEffect(() => {
    setAvatarUrl(url ?? user.avatar ?? avatar);
  }, [url, user.avatar]);

  return (
    <>
      <div className={styles.avatar}>
        <img className={styles.avatarImg} src={avatarUrl} />
      </div>
    </>
  );
};

export default Avatar;
