import React, { ReactElement } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectIsShowGlobalLoader } from '../../../store/slices/global.slice';
import Spinner from '../Spinner/Spinner';
import styles from './global-loader.module.scss';

const GlobalLoader = (): ReactElement => {
  const isShowGlobalLoader = useSelector(selectIsShowGlobalLoader, shallowEqual);

  return (
    isShowGlobalLoader && (
      <div className={styles.overlay}>
        <Spinner className={styles.spinner} isBlue />
      </div>
    )
  );
};

export default GlobalLoader;
