import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { ToastInterface } from '../interfaces/toast.interface';
import { hideToast, showToast } from '../store/slices/toast.slice';

interface UseToastInterface {
  sendToast: (params: ToastInterface, delay?: number) => void;
}

export const sendToastHelper = (dispatch: Dispatch) => (params: ToastInterface, delay = 4000): void => {
  dispatch(showToast(params));
  setTimeout(() => {
    dispatch(hideToast());
  }, delay);
};

export const useToast = (): UseToastInterface => {
  const dispatch = useDispatch();

  const sendToast = sendToastHelper(dispatch);

  return {
    sendToast
  };
};
