import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { HTMLProps, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSlider from 'react-slider';

import Icon from '../../../components/Atoms/Icon/Icon';
import { CohortExperimentPlans } from '../../../enums/cohort-experiment-plans.enum';
import { UserInterface } from '../../../interfaces/user.interface';
import { selectSelectedPlan, setPlansByPeriod, setSelectedPlan } from '../../../store/slices/global.slice';
import { selectUser } from '../../../store/slices/user.slice';
import Tooltip from '../tooltip/tooltip';
import { monthlyLabel, yearlyLabel } from './pricing-slider.const';
import { PricingBulletInterface, PricingPlanInterface, PricingSliderInterface } from './pricing-slider.interface';
import styles from './pricing-slider.module.scss';

enum PricingPlansPeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

const RenderBullet = ({ id, bullet, tooltipTitle, tooltipDescription }: PricingBulletInterface): ReactElement => {
  const triggerElement = (): ReactElement => (
    <div key={id} className={styles.bullet}>
      <span>{bullet}</span>
      <div className={styles.icon}>
        <Icon name={'info'} />
      </div>
    </div>
  );

  return tooltipTitle && tooltipDescription ? (
    <Tooltip
      contentStyle={{ maxWidth: '300px', minWidth: '100px' }}
      offsetX={30}
      position={['right center']}
      triggerElement={triggerElement()}
      content={
        <div className={styles.tooltipContent}>
          <h3>{tooltipTitle}</h3>
          <p>{tooltipDescription}</p>
        </div>
      }
    />
  ) : (
    triggerElement()
  );
};

export const getPlanIdxWithCohort = (
  plans: PricingPlanInterface[],
  user: UserInterface,
  isAnnualPlansDefault?: boolean
): number => {
  if (isAnnualPlansDefault) {
    return 0;
  }
  const planForCohort = plans.find(singlePlan => CohortExperimentPlans[singlePlan.title] === user.cohort);
  const idx = plans.indexOf(planForCohort);
  return idx !== -1 ? idx : 0;
};

const PricingSlider = ({ plans }: PricingSliderInterface): ReactElement => {
  const user = useSelector(selectUser);
  const { annualPlansDefault } = useFlags();
  const [selectedTab, setSelectedTab] = useState(
    annualPlansDefault ? PricingPlansPeriod.YEARLY : PricingPlansPeriod.MONTHLY
  );
  const dispatch = useDispatch();

  const plansByPeriod = useMemo(
    (): PricingPlanInterface[] =>
      plans.filter(plan => (selectedTab === PricingPlansPeriod.YEARLY ? plan.isAnnual : !plan.isAnnual)),
    [selectedTab, plans]
  );

  useEffect((): void => {
    dispatch(setPlansByPeriod(plansByPeriod));
  }, [plansByPeriod, dispatch]);

  const [planIdx, setPlanIdx] = useState(getPlanIdxWithCohort(plansByPeriod, user, annualPlansDefault));
  const selectedPlan = useSelector(selectSelectedPlan);

  useEffect((): void => {
    dispatch(setSelectedPlan(plansByPeriod[planIdx]));
  }, [selectedTab, plansByPeriod, planIdx, dispatch]);

  const minSliderRange = 0;
  const maxSliderRange = plansByPeriod.length - 1;

  const setTab = (tab: PricingPlansPeriod): (() => void) => (): void => setSelectedTab(tab);

  const onPlanChange = useCallback(
    (idx: number): void => {
      setPlanIdx(idx);
      dispatch(setSelectedPlan(plansByPeriod[idx]));
    },
    [plansByPeriod, dispatch]
  );

  useEffect((): void => {
    onPlanChange(getPlanIdxWithCohort(plansByPeriod, user, annualPlansDefault));
  }, [selectedTab, annualPlansDefault, plansByPeriod, user, onPlanChange]);

  return (
    <div className={styles.container}>
      <div className={styles.pricingBox}>
        {!annualPlansDefault && (
          <div className={styles.tabs}>
            {Object.values(PricingPlansPeriod).map(plan => (
              <div
                key={plan}
                onClick={setTab(plan)}
                className={classnames(styles.tab, selectedTab === plan && styles.tabActive)}
              >
                <span>{plan}</span>
              </div>
            ))}
          </div>
        )}
        {selectedPlan?.discountText && (
          <div className={styles.discountContainer}>
            <Icon name={'discount'} />
            <span>{selectedPlan?.discountText}</span>
          </div>
        )}
        <div className={styles.priceContainer}>
          <div className={styles.priceValueContainer}>
            <span className={styles.dollar}>$</span>
            <span className={styles.price}>{selectedPlan?.price}</span>
            {selectedPlan?.old_price && (
              <div className={styles.oldPriceContainer}>
                <span className={styles.dollar}>$</span>
                <span className={styles.oldPrice}>{selectedPlan?.old_price}</span>
              </div>
            )}
          </div>
          <span className={styles.planLabel}>
            {selectedTab === PricingPlansPeriod.MONTHLY ? monthlyLabel : yearlyLabel}
          </span>
        </div>
        <div className={styles.sliderContainer}>
          <ReactSlider
            value={planIdx}
            onChange={onPlanChange}
            className={styles.slider}
            marks
            markClassName={styles.mark}
            min={minSliderRange}
            max={maxSliderRange}
            thumbClassName={styles.thumb}
            trackClassName={styles.track}
            renderThumb={(props: HTMLProps<HTMLDivElement>) => (
              <div {...props}>
                <div className={styles.triangleLeft} />
                <div className={styles.triangleRight} />
              </div>
            )}
          />
        </div>
        <div className={styles.bullets}>
          {selectedPlan?.pricingBullet.map(bullet => (
            <RenderBullet key={bullet.id} {...bullet} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingSlider;
