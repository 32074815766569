import classNames from 'classnames';
import React, { ReactElement, useEffect } from 'react';
import { Control, UseFormGetValues, UseFormWatch } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { shallowEqual, useSelector } from 'react-redux';

import { selectCurrentlyBookingArtistConfig } from '../../../../../store/slices/musicians.slice';
import RadioWide from '../../../../Atoms/RadioWide/RadioWide';
import styles from '../../BookSessionForm.module.scss';

interface ChooseAServiceInterface {
  control: Control<any>;
  getValues: UseFormGetValues<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  setSelection: any;
}

const ChooseAService = ({ control, getValues, watch, setValue, setSelection }: ChooseAServiceInterface): ReactElement => {
  const {
    services: { label, servicesList }
  } = useSelector(selectCurrentlyBookingArtistConfig, shallowEqual);

  const watcher = watch('service');

  useEffect((): void => {
    setValue('extra', watcher);
    const { value: serviceValue } = servicesList ? servicesList?.filter(x => x.value === watcher)[0] : [];
    setSelection(serviceValue);
  }, [watcher, setValue]);

  return (
    <>
      <label className={classNames(styles.label, styles.mbMin)}>{label}</label>
      <div>
        {servicesList.map(
          ({
            id,
            label: serviceLabel,
            value,
            short_description: description,
            isShortDescription,
            isInfoButton,
            info_button_body
          }) => (
            <div key={id}>
              <RadioWide
                defaultChecked={getValues().service === value}
                name='service'
                control={control}
                id={id}
                value={value}
                label={serviceLabel}
                description={isShortDescription && description ? description : null}
                infoContent={isInfoButton && info_button_body ? info_button_body : null}
              />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default ChooseAService;
