import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { global } from './slices/global.slice';
import { messages } from './slices/messages.slice';
import { modals } from './slices/modals.slice';
import { musicians } from './slices/musicians.slice';
import { referral } from './slices/referral.slice';
import { sessions } from './slices/sessions.slice';
import { toast } from './slices/toast.slice';
import { uploads } from './slices/uploads.slice';
import { user } from './slices/user.slice';
import { sidePanel } from './slices/sidePanel.slice';
import { limitations } from './slices/limitations.slice';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
});

const store = configureStore({
  middleware: customizedMiddleware,
  reducer: {
    globalSlice: global.reducer,
    userSlice: user.reducer,
    toastSlice: toast.reducer,
    musiciansSlice: musicians.reducer,
    uploadsSlice: uploads.reducer,
    messagesSlice: messages.reducer,
    modalsSlice: modals.reducer,
    sessionsSlice: sessions.reducer,
    referralSlice: referral.reducer,
    sidePanelSlice: sidePanel.reducer,
    limitationsSlice: limitations.reducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export default store;
