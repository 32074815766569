export const cancelledAccountContent = `
  <h1>Your membership is no longer active. When you rejoin, you'll be able to:</h1>
  <ul>
    <li>Start booking and attending sessions again</li>
    <li>Continue making your best music</li>
  </ul>
  <p>If you have any questions about starting a new membership, we'd be happy to help. <a target='_blank' rel='noreferrer' href='https://share.hsforms.com/18FoFHA41TZ-c-Dku-Wpcpg4zn1a'>Contact our team here</a>.</p>
`;

export const pendingCancellationAccountContent = `
  <h1>What happens when your membership gets canceled:</h1>
  <ul>
    <li>Any booked sessions will get canceled automatically</li>
    <li>You won't be able to book any more sessions</li>
    <li>You'll lose all credits that are stored in your Credits Bank</li>
  </ul>
  <p>And we'll be very sad... :(. If you change your mind, simply click "Resume Membership" before your cancelation date.</p>
`;
