import moment from 'moment-timezone';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Icon from '../../Atoms/Icon/Icon';
import styles from '../AvailabilityCalendar/AvailabilityCalendar.module.scss';
import classNames from 'classnames';
import { setIsShowGlobalLoader } from '../../../store/slices/global.slice';
import MusiciansService from '../../../services/musicians/musicians.service';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../store/slices/user.slice';
import { uniq } from 'lodash';
import { useIsMobileResolution } from '../../../hooks/useIsMobileResolution';

const PAGE_SIZE = 7 * 4; // 4 weeks

export default function MusicianCalendar(props) {
  const { isFullWidth } = props;
  const isMobileResolution = useIsMobileResolution();

  const WEEKSIZE = isMobileResolution ? 1 : 7;

  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);
  const timezone = user?.timezone || moment.tz.guess();

  const [currentWeek, setCurrentWeek] = useState(0);
  const [initialDate, setInitialDate] = useState(new Date());
  const [timetable, setTimetable] = useState({});
  const [weekSize, setWeekSize] = useState(WEEKSIZE);
  const slotsCollection = useRef([]);
  const [slots, setSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getSlots = useCallback(async ({ from_date, to_date }) => {
    dispatch(setIsShowGlobalLoader(true));
    setIsLoading(true);
    try {
      const params = {
        timezone,
        from_date,
        to_date
      };
      const { data } = await MusiciansService.getMusicianCalendar(params);
      slotsCollection.current = uniq([...slotsCollection.current, ...data.slots])
        .sort((a, b) => a.start_date < b.start_date ? -1 : 1);
      setSlots(slotsCollection.current);
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
      setIsLoading(false);
    }
  }, [timezone, dispatch]);

  useEffect(() => {
    const now = moment();
    const from_date = now.clone().subtract(PAGE_SIZE, 'days').format('YYYY-MM-DD');
    const to_date = now.clone().add(PAGE_SIZE, 'days').format('YYYY-MM-DD');
    getSlots({ from_date, to_date }).finally();
  }, [getSlots]);

  const [nextButtonCounter, setNextButtonCounter] = useState(0);

  const changeWeek = into => {
    if (isLoading) {
      return;
    }
    if (into === 'next') {
      setCurrentWeek(currentWeek + 1);
      setNextButtonCounter(nextButtonCounter + 1);

      const lastSlotMoment = moment(slotsCollection.current[slotsCollection.current.length - 1]?.start_date);
      const newInitialDate = moment(initialDate).add(WEEKSIZE, 'days');

      if (newInitialDate.valueOf() < lastSlotMoment.valueOf()) {
        return;
      }

      const from_date = lastSlotMoment.clone().add(1, 'days').format('YYYY-MM-DD');
      const to_date = lastSlotMoment.clone().add(PAGE_SIZE, 'days').format('YYYY-MM-DD');

      getSlots({ from_date, to_date }).finally();
    }
    if (into === 'previous') {
      setCurrentWeek(currentWeek - 1);
      setNextButtonCounter(nextButtonCounter - 1);

      const firstSlotMoment = moment(slotsCollection.current[0]?.start_date);
      const newInitialDate = moment(initialDate).subtract(WEEKSIZE, 'days');

      if (newInitialDate.valueOf() > firstSlotMoment.valueOf()) {
        return;
      }

      const from_date = firstSlotMoment.clone().subtract(PAGE_SIZE, 'days').format('YYYY-MM-DD');
      const to_date = firstSlotMoment.clone().subtract(1, 'days').format('YYYY-MM-DD');
      getSlots({ from_date, to_date }).finally();
    }
  };

  const arrangeSlotsPerDay = useCallback((s, startDay) => {
    const tempTimetable = {};
    for (let i = 0; i < weekSize; i++) {
      const day = moment(startDay).tz(timezone).add(i, 'days');

      const beginningOfDay = day.startOf('day').valueOf();
      const endOfDay = day.endOf('day').valueOf();

      tempTimetable[day] = s.filter(x =>
        moment(x.start_date).tz(timezone).valueOf() >= beginningOfDay &&
        moment(x.start_date).tz(timezone).valueOf() <= endOfDay
      );
    }
    return tempTimetable;
  }, [timezone, weekSize]);

  useEffect(() => {
    let startDay = moment();
    if (isMobileResolution) {
      setWeekSize(1);
      startDay.toDate();
    } else {
      startDay = moment()
        .tz(timezone)
        .startOf('isoWeek')
        .subtract(1, 'days')
        .add(weekSize * currentWeek, 'days')
        .toDate();
    }
    setInitialDate(startDay);
    setTimetable(arrangeSlotsPerDay(slots, startDay));
  }, [currentWeek, timezone, weekSize, arrangeSlotsPerDay, isMobileResolution, slots]);

  return (
    <>
      <div className={classNames(styles.availabilityCalendar, isFullWidth && styles.isFullWidth)}>
        <div>
          <div className={styles.availabilityCalendarPagination}>
            <a className={styles.availabilityCalendarPagination__button} onClick={() => changeWeek('previous')}>
              <Icon name='arrow-left' width={10} height={18} viewBox='0 0 10 18' />
            </a>

            {isMobileResolution ? (
              <div className={styles.availabilityCalendarPagination__title}>
                {moment(initialDate).tz(timezone).format('MMMM DD')}
              </div>
            ) : (
              <div className={styles.availabilityCalendarPagination__title}>
                {moment(initialDate).tz(timezone).format('MMMM DD')} -{' '}
                {moment(initialDate).tz(timezone).add(WEEKSIZE - 1, 'days').format('DD')},{' '}
                {moment(initialDate).tz(timezone).format('YYYY')}
              </div>
            )}

            <a
              className={styles.availabilityCalendarPagination__button}
              onClick={() => changeWeek('next')}
            >
              <Icon name='arrow-right' width={10} height={18} viewBox='0 0 10 18' />
            </a>
          </div>

          <div className={styles.slotsTable}>
            {Object.keys(timetable).map((dow, index) => (
              <div key={index} className={styles.slotsTable__column}>
                {isMobileResolution ? (
                  <div className={styles.slotsTable__columnHead}>
                    <div className={styles.slotsTable__dayNumber}>
                      {/* REMV{moment(dow).tz(timezone).format('dddd')} {moment(dow).tz(timezone).format('DD')} */}
                    </div>
                  </div>
                ) : (
                  <div className={styles.slotsTable__columnHead}>
                    <div className={styles.slotsTable__dayName}>{moment(dow).tz(timezone).format('dddd')}</div>
                    <div className={styles.slotsTable__dayNumber}>{moment(dow).tz(timezone).format('DD')}</div>
                  </div>
                )}

                {timetable[dow]
                  .sort((a, b) => moment(a.start_date).tz(timezone).diff(moment(b.start_date).tz(timezone)))
                  .map(slot => (
                    <div
                      key={slot.id}
                      className={classNames(
                        styles.slotsTable__slot,
                        styles[slot.status],
                        slot.status === 'prebooked' && styles.prebookedAsBooked,
                        styles.slotNotSelectable
                      )}
                    >
                      <div className={styles.slotsTable__slotTime}>
                        {moment(slot.start_date).tz(timezone).format('hh:mm')}
                      </div>
                      <div className={styles.slotsTable__slotPeriod}>
                        {moment(slot.start_date).tz(timezone).format('a')}
                      </div>
                      <span className={classNames(styles.slotType, slot.slot_type === 'fixed' && styles.fixedSlot)}>
                        {slot.slot_type}
                      </span>
                    </div>
                  ))}
              </div>
            ))}
          </div>

          <div className={styles.availabilityCalendar__timezone}>
            <div className={styles.availabilityCalendar__timezoneIcon}>
              <Icon name='globe' />
            </div>
            <p>{timezone}</p>
          </div>
        </div>
      </div>
    </>
  );
}
