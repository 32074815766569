import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { HTMLProps, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactSlider from 'react-slider';
import { useIsMobileResolution } from '../../../hooks/useIsMobileResolution';

import Icon from '../../../components/Atoms/Icon/Icon';
import { UserInterface } from '../../../interfaces/user.interface';
import { selectSelectedPlan,
  selectSelectedProduct,
  selectSelectedProductType,
  setPlansByPeriod,
  setSelectedPlan,
  setSelectedProduct,
  setSelectedProductType
} from '../../../store/slices/global.slice';
import { selectUser } from '../../../store/slices/user.slice';
// import Tooltip from '../tooltip/tooltip';
import Button_v2 from '../../../components/Atoms/Button_v2/button';
import { ButtonSizes } from '../../../enums/button-sizes.enum';
import { ButtonTypes } from '../../../enums/button-types.enum';
import { StrapiIcons } from '../../../enums/strapi-icons.enum';
import { ProductTypes } from '../../../enums/product-types.enum';
import { strapiIconsMap } from '../../../utils/strapiIcons';
import AuthService from '../../../services/auth/auth.service';
import { getMe } from '../../../store/actions/user.actions';
import { sectionTitles, KEYS_TO_SHOW } from './product-options.const';
import styles from './product-options.module.scss';

interface ProductInterface {
  title: string;
  bullets: any[];
  product: any;
  productType: ProductTypes;
  onProductClick: any;
}

interface ProductSectionInterface {
  title: string;
  products: any[];
  productType: ProductTypes;
  onProductClick: any;
  // plan: PricingPlanInterface;
  // setIsPlanSelection: Dispatch<boolean>;
}

interface ProductOptionsInterface {
  products: any[];
  setIsPlanSelection: any;
}

const Product = ({ title, bullets, productType, product, onProductClick }: ProductInterface): ReactElement => {
  const dispatch = useDispatch();
  const selectedPlan = useSelector(selectSelectedPlan);
  const selectedProduct = useSelector(selectSelectedProduct);
  const selectedProductType = useSelector(selectSelectedProductType);

  const selected = useMemo(() => selectedProductType == productType && selectedProduct?.id && product?.id === selectedProduct?.id, [selectedProduct]);

  const onProductSelect = (): void => {
    onProductClick(productType, product);
    // Mixpanel.track(MixPanelSignUpEvents.CHANGED_PLAN, {
    //   [MixPanelChangedPlanProperties.PLAN]: plan.title
    // });
    dispatch(setSelectedProductType(productType));
    dispatch(setSelectedProduct(product));
    if (productType == ProductTypes.MEMBERSHIPS) {
      dispatch(setSelectedPlan(product));
    }
    console.log('clicked', title, productType);
  };

  return (
    <div onClick={onProductSelect} className={classnames(styles.productContainer, selected ? styles.selectedPlan : null)}>
      <Icon name={selected ? 'radio-selected-gold' : 'radio-unselected'}
            className={selected ? styles.selectedIcon : styles.unselectedIcon}
      />
      <div className={styles.productInfo}>
        <div className={classnames(styles.productTitle, selected ? styles.selectedPlan : null)}>
          {title}
        </div>
        <div className={styles.productBullets}>
          {bullets?.map((bullet, index) =>
            <div key={index} className={styles.productBullet}>
              {/* {bullet?.icon && strapiIconsMap[bullet?.icon] ?
                <Icon key={index} name={strapiIconsMap[bullet.icon] ?? StrapiIcons.PACKAGE} className={styles.bulletIcon} /> :
                <Icon key={index} name={StrapiIcons.PACKAGE} className={styles.bulletIcon} />
              } */}
              <Icon key={index} name={bullet.icon ?? StrapiIcons.CHECKMARK} className={styles.bulletIcon} />
              {/* <Icon name={plan.id === selectedPlan?.id ? 'radio-selected' : 'radio-unselected'} className={styles.bulletIcon} />
                <span className={classnames(styles.bigText, plan.id === selectedPlan?.id && styles.highlight)}>
                  {plan.title}
                </span> */}
              <div className={styles.bulletBody}>{bullet.bullet}</div>
            </div>
            //   <Icon name={bullet.icon} className={styles.bulletIcon> />
            //   <div className={styles.bulletBody}>{bullet.body}</div>
            // </div>
          )}
        </div>
      </div>
    </div>
  );
};
const ProductSection = ({ title, products, productType, onProductClick }: ProductSectionInterface): ReactElement => {
  const isMobileResolution = useIsMobileResolution();
  const dispatch = useDispatch();

  // const onPlanSelect = (): void => {
  //   Mixpanel.track(MixPanelSignUpEvents.CHANGED_PLAN, {
  //     [MixPanelChangedPlanProperties.PLAN]: plan.title
  //   });
  //   setIsPlanSelection(false);
  //   dispatch(setSelectedPlan(plan));
  // };

  return products?.length > 0 && (
    <div className={styles.productSectionContainer}>
      { title != '' ? (
        <div className={styles.productSectionTitle}>{title}</div>
      ) : (
        <div className={styles.lineDivider} />
      )}
      { products?.map((op, index) =>
        <Product
          key={index}
          title={op.title}
          bullets={op.bullets}
          product={op}
          productType={productType}
          onProductClick={onProductClick}
        />
      )}
    </div>
  );
};

const ProductOptions = ({ products, setIsPlanSelection }: ProductOptionsInterface): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);
  const selectedPlan = useSelector(selectSelectedPlan);
  const selectedProductType = useSelector(selectSelectedProductType);
  const selectedProduct = useSelector(selectSelectedProduct);

  const onOndemandSignup = async (): void => {
    await AuthService.ondemandSignup();
    const redirectRoute = '/thank-you';
    history.push(redirectRoute);
    // Mixpanel.track(MixPanelSignUpEvents.CHANGED_PLAN, {
    //   [MixPanelChangedPlanProperties.PLAN]: plan.title
    // });
  };

  // const { title, discounted, discountText, price, credits, bullets, button_cta } = plan;

  const onProductClick = (productType, product): void => {
    // Mixpanel.track(MixPanelSignUpEvents.CHANGED_PLAN, {
    //   [MixPanelChangedPlanProperties.PLAN]: plan.title
    // });
    if (productType == ProductTypes.MEMBERSHIPS) {
      dispatch(setSelectedPlan(product));
    }
  };

  const onPlanMembershipSelect = (): void => {
    // Mixpanel.track(MixPanelSignUpEvents.CHANGED_PLAN, {
    //   [MixPanelChangedPlanProperties.PLAN]: plan.title
    // });
    setIsPlanSelection(false);
    // dispatch(setSelectedPlan(plan));
  };

  const onBundleSelect = (): void => {
    // Mixpanel.track(MixPanelSignUpEvents.CHANGED_PLAN, {
    //   [MixPanelChangedPlanProperties.PLAN]: plan.title
    // });
    setIsPlanSelection(false);
    // dispatch(setSelectedPlan(plan));
  };

  const onOrchestraSelect = (): void => {
    // Mixpanel.track(MixPanelSignUpEvents.CHANGED_PLAN, {
    //   [MixPanelChangedPlanProperties.PLAN]: plan.title
    // });
    // setIsPlanSelection(false);
    location.href = selectedProduct.link;
    // history.push(selectedProduct.link);
    // dispatch(setSelectedPlan(plan));
  };

  return (
    <div className={styles.productOptionsContainer}>
      <div className={styles.container}>
        { KEYS_TO_SHOW.map((k, index) =>
          <ProductSection
            key={index}
            products={products[k]}
            title={sectionTitles[k]}
            productType={k}
            onProductClick={onProductClick}
          />)
        }

      </div>
        <div className={styles.footer}>
        { selectedProductType === ProductTypes.BUNDLES && (
          <Button_v2
            className={styles.submitButton}
            isSubmit
            width={'100%'}
            height={'60px'}
            onClick={onPlanMembershipSelect}
            buttonSize={ButtonSizes.MEDIUM}
            buttonText={
              <>
                ${selectedProduct?.price}
              </>
            }
          />
        )}
        { selectedProductType === ProductTypes.MEMBERSHIPS && (
          <Button_v2
            className={styles.submitButton}
            isSubmit
            width={'100%'}
            height={'60px'}
            onClick={onPlanMembershipSelect}
            buttonSize={ButtonSizes.MEDIUM}
            buttonText={
              <>
              {selectedPlan?.old_price ? (
                <>
                  <span className={styles.oldPrice}>${selectedPlan?.old_price}</span>${selectedPlan?.price}/Year
                </>
              ) : (
                <>
                  ${selectedPlan?.price}/Year
                </>
              )}
              </>
            }
          />
        )}
        { selectedProductType === ProductTypes.ORCHESTRAS && (
          <Button_v2
            className={styles.submitButton}
            isSubmit
            width={'100%'}
            height={'60px'}
            onClick={onOrchestraSelect}
            buttonSize={ButtonSizes.MEDIUM}
            buttonText={
              <>
                ${selectedProduct?.price}
              </>
            }
          />
        )}
        <div className={styles.VATWarning}>
          VAT Charges may apply
        </div>
        {/* <div className={styles.ondemandParagraph}>
          <div className={styles.row}>
            <span className={styles.highlighted}>Not ready to commit?</span><a className={styles.freeplanLink} onClick={onOndemandSignup}> Try our free plan</a>
          </div>
          {/* <span>and buy sessions on demand.</span>
        </div> */}
      </div>
    </div>
  );
};

export default ProductOptions;
