import { SessionStatus } from '../../../enums/session-status.enum';

export const attendanceLivestream = {
  true: 'Yes (recommended)',
  false: 'No'
};

export const attendanceLivestreamPartners = {
  true: 'Yes',
  false: 'No'
};

export const articleLink = 'https://help.musiversal.com/preparing-your-materials-ahead-of-a-session';

export const editAllowedStatuses = [
  SessionStatus.CLIENT_FILES_UPLOAD_REQUIRED,
  SessionStatus.CLIENT_FILES_PENDING_REVIEW,
  SessionStatus.CLIENT_FILES_UPDATE_REQUIRED,
  SessionStatus.PARTNER_READY_TO_GO
];

export const cancelAllowedStatuses = [
  SessionStatus.CLIENT_FILES_UPLOAD_REQUIRED,
  SessionStatus.CLIENT_FILES_PENDING_REVIEW,
  SessionStatus.CLIENT_FILES_UPDATE_REQUIRED,
  SessionStatus.PARTNER_READY_TO_GO
];

export const feedback = {
  'more-than-1-session': 'Approved, but more than 1 session will be required.',
  'missing-materials': 'Action required: add missing files',
  'poor-quality-materials': 'Action required: improve and update the materials provided.',
  'preproduction-required': 'Materials are unworkable: Pre-production service required.',
  'outside-scope': 'Materials are beyond scope of the Musiversal Service',
  'materials-deadline-not-achieved': 'Files were not submitted on time'
};

export const firstSessionMessage = 'This is the client’s first session. Make sure to blow them away!';
