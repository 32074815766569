export const editedCountryNames = [
  {
    code: 'US',
    name: 'United States'
  },
  {
    code: 'GB',
    name: 'United Kingdom'
  },
  {
    code: 'BO',
    name: 'Bolivia'
  },
  {
    code: 'BQ',
    name: 'Bonaire'
  },
  {
    code: 'IO',
    name: 'BIOT'
  },
  {
    code: 'BN',
    name: 'Brunei'
  },
  {
    code: 'CC',
    name: 'Cocos Islands'
  },
  {
    code: 'CD',
    name: 'DR Congo'
  },
  {
    code: 'FK',
    name: 'Falkland Islands'
  },
  {
    code: 'TF',
    name: 'French Southern Lands'
  },
  {
    code: 'HM',
    name: 'HIMI'
  },
  {
    code: 'IR',
    name: 'Iran'
  },
  {
    code: 'VE',
    name: 'Venezuela'
  },
  {
    code: 'UM',
    name: 'USMOI'
  },
  {
    code: 'KP',
    name: 'North Korea'
  },
  {
    code: 'LA',
    name: 'Laos'
  },
  {
    code: 'FM',
    name: 'Micronesia'
  },
  {
    code: 'MD',
    name: 'Moldova'
  },
  {
    code: 'TZ',
    name: 'Tanzania'
  },
  {
    code: 'KR',
    name: 'South Korea'
  },
  {
    code: 'PS',
    name: 'Palestine State'
  },
  {
    code: 'SH',
    name: 'Saint Helena'
  },
  {
    code: 'VC',
    name: 'Saint Vincent'
  },
  {
    code: 'GS',
    name: 'SGSSI'
  }
];
