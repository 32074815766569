import React, { ReactElement, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useHistory, useParams } from 'react-router-dom';

import { ModalTypes } from '../../../enums/modal-types.enum';
import { ButtonTypes } from '../../../enums/button-types.enum';
import { ModalInterface } from '../../../interfaces/modal.interface';
import SessionsService from '../../../services/sessions/sessions.service';
import { getMe } from '../../../store/actions/user.actions';
import { setIsShowGlobalLoader } from '../../../store/slices/global.slice';
import { setIsShowCancelSessionModal, selectCancellationCreditsCancellationModal } from '../../../store/slices/modals.slice';
import { selectSession, setSession } from '../../../store/slices/sessions.slice';
import { setIsShowSidePanel, setIsFullSessionLoaded } from '../../../store/slices/sidePanel.slice';
import { getSingleSession } from '../../../store/actions/sessions.actions';
import Button_v2 from '../../Atoms/Button_v2/button';
import Button from '../../Atoms/Button/Button';
import Icon from '../../Atoms/Icon/Icon';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import { showPaymentMethodModalStatuses } from '../PlansModal/PlansModal';
import styles from './CancelSessionModal.module.scss';

const CancelSessionModal = ({ isOpen }: ModalInterface): ReactElement => {
  const session = useSelector(selectSession, shallowEqual);
  const history = useHistory();
  const { cancellationRecreditLimit } = useSelector(selectCancellationCreditsCancellationModal, shallowEqual) || {};
  const dispatch = useDispatch();

  const onRequestClose = (): void => {
    dispatch(setIsShowCancelSessionModal(false));
  };

  const onConfirmation = async (): Promise<void> => {
    dispatch(setIsShowGlobalLoader(true));
    try {
      const data = {
        session_id: session?.id,
        feedback_type: 'cancel-by-user'
      };
      try {
        dispatch(setIsShowGlobalLoader(true));
        await SessionsService.cancelSession(data);
        await dispatch(getMe());
        await dispatch(getSingleSession(session.id));

        dispatch(setIsShowGlobalLoader(false));
        dispatch(setIsShowCancelSessionModal(false));
        dispatch(setIsShowSidePanel(false));
        dispatch(setIsFullSessionLoaded(false));
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(setIsShowGlobalLoader(false));
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }
  };

  const wasBooked1HourOrLess = () => {
    const now = moment();
    const duration = moment.duration(now.diff(session?.booked_at));
    const minutes = duration.asMinutes();
    // If you booked 10 min ago
    if (minutes <= 60) {
      return false;
    }
    // If the session starts in more than 48-ish hours from now
    if (moment().add(parseInt(cancellationRecreditLimit), 'hours') <= moment(session.start_date)) {
      return false;
    }
    return true;
  };

  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onRequestClose} className={styles.modal} modalType={ModalTypes.DIALOG} >
      { wasBooked1HourOrLess() ? (
        <>
          <div className={styles.container}>
            <div className={styles.icon}>
              <Icon name='offboardingWarning' />
            </div>
            <p className={styles.dialogTitle}>Are you sure you want to cancel this session?</p>
            <p className={styles.dialogContent}>
              Your session is happening in less than {cancellationRecreditLimit} hours. <b>Once cancelled your {session?.credits} {session?.credits > 1 ? 'credits' : 'credit'} will not be returned to your account.</b>
            </p>
            <p className={styles.dialogContent}>
              Do you wish to continue?
            </p>
          </div>
          <div className={styles.containerButtons}>
            <Button className={styles.closeButton} onClick={onRequestClose} buttonType={ButtonTypes.SECONDARY}>
              Back
            </Button>
            <Button className={styles.confirmButton} onClick={onConfirmation}>
              Cancel Session
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={styles.container}>
            <p className={styles.dialogTitle}>Are you sure you want to cancel this session?</p>
            <p className={styles.dialogContent}>
              Once canceled, {session?.credits} {session?.credits > 1 ? 'credits' : 'credit'} will be returned to your account. <b>This operation can not be undone.</b>
            </p>
          </div>
          <div className={styles.containerButtons}>
            <Button className={styles.closeButton} onClick={onRequestClose} buttonType={ButtonTypes.SECONDARY}>
              Back
            </Button>
            <Button className={styles.confirmButton} onClick={onConfirmation}>
              Cancel Session
            </Button>
          </div>
        </>
      )}
    </ModalWrapper>
  );
};

export default CancelSessionModal;
