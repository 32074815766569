import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Button_v2 from '../../components/Atoms/Button_v2/button';
import StyledInput from '../../components/Atoms/StyledInput/StyledInput';
import SignUpFlowNavbar from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar';
import { ButtonSizes } from '../../enums/button-sizes.enum';
import { StyledInputTypes } from '../../enums/styled-input-types.enum';
import AuthService from '../../services/auth/auth.service';
import styles from './ResetPassword.module.scss';
import {
  backToLoginText,
  buttonSubmittingText,
  buttonText,
  header,
  infoText,
  instructionsSentText
} from './reset-password.const';

const ResetPassword = (): ReactElement => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors
  } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [resetInstructionsSent, setResetInstructionsSent] = useState(false);

  const onSubmit = async data => {
    setSubmitting(true);

    const email = data.email?.toLowerCase();

    try {
      const res = await AuthService.resetPasswordInstructions(email);
      if (res.status === 200) {
        setSubmitting(false);
        setResetInstructionsSent(true);
        return;
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setSubmitting(false);
        setError('email', {
          type: 'manual',
          message: 'Invalid email or wrong password'
        });
      } else {
        setError('server', {
          type: 'manual',
          message: 'We have some issues connecting to our servers. Please try again later.'
        });
      }
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <SignUpFlowNavbar />
      <div className={styles.leftImage}>
        <div className={styles.bg} />
      </div>
      <div className={styles.rightImage}>
        <div className={styles.bg} />
      </div>
      <h1>{header}</h1>
      <p className={styles.infoText}>{infoText}</p>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <StyledInput
          width={'100%'}
          height={'51px'}
          label={'Email Address'}
          type={StyledInputTypes.EMAIL}
          name={'email'}
          id={'email'}
          registerRef={register('email', {
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'invalid email address'
            }
          })}
          errors={[errors.email?.message, errors.server?.message]}
          clearErrors={() => clearErrors()}
        />
        <div className={styles.button}>
          <Button_v2
            disabled={submitting}
            width={'100%'}
            height={'60px'}
            buttonSize={ButtonSizes.MEDIUM}
            buttonText={submitting ? buttonSubmittingText : buttonText}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
        {resetInstructionsSent && <p className={styles.instructionsSent}>{instructionsSentText}</p>}
        <Link to='/login' className={styles.backToLogin}>
          {backToLoginText}
        </Link>
      </form>
    </div>
  );
};

export default ResetPassword;
