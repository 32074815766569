import { ProductTypes } from '../../enums/product-types.enum';
export const planSelectionHeader = 'Pick your offer';
export const planSelectionHeaderSales = 'Cyber Monday deals';
export const paymentDetailsHeader = 'Enter your\n payment details';
export const selectedPlanText = 'SELECTED PLAN';
export const selectThisPlanText = 'SELECT THIS PLAN';
export const changePlanText = 'CHANGE PLAN';
export const freeTrialText = 'Free trial (14 days)';
export const sessionsPerMonth = 'credits per month';
export const sessionsPerYear = 'credits per year';
export const submitButtonText = (isTrialOn?: boolean, productType?: string): string => !isTrialOn ? (productType === ProductTypes.BUNDLES ? 'GET YOUR BUNDLE' : 'START YOUR MEMBERSHIP') : 'START YOUR FREE TRIAL';
export const submittingButtonText = 'GETTING STARTED...';
export const bullet0 = 'VAT charges apply (to EU members)';
export const bullet1 = 'Your card won’t be charged today';
export const bullet2 = "We'll notify you before your trial ends";
export const bullet4 = 'No surprise charges, cancel at any time';
export const bullet5 = 'You can cancel your membership at any time';
export const selectPlanBullets = [
  '14 day free trial',
  'No upfront payment required',
  'No surprise charges, cancel any time'
];
export const haveACouponText = 'Have a coupon?';
export const applyText = 'Apply';
export const removeText = 'REMOVE';
export const creditCardTextPaymentOption = 'Credit card';
export const paypalTextPaymentOption = 'Paypal';
export const blackFridayPromoText = ' (Exclusive Black Friday 33% Discount applied)';