import classNames from 'classnames';
import React, { ReactElement, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import Select, { DropdownIndicatorProps } from 'react-select';
import { FixedSizeList as List } from 'react-window';

import Icon from '../Icon/Icon';
import styles from './Select.module.scss';

const HEIGHT = 35;

export const MenuList = ({ options, children, maxHeight, getValue }: Record<string, any>): ReactElement => {
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * HEIGHT;

  return (
    <List height={maxHeight} itemCount={children.length} itemSize={HEIGHT} initialScrollOffset={initialOffset}>
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

interface SelectInterface {
  control: Control<any>;
  label: string;
  name: string;
  options: { value: string; label: string }[];
  required?: boolean;
  isLongList?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  className?: string;
  labelElement?: ReactElement;
  menuPortalTarget?: HTMLElement;
  stylesOverride?: Record<any, any>;
}

const dropdownStyles = ({ bgColor, fontSize, color, textAlign, padding }) => ({
  menuList: provided => ({
    ...provided,
    backgroundColor: '#F9FAFB',
    color,
    textAlign
  }),
  singleValue: provided => ({
    ...provided,
    fontSize,
    color,
    textAlign
  }),
  menu: provided => ({
    ...provided
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    color: state.isSelected ? '#fff' : color ?? '#1F2937',
    backgroundColor: state.isSelected ? '#DE6139' : state.isFocused ? '#E5E7EB' : bgColor ?? '#F9FAFB',
    ':active': {
      backgroundColor: state.isSelected ? '#DE6139' : state.isFocused ? '#E5E7EB' : bgColor ?? '#F9FAFB'
    },
    fontSize
  }),
  control: (provided, state) => ({
    padding: padding ?? '12px 16px',
    marginTop: '8px',
    border: '1px solid #D1D5DB',
    borderRadius: '8px',
    backgroundColor: bgColor ?? '#F9FAFB',
    cursor: 'pointer',
    height: '58px',
    borderColor: state.isFocused ? '#DE6139 !important' : '#D1D5DB',
    position: 'relative'
  }),
  menuPortal: provided => ({
    ...provided,
    borderRadius: '8px',
    zIndex: 9999,
    backgroundColor: '#F9FAFB'
  })
});

export const DropdownArrow = (): ReactElement => (
  <span className={styles.selectIcon}>
    <Icon name={'dropdownArrow'} />
  </span>
);

const CustomSelect = ({
  control,
  label,
  name,
  options,
  required,
  isLongList,
  containerClassName,
  labelClassName,
  className,
  labelElement,
  menuPortalTarget,
  stylesOverride = {}
}: SelectInterface): ReactElement => {
  const components = useMemo(
    () =>
      isLongList
        ? {
            IndicatorSeparator: () => null,
            DropdownIndicator: () => <DropdownArrow />,
            MenuList
          }
        : {
            IndicatorSeparator: () => null,
            DropdownIndicator: () => <DropdownArrow />
          },
    [isLongList]
  );

  return (
    <div className={containerClassName}>
      {labelElement ? labelElement : <label className={classNames(styles.label, labelClassName)}>{label}</label>}
      <Controller
        control={control}
        name={name}
        rules={{
          required
        }}
        render={({ field }) => (
          <Select
            components={components}
            name={name}
            options={options}
            className={className}
            styles={dropdownStyles(stylesOverride as any) as any}
            menuPortalTarget={menuPortalTarget}
            {...field}
          />
        )}
      />
    </div>
  );
};

export default CustomSelect;
