import React from 'react';
import moment from 'moment-timezone';
import AddToGoogleCalendarButton from '../../../../Atoms/AddToGoogleCalendarButton/AddToGoogleCalendarButton';
import styles from '../../BookSessionForm.module.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentlyBookingArtistConfig } from '../../../../../store/slices/musicians.slice';
import Button from '../../../../Atoms/Button/Button';
import { useHistory } from 'react-router-dom';
import { CustomerSessionDetailsTabs } from '../../../../../enums/customer-session-details-tabs.enum';
import Icon from '../../../../Atoms/Icon/Icon';
import { timeParserHelper } from '../../../../../helpers/time-parser.helper';
import { selectUser } from '../../../../../store/slices/user.slice';

const BookingConfirmation = ({ session, musician, instrument, primarySlot, selectedService }) => {
  const history = useHistory();
  const { timezone } = useSelector(selectUser, shallowEqual);
  const { Name: partnerName } = useSelector(selectCurrentlyBookingArtistConfig, shallowEqual);

  const { files_deadline_enabled, files_deadline } = selectedService || {};

  const { start_date } = session || {};
  const reviewStartTime = moment(start_date).tz(timezone).subtract(+files_deadline, 'minute').format('HH:mm');

  const onUploadFilesButtonClick = () => history.push(`/sessions/${session.id}/${CustomerSessionDetailsTabs.FILES_MANAGEMENT}`);
  const onEditSessionDetailsClick = () => history.push(`/sessions/${session.id}/edit`);

  return <div className={styles.confirmation}>
    <div className={styles.confirmation__icon}>
      <Icon name={'calendar-check'} />
    </div>
    <p className={styles.confirmation__title}>Nice! Your session has been booked!</p>

    <div className={styles.confirmation__fileUploadReminderWrapper}>
      {!files_deadline_enabled ? (
      <>
        <p className={styles.confirmation__description}>
          {partnerName} will download and prepare your material {timeParserHelper(files_deadline)} before the session,
          at {reviewStartTime} - {timezone}.
        </p>
        <p className={styles.confirmation__description}>
        Please ensure your material is uploaded in advance.
      </p>
      </>
      ) :
        <p className={styles.confirmation__description}>
        Please upload your session files at least<br />{timeParserHelper(files_deadline)} before the session.
        </p>}
    </div>

    <Button
      onClick={onUploadFilesButtonClick}
      className={styles.uploadButton}
      value={'Upload files'}
    />

    <p className={styles.uploadInfo}>
      You can upload your session files later in the My Sessions area.
    </p>

    <div className={styles.confirmation__actions}>
      <AddToGoogleCalendarButton
        partnerName={musician.name}
        instrument={instrument}
        trackName={session?.session_info?.title}
        start_date={moment(session?.start_date).toISOString()}
        end_date={moment(session?.start_date).add(session?.duration, 'minutes').toISOString()}
      />
    </div>

    <span
      onClick={onEditSessionDetailsClick}
      className={styles.editSessionDetailsText}
    >
      Edit your session details
    </span>
  </div>;
};
export default BookingConfirmation;
