import classNames from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import ReactModal from 'react-modal';

import Icon from '../../Atoms/Icon/Icon';
import { ModalTypes } from '../../../enums/modal-types.enum';
import styles from './ModalWrapper.module.scss';

interface ModalWrapperInterface {
  isOpen: boolean;
  children?: ReactNode;
  className?: string;
  onRequestClose?: () => void;
  isPreventClosing?: boolean;
  modalType?: ModalTypes;
}

const ModalWrapper = ({
  isOpen,
  children,
  className,
  onRequestClose,
  modalType = ModalTypes.DEFAULT,
  isPreventClosing
}: ModalWrapperInterface): ReactElement => (
  <ReactModal
    appElement={document.getElementById('root')}
    isOpen={isOpen}
    onRequestClose={isPreventClosing ? null : onRequestClose}
    className={classNames((modalType == ModalTypes.FULLSCREEN ? styles.fullscreen : styles.content), className)}
    overlayClassName={styles.overlay}
  >
    {console.log(modalType, className)}
    { modalType === ModalTypes.DEFAULT && (
     !isPreventClosing && (
        <button className={styles.close} onClick={onRequestClose}>
          <Icon name={'xClose'} />
        </button>
      )
    )}
    <div className={styles.body}>{children}</div>
  </ReactModal>
);

export default ModalWrapper;
