import classNames from 'classnames';
import moment, { Moment } from 'moment-timezone';
import React, { Dispatch, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UseFormGetValues, UseFormWatch } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { UserStatuses } from '../../../enums/user-statuses.enum';
import { SlotAdditionalClassName } from '../../../enums/slot-additional-class-enum';
import { ModalWarningTypes } from '../../../enums/modal-types.enum';
import { SlotDisplayStatus, SlotStatus } from '../../../enums/slot-status.enum';
import { translateUserSubscription } from '../../../helpers/translate-user-subscription';
import { useIsMobileResolution } from '../../../hooks/useIsMobileResolution';
import { SlotInterface } from '../../../interfaces/slot.interface';
import { setIsShowBlockingModal, setIsShowSpeedLimitModal, setIsShowTopUpModal } from '../../../store/slices/modals.slice';
import { selectLimitations } from '../../../store/slices/limitations.slice';
import { selectCurrentlyBookingArtistConfig } from '../../../store/slices/musicians.slice';
import { selectUser } from '../../../store/slices/user.slice';
import Icon from '../../Atoms/Icon/Icon';
import PreSessionSpecs from '../../Atoms/PreSessionSpecs/PreSessionSpecs';
import FileUploadsWarning from '../../Atoms/FileUploadsWarning/FileUploadsWarning';

import styles from './BookingCalendar.module.scss';

const MAX_WEEKS = 8;
const DAY_SIZE = 1;
const WEEK_SIZE = 7;
const MAX_SESSION_LENGTH_IN_MINUTES = 180;
const MAX_SLOT_OFFSET_TIME_IN_MINUTES = 80;
const NEED_MORE_CREDITS_MESSAGE = 'You need more credits to book this session.';

interface BookingCalendarInterface {
  slots: SlotInterface[];
  selectedSlots: SlotInterface[];
  selectedService: string;
  setSelectedSlots: Dispatch<SlotInterface[]>;
  getValues: UseFormGetValues<any>;
  watch: UseFormWatch<any>;
}

const BookingCalendar = ({
  slots,
  selectedSlots,
  selectedService,
  setSelectedSlots,
  getValues,
  watch
}: BookingCalendarInterface): ReactElement => {
  const dispatch = useDispatch();
  const isMobileResolution = useIsMobileResolution();

  const {
    services: { servicesList },
    services_first: serviceFirst,
    ServiceCategory: serviceCategories
  } = useSelector(selectCurrentlyBookingArtistConfig, shallowEqual);

  const user = useSelector(selectUser, shallowEqual);
  const limitations = useSelector(selectLimitations, shallowEqual);
  const timezone = user?.timezone || moment.tz.guess();

  const watcher = watch('service');

  const defaultService = useMemo(() => {
    const preselectedService = servicesList?.find(service => service.value === getValues().service);
    if (preselectedService && serviceFirst) {
      return preselectedService;
    }
    return servicesList?.find(service => service.isDefault);
    // eslint-disable-next-line
  }, [getValues, servicesList, serviceFirst, watcher]);

  const serviceDescription = useMemo((): string => defaultService.service_description, [defaultService]);

  const [currentWeek, setCurrentWeek] = useState(0);
  const [startDate, setStartDate] = useState<Moment>(moment());
  const [endDate, setEndDate] = useState<Moment>(moment());
  const [timetable, setTimetable] = useState<any>({ slots: {} });
  const [isNoAvailableSlots, setIsNoAvailableSlots] = useState(false);
  const [firstWeekWithAvailableSlots, setFirstWeekWithAvailableSlots] = useState(null);
  const [weekInto, setWeekInto] = useState<-1 | 1>(1);

  const isSlotPrebooked = (status: SlotStatus) => status === SlotStatus.PREBOOKED && !selectedSlots.length;

  const restrictedSlots = useMemo(
    (): SlotInterface[] => {
      const endRestrictionsDate = moment().add(limitations?.booking_priority_end, 'minutes');
      return user.has_limitations ? slots.filter(({ status, start_date }) => [SlotStatus.AVAILABLE].includes(status) && moment(start_date) <= endRestrictionsDate) : [];
    }, [slots]
  );


  const isSlotRestricted = (s: SlotInterface) => {
    const endRestrictionsDate = moment().add(limitations?.booking_priority_end, 'minutes');
    return user.has_limitations ? moment(s.start_date) <= endRestrictionsDate : false;
  };

  const weekSize = useMemo((): number => (isMobileResolution ? DAY_SIZE : WEEK_SIZE), [isMobileResolution]);

  const isWeekChanged = useRef(false);

  const selectedServiceWithOptions = servicesList.filter(x => x.value === selectedService)[0];

  const currentSelectedServiceValue = useMemo(
    (): string => {
      for (const category of serviceCategories) {
        const services = category.services.map(x => x.value);
        if (services.includes(selectedService)) {
          return category.value;
          break;
        }
      }
      return null;
    }, [selectedService]);

  const inCategory = useMemo(
    (): boolean => {
      for (const category of serviceCategories) {
        const services = category.services.map(x => x.value);
        if (services.includes(selectedService)) {
          return true;
          break;
        }
      }
      return false;
    }, [selectedService]);

  const isOfflineService = useMemo(
    (): boolean => {
      const zervice = servicesList.filter(x => x.value === selectedService)[0];
      return zervice?.session_type === 'offline';
    }, [selectedService]);


  const availableSlots = useMemo(
    (): SlotInterface[] => slots.filter(({ status }) => [SlotStatus.AVAILABLE, SlotStatus.PREBOOKED].includes(status)),
    [slots]
  );

  useEffect((): void => {
    setCurrentWeek(0);
  }, [isMobileResolution]);

  const canPressNext = useMemo((): boolean => {
    const maxPeriod = isMobileResolution ? MAX_WEEKS * WEEK_SIZE : MAX_WEEKS * DAY_SIZE;
    return currentWeek <= maxPeriod && availableSlots.length !== 0;
  }, [isMobileResolution, currentWeek, availableSlots.length, selectedService]);

  const calcSlotsDuration = (slotsToCalc: SlotInterface[]): number =>
    slotsToCalc.map(({ duration }) => duration).reduce((a, b) => a + b, 0);

  const filterSlotsByServiveCategory = (slotz: SlotInterface[], serviceCategorySelected: string): SlotInterface[] => {
    if (inCategory) {
      return slotz.filter(s => s.service_category === currentSelectedServiceValue);
    }
    return slotz.filter(s => s.service_category === null || s.service_category === 'default');
  };

  const arrangeSlotsPerDay = useCallback(
    (startDay: Moment, serviceCategorySelected: string): { slots: Record<number, SlotInterface>; currentWeek: number } => {
      const tempTimetable = { slots: {}, currentWeek };

      const slotz = filterSlotsByServiveCategory(slots, serviceCategorySelected);

      const weekStartDate = startDay.clone().tz(timezone).startOf('day').valueOf();
      const weekEndDate = startDay
        .clone()
        .add(weekSize - 1, 'days')
        .endOf('day')
        .valueOf();

      const weekSlots = slotz.filter(
        slot =>
          moment(slot.start_date).tz(timezone).valueOf() >= weekStartDate &&
          moment(slot.start_date).tz(timezone).valueOf() <= weekEndDate
      );

      for (let i = 0; i < weekSize; i++) {
        const day = startDay.clone().tz(timezone).add(i, 'days');

        const beginningOfDay = day.clone().startOf('day').valueOf();
        const endOfDay = day.clone().endOf('day').valueOf();

        tempTimetable.slots[day.unix()] = weekSlots.filter(
          slot =>
            moment(slot.start_date).tz(timezone).valueOf() >= beginningOfDay &&
            moment(slot.start_date).tz(timezone).valueOf() <= endOfDay
        );
      }

      const slotsByDay = Object.values(tempTimetable.slots).flat() as SlotInterface[];

      slotsByDay.forEach(slot => {
        const offsetMultiplier = (defaultService.slots || 1) - 1;
        const consecutiveSlots = slotsByDay.filter(({ start_date, status }) => {
          const startDateDiff = moment(start_date).diff(slot.start_date, 'minutes');
          return (
            startDateDiff > 0 &&
            startDateDiff <= MAX_SLOT_OFFSET_TIME_IN_MINUTES * offsetMultiplier &&
            [SlotStatus.AVAILABLE, SlotStatus.PREBOOKED].includes(status)
          );
        });
        const consecutiveDuration = calcSlotsDuration([slot, ...consecutiveSlots]);
        slot.displayStatus =
          consecutiveSlots.length === offsetMultiplier && consecutiveDuration <= MAX_SESSION_LENGTH_IN_MINUTES
            ? SlotDisplayStatus.SHOWN
            : SlotDisplayStatus.HIDDEN;
      });

      return tempTimetable;
    },
    [currentWeek, weekSize, timezone, slots, defaultService.slots, selectedService]
  );

  const checkWeekForAvailableSlots = (slotsObj: Record<number, SlotInterface>): boolean => {
    const timetableSlots = Object.values(slotsObj).flat() as SlotInterface[];

    const availableSlotsList = timetableSlots.filter(
      ({ status, displayStatus, service_category }) =>
        [SlotStatus.AVAILABLE, SlotStatus.PREBOOKED].includes(status) && displayStatus === SlotDisplayStatus.SHOWN
    );
    return !!availableSlotsList.length;
  };

  const isPrevWeekAvailable = useMemo((): boolean => {
    const prevWeekStartDate = startDate.clone().subtract(weekSize, 'days');
    const prevWeekTimetable = arrangeSlotsPerDay(prevWeekStartDate, selectedService);

    return (
      checkWeekForAvailableSlots(prevWeekTimetable.slots) || prevWeekTimetable.currentWeek > firstWeekWithAvailableSlots
    );
  }, [startDate, weekSize, arrangeSlotsPerDay, firstWeekWithAvailableSlots, selectedService]);

  const canPressBack = useMemo((): boolean => {
    const currentDate = moment().tz(timezone).startOf('day');

    const timetableTimestamps = Object.keys(timetable.slots);
    const isFutureDate = timetableTimestamps.every(timestamp => moment.unix(+timestamp).isSameOrAfter(currentDate));

    return isFutureDate && isPrevWeekAvailable && availableSlots.length !== 0;
  }, [timezone, timetable.slots, isPrevWeekAvailable, availableSlots]);

  const { sessionsLeft, concurrency, currentSessions, isAnnual, creditsBank } = translateUserSubscription(user);

  const changeWeek = useCallback(
    (into: -1 | 1, isAuto?: boolean): (() => void) => (): void => {
      if ((into === -1 && !canPressBack) || (into === 1 && !canPressNext)) {
        return;
      }
      setWeekInto(into);
      if (!isAuto) {
        isWeekChanged.current = true;
      }
      setCurrentWeek(currentWeek + into);
    },
    [currentWeek, canPressBack, canPressNext]
  );


  useEffect((): void => {
    if (!inCategory && availableSlots.filter(x => x.service_category === null || x.service_category === 'default').length === 0 || inCategory && availableSlots.filter(x => x.service_category === currentSelectedServiceValue).length === 0) {
      setIsNoAvailableSlots(true);
      return;
    }
    setIsNoAvailableSlots(false);

    if (isWeekChanged.current && !isMobileResolution) {
      return;
    }
    if (checkWeekForAvailableSlots(timetable.slots) && firstWeekWithAvailableSlots === null) {
      setFirstWeekWithAvailableSlots(timetable.currentWeek);
    }
    if (firstWeekWithAvailableSlots === timetable.currentWeek && !checkWeekForAvailableSlots(timetable.slots)) {
      setFirstWeekWithAvailableSlots(null);
    }

    if (!inCategory && !checkWeekForAvailableSlots(timetable.slots) && timetable.currentWeek === currentWeek) {
      changeWeek(weekInto, true)();
    }

    // if (inCategory && !checkWeekForAvailableSlots(timetable.slots) && timetable.currentWeek === currentWeek) {
    //  changeWeek(weekInto, true)();
    // }
  }, [
    changeWeek,
    timezone,
    currentWeek,
    timetable,
    availableSlots,
    weekInto,
    firstWeekWithAvailableSlots,
    isMobileResolution
  ]);

  useEffect((): void => {
    const startDay = moment()
      .tz(timezone)
      .startOf('isoWeek')
      .subtract(1, 'days')
      .add(weekSize * currentWeek, 'days');

    setStartDate(startDay);
    setEndDate(startDay.clone().add(weekSize - 1, 'days'));
    const arrangedTimetable = arrangeSlotsPerDay(startDay, selectedService);
    setTimetable(arrangedTimetable);
  }, [currentWeek, timezone, weekSize, arrangeSlotsPerDay, selectedService]);

  const sessionCredits = useCallback(
    (slotz: SlotInterface[]): number => {
      const { cohorts, subscription } = user;
      const {
        alternative_value: alternativeValue,
        alternative_value_annual: alternativeValueAnnual,
        exclusive_prices_value: exclusiveValue
      } = defaultService;
      const credits = user.status == UserStatuses.ONDEMAND ? defaultService.credits : user.cohorts?.hasExclusivePrices
        ? exclusiveValue
        : cohorts?.isPrePivot
        ? subscription?.isAnnual
          ? alternativeValueAnnual
          : alternativeValue
        : defaultService.credits;
      return slotz.length * credits;
    },
    [defaultService, user]
  );

  const showFilesDeadlineWarning = () => selectedServiceWithOptions?.files_deadline_enabled === true ? true : false;

  const isNoSessionsOrCreditsLeft = useCallback(
    (slotz: SlotInterface[]): boolean => sessionsLeft + creditsBank < sessionCredits(slotz),
    [creditsBank, sessionCredits, sessionsLeft]
  );

  const creditsAmountRequired = useCallback(
    (currentPrice: number): number => currentPrice - (sessionsLeft + creditsBank),
    [creditsBank, sessionCredits, sessionsLeft]
  );

  const isSpeedLimitExceeded = () => {
    if (concurrency < 1) {
      return false;
    }
    return isAnnual && currentSessions >= concurrency;
  };

  const sortSlotsByStartTime = (slotsToSort: SlotInterface[]): SlotInterface[] =>
    slotsToSort.sort((a, b) => (moment(a.start_date).unix() - moment(b.start_date).unix() < 0 ? -1 : 1));

  const onSlotClick = (slot: SlotInterface): void => {
    if (isSlotRestricted(slot)) {
      return;
    }
    const slotList = Object.values(timetable.slots).flat();
    const slotIdList = Object.values(timetable.slots)
      .flat()
      .map((s: SlotInterface) => s.id);
    const slotIdx = slotIdList.indexOf(slot.id);
    const slotsIdsSubset = slotIdList.slice(slotIdx, slotIdx + defaultService.slots);
    const slotsSubset = slotList.filter(({ id }) => slotsIdsSubset.includes(id)) as SlotInterface[];

    handleSlotSelection(slotsSubset);
  };

  const handleSlotSelection = (slotz: SlotInterface[]): void => {
    if (slotz.some(({ status }) => status === SlotStatus.BOOKED)) {
      return;
    }

    const slotList = Object.values(timetable.slots);
    const slotIdList = Object.values(timetable.slots)
      .flat()
      .map((s: SlotInterface) => s.id);

    if (slotz.some(slot => isSlotSelected(slot))) {
      slotList.forEach((s: SlotInterface) => (s.additionalClassName = ''));
      setSelectedSlots([]);
      return;
    }

    const updatedSelectedSlots = sortSlotsByStartTime(slotz.length === 1 ? [...selectedSlots, ...slotz] : slotz);
    const combinedSlotsDuration = calcSlotsDuration(updatedSelectedSlots);

    if (isSpeedLimitExceeded()) {
      dispatch(setIsShowSpeedLimitModal(true));
      return;
    } else if (isNoSessionsOrCreditsLeft(updatedSelectedSlots)) {
      const neededCredits = creditsAmountRequired(sessionCredits(updatedSelectedSlots));
      const msg = `You need ${neededCredits} more ${neededCredits == 1 ? 'credit' : 'credits'} to book this session.`;
      dispatch(setIsShowTopUpModal({
        isShown: true,
        warningType: ModalWarningTypes.WARNING,
        message: msg,
        initialAmount: creditsAmountRequired(sessionCredits(updatedSelectedSlots))
      }));
      return;
    }

    if (combinedSlotsDuration > MAX_SESSION_LENGTH_IN_MINUTES) {
      slotz.forEach(slot => (slot.additionalClassName = ''));
      setSelectedSlots(slotz);
      return;
    }

    if (updatedSelectedSlots.length) {
      const slotStartDateMoment = moment(slotz[0].start_date);
      const firstSlot = updatedSelectedSlots[0];
      const firstSlotGlobalIdx = slotIdList.indexOf(firstSlot.id);

      const middleSlotIdx = firstSlotGlobalIdx + 1;
      const middleSlotId = slotIdList[middleSlotIdx];

      const areSlotsAdjacent = updatedSelectedSlots.map(s => s.id).includes(middleSlotId as number);
      const prevSlot = selectedSlots[selectedSlots.length - 1];
      const slotStartTimeDiffInMinutes = slotStartDateMoment.diff(prevSlot?.start_date, 'minutes');
      const isSlotOffsetInvalid =
        slotStartTimeDiffInMinutes > MAX_SLOT_OFFSET_TIME_IN_MINUTES || slotStartTimeDiffInMinutes <= 0;

      if ((isSlotOffsetInvalid || !areSlotsAdjacent) && slotz.length === 1) {
        slotz.forEach(slot => (slot.additionalClassName = ''));
        setSelectedSlots(slotz);
        return;
      }
    }

    updatedSelectedSlots.forEach(s => (s.additionalClassName = SlotAdditionalClassName.MIDDLE));
    updatedSelectedSlots[0].additionalClassName = SlotAdditionalClassName.TOP;
    updatedSelectedSlots[0].isPrimary = true;
    updatedSelectedSlots[updatedSelectedSlots.length - 1].additionalClassName = SlotAdditionalClassName.BOTTOM;

    if (updatedSelectedSlots.length === 1) {
      updatedSelectedSlots[0].additionalClassName = '';
    }

    setSelectedSlots(updatedSelectedSlots);
  };

  const sessionDuration = useMemo((): number => calcSlotsDuration(selectedSlots), [selectedSlots]);

  const isSlotSelected = (slot: SlotInterface): boolean => !!selectedSlots.find(({ id }) => id === slot.id);

  const sessionCreditsResult = useMemo((): number => sessionCredits(selectedSlots), [selectedSlots, sessionCredits]);

  return (
    <div className={classNames(styles.availabilityCalendar)}>
      {/* <div className={styles.description}>
        <p>{serviceDescription}</p>
      </div> */}
      <div>
        <div className={styles.availabilityCalendarPagination}>
          <a
            className={classNames(
              styles.availabilityCalendarPagination__button,
              !canPressBack && styles.availabilityCalendarPagination__buttonDisabled
            )}
            onClick={changeWeek(-1)}
          >
            <Icon name='arrow-left' width={10} height={18} viewBox='0 0 10 18' />
          </a>

          {isMobileResolution ? (
            <div className={styles.availabilityCalendarPagination__title}>
              {startDate.format('MMMM DD')}
              <span className={styles.timezoneTitle}>({timezone})</span>
            </div>
          ) : (
            <div className={styles.availabilityCalendarPagination__title}>
              {startDate.format('MMMM DD')} - {endDate.format('DD')}, {endDate.format('YYYY')}
              <span className={styles.timezoneTitle}>({timezone})</span>
            </div>
          )}

          <a
            className={classNames(
              styles.availabilityCalendarPagination__button,
              !canPressNext && styles.availabilityCalendarPagination__buttonDisabled
            )}
            onClick={changeWeek(1)}
          >
            <Icon name='arrow-right' width={10} height={18} viewBox='0 0 10 18' />
          </a>
        </div>

        <div className={styles.slotsTable}>
          {canPressNext || isNoAvailableSlots ? (
            Object.keys(timetable.slots).map((dow, index) => (
              <div key={index} className={styles.slotsTable__column}>
                <div className={styles.slotsTable__columnHead}>
                  <div className={styles.slotsTable__dayName}>
                    {moment
                      .unix(+dow)
                      .tz(timezone)
                      .format('dddd')}
                  </div>
                  <div className={styles.slotsTable__dayNumber}>
                    {moment
                      .unix(+dow)
                      .tz(timezone)
                      .format('DD')}
                  </div>
                </div>
                {timetable.slots[dow]
                  .sort((a, b) => moment(a.start_date).diff(moment(b.start_date)))
                  .map(slot => (
                    <div
                      key={slot.id}
                      data-cy={`slot-${slot.status}`}
                      data-info={`slot-${slot.status}-${isSlotRestricted(slot)}`}
                      className={classNames(
                        styles.slotsTable__slot,
                        styles[slot.status],
                        isSlotPrebooked(slot.status) && styles.slotPrebooked,
                        isSlotRestricted(slot) && styles.slotRestricted,
                        isSlotSelected(slot) && styles.isSelected,
                        slot.displayStatus === SlotDisplayStatus.HIDDEN && styles.slotHidden,
                        selectedSlots.includes(slot) && styles[slot.additionalClassName]
                      )}
                      onClick={() => onSlotClick(slot)}
                    >
                      <div className={styles.slotsTable__slotTime}>
                        {moment(slot.start_date).tz(timezone).format('hh:mm')}
                      </div>
                      <div className={styles.slotsTable__slotPeriod}>
                        {moment(slot.start_date).tz(timezone).format('a')}
                      </div>
                      {isSlotRestricted(slot) && (
                        <Icon name='full-lock' className={styles.slotIcon} />
                      )}
                    </div>
                  ))}
              </div>
            ))
          ) : (
            <div className={styles.availabilityCalendar__note}>
              <div className={styles.availabilityCalendar__noteIcon}>
                <Icon name={'construction-sign'} />
              </div>
              <p className={styles.availabilityCalendar__noteText}>
                <em>Heads Up!</em>
                {" You can't book sessions"}
                <br />
                more than 2 months in advance.
              </p>
            </div>
          )}
          {isNoAvailableSlots && (
            <div className={styles.noAvailableSlots}>
              <p>There are no available sessions at the moment.</p>
              <p>Please check again soon!</p>
            </div>
          )}
        </div>

        <PreSessionSpecs
          timezone={timezone}
          sessionDuration={sessionDuration}
          sessionCreditsResult={sessionCreditsResult}
          offlineService={isOfflineService}
        />

        { showFilesDeadlineWarning() || user.has_limitations && (
          <FileUploadsWarning
            filesDeadline={selectedServiceWithOptions?.files_deadline}
            icon={'calendar_outlined'}
            hasLimitations={user.has_limitations}
            warningHeader={limitations.booking_priority_header}
            warningBody={limitations.booking_priority_body}
          />
        )}
      </div>
    </div>
  );
};

export default BookingCalendar;
