import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import styles from './Toast.module.css';
import { hideToast, selectToast } from '../../../store/slices/toast.slice';
import { ToastTypes } from '../../../enums/toast-types.enum';

const Toast = () => {
  const dispatch = useDispatch();
  const { toastType, message, show } = useSelector(selectToast, shallowEqual);

  const closeToast = () => dispatch(hideToast());

  return (
    <>
      {show && (
        <div className={styles.toast}>
          <div className={styles.toast__content}>
            {toastType === ToastTypes.SUCCESS && (
              <span className={styles.toast__icon}>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                  <g
                    className='nc-icon-wrapper'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    fill='#44837d'
                    stroke='#44837d'
                  >
                    <polyline data-color='color-2' fill='none' strokeMiterlimit='10' points=' 6,12 10,16 18,8 ' />
                    <circle fill='none' stroke='#44837d' strokeMiterlimit='10' cx='12' cy='12' r='11' />
                  </g>
                </svg>
              </span>
            )}

            {toastType === ToastTypes.DANGER && (
              <span className={styles.toast__icon}>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                  <g
                    className='nc-icon-wrapper'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    fill='#C53939'
                    stroke='#C53939'
                  >
                    <circle cx='12' cy='12' r='11' fill='none' stroke='#C53939' strokeMiterlimit='10' />
                    <line data-color='color-2' x1='12' y1='7' x2='12' y2='13' fill='none' strokeMiterlimit='10' />
                    <circle data-color='color-2' data-stroke='none' cx='12' cy='17' r='1' stroke='none' />
                  </g>
                </svg>
              </span>
            )}

            <p className={styles.toast__text}>{message}</p>
          </div>

          <button onClick={closeToast} className={styles.toast__close}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M19.7 4.3C19.3 3.9 18.7 3.9 18.3 4.3L12 10.6L5.7 4.3C5.3 3.9 4.7 3.9 4.3 4.3C3.9 4.7 3.9 5.3 4.3 5.7L10.6 12L4.3 18.3C3.9 18.7 3.9 19.3 4.3 19.7C4.5 19.9 4.7 20 5 20C5.3 20 5.5 19.9 5.7 19.7L12 13.4L18.3 19.7C18.5 19.9 18.8 20 19 20C19.2 20 19.5 19.9 19.7 19.7C20.1 19.3 20.1 18.7 19.7 18.3L13.4 12L19.7 5.7C20.1 5.3 20.1 4.7 19.7 4.3Z'
                fill='#D1D5DB'
              />
            </svg>
          </button>
        </div>
      )}
    </>
  );
};

export default Toast;
