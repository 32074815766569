import { useEffect, useRef, useState } from 'react';

import axios from 'axios';

import { FileDownloadStatusEnum } from '../../../enums/file-download-status.enum';

const REQUEST_DOWNLOAD_LINK_TIMEOUT_IN_MS = 2000;

const requestDownloadLink = async ({ data, setDownloadUrl, setGeneratingDownloadLink }): Promise<void> => {
  setGeneratingDownloadLink(true);
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/sessions/file-download-link`, data, {
      withCredentials: true
    });
    const { code, downloadUrl } = res.data;
    if (code === FileDownloadStatusEnum.FILES_READY) {
      setDownloadUrl(downloadUrl);
      setGeneratingDownloadLink(false);
    }
  } catch (e) {
    console.error(e);
  }
};

export const useDownloadAllFiles = (sessionId: number): Record<string, any> => {
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [generatingDownloadLink, setGeneratingDownloadLink] = useState(false);
  const requestDownloadLinkInterval = useRef(null);

  useEffect((): void => {
    if (!downloadUrl) {
      return;
    }
    clearInterval(requestDownloadLinkInterval.current);
  }, [downloadUrl]);

  const generateDownloadLink = async (): Promise<void> => {
    if (generatingDownloadLink) {
      return;
    }
    const data = {
      session_id: sessionId
    };
    const call = () =>
      requestDownloadLink({
        data,
        setDownloadUrl,
        setGeneratingDownloadLink
      });
    requestDownloadLinkInterval.current = setInterval(async () => {
      await call();
    }, REQUEST_DOWNLOAD_LINK_TIMEOUT_IN_MS);
    await call();
  };

  return {
    setDownloadUrl,
    generateDownloadLink,
    downloadUrl,
    generatingDownloadLink
  };
};
