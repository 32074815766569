import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Icon from '../../../../../components/Atoms/Icon/Icon';
import Navbar from '../../../../../components/Atoms/Navbar/Navbar.js';
import BookSessionForm from '../../../../../components/Molecules/BookSessionForm/BookSessionForm';
import MusicianCard from '../../../../../components/Molecules/MusicianCard/MusicianCard.js';
import SidePane from '../../../../../components/Molecules/SidePane/SidePane.js';
import { config } from '../../../../../config/musicianConfigs';
import styles from '../Explore.module.scss';
import useIsPartnerGuard from '../../../../../guards/useIsPartnerDashboard.guard';
import {
  getCurrentlyBookingArtistConfig,
  getInstruments,
  getMusicians
} from '../../../../../store/actions/musicians.actions';
import Spinner from '../../../../../components/Atoms/Spinner/Spinner';
import { UserStatuses } from '../../../../../enums/user-statuses.enum';
import { getTracks } from '../../../../../store/actions/sessions.actions';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { setIsShowGlobalLoader } from '../../../../../store/slices/global.slice';
import MusiciansService from '../../../../../services/musicians/musicians.service';
import { setSession } from '../../../../../store/slices/sessions.slice';

const AVAILABILITY_DAYS = 60;
const DEFAULT_CONFIG = {
  bookingOptions: null,
  fileUploadRules: null,
  deliverablesOptions: {
    default: 'Stems to be imported in a different DAW',
    options: ['Stems to be imported in a different DAW']
  }
};

const BasicExplore = () => {
  const dispatch = useDispatch();
  const { user, tracks } = useSelector(state => state.userSlice, shallowEqual);
  const { musicians, instruments } = useSelector(state => state.musiciansSlice, shallowEqual);
  const history = useHistory();
  const { slug } = useParams();
  const location = useLocation();

  const [selectedInstrument, setSelectedInstruments] = useState('all');
  const [showPanel, setShowPanel] = useState(false);
  const [slotsToShow, setSlotsToShow] = useState([]);
  const [selectedMusician, setSelectedMusician] = useState(null);
  const [selectedMusicianConfig, setSelectedMusicianConfig] = useState(null);
  const [availableMusicians, setAvailableMusicians] = useState(musicians);

  useIsPartnerGuard('/sessions');

  useEffect(() => {
    if (musicians.length) {
      return;
    }
    const getData = async () => {
      await Promise.all([
        dispatch(getInstruments()),
        dispatch(getMusicians()),
        dispatch(getTracks())
      ]);
    };
    getData().finally();
  }, [musicians, dispatch]);

  useEffect(() => {
    setAvailableMusicians(musicians);
  }, [musicians]);

  const instrumentsToSelect = useMemo(() => instruments.map(i => i.name), [instruments]);

  const getInstrumentsForMusician = musician => {
    if (selectedInstrument !== 'all') {
      return selectedInstrument;
    }
    const instrumentsList = instruments
      .filter(i => i.musicians.includes(musician.name))
      .map(r => r.name)
      .join(' / ');
    /* Ugly case for Luiz */
    if (instrumentsList === 'Mastering / Mix Advice') {
      return 'Mix Advice / Mastering';
    }
    /* Ugly case for Pedro */
    if (instrumentsList.includes('Pre-production')) {
      return 'Pre-production';
    }
    return instrumentsList;
  };

  const getMusicianAvailability = useCallback(async ({ id }) => {
    const slots = (await MusiciansService.getMusicianAvailability(id, AVAILABILITY_DAYS)).data;
    setSlotsToShow(slots);
  }, []);

  const getMusicianConfiguration = useCallback(async ({ id }) => {
    try {
      const res = await MusiciansService.getMusicianConfiguration(id);
      setSelectedMusicianConfig(res.data);
    } catch (err) {
      setSelectedMusicianConfig(DEFAULT_CONFIG);
      throw new Error(err);
    }
  }, []);

  const selectMusician = useCallback(async musician => {
    dispatch(setIsShowGlobalLoader(true));
    try {
      await Promise.all([
        getMusicianAvailability(musician),
        getMusicianConfiguration(musician),
        dispatch(getCurrentlyBookingArtistConfig(musician.slug))
      ]);
      setSelectedMusician(musician);
      setShowPanel(true);
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }
  }, [getMusicianAvailability, getMusicianConfiguration, dispatch]);

  const refreshAvailableMusicians = (filterClicked, selection) => {
    /* We just filter by instrument */
    if (filterClicked === 'instrument') {
      if (selection === 'all') {
        setAvailableMusicians(musicians);
      } else {
        const musiciansToShow = instruments.filter(i => i.name === selection)[0].musicians;
        setAvailableMusicians(musicians.filter(s => musiciansToShow.includes(s.name)));
      }
    }
  };

  useEffect(() => {
    if (!slug || !musicians.length) {
      return;
    }
    if (location.search) {
      history.push(location.pathname);
    }
    const musicianToOpen = musicians.find(musician => musician.slug === slug);
    if (!musicianToOpen) {
      history.push('/explore');
      return;
    }
    dispatch(setIsShowGlobalLoader(true));
    selectMusician(musicianToOpen)
      .finally(() => dispatch(setIsShowGlobalLoader(false)));
  }, [musicians, selectMusician, slug, dispatch, history, location.pathname, location.search]);

  const onClickInstrument = selection => {
    selection === selectedInstrument ? setSelectedInstruments('all') : setSelectedInstruments(selection);
    refreshAvailableMusicians('instrument', selection === selectedInstrument ? 'all' : selection);
  };

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const onPanelClose = () => {
    dispatch(setSession(null));
    history.push('/explore');
    setShowPanel(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Navbar currentPath='musicians' spaceBottom={false} isTrial={user?.status === UserStatuses.TRIAL} />

        {(musicians.length && instruments.length) ? <div className={styles.container}>
          <div className={styles.headerNav}>
            {instrumentsToSelect.map((instrument_name, index) => (
              <a
                key={index}
                className={
                  styles.headerSubMenuLink + ' ' + (selectedInstrument === instrument_name ? styles.isActive : '')
                }
                onClick={() => onClickInstrument(instrument_name)}
              >
                {instrument_name}
              </a>
            ))}
          </div>

          <div className={styles.musiciansGrid}>
            {availableMusicians.length === 0 ? (
              <>
                <div className={styles.musiciansGrid__notFound}>
                  <div className={styles.musiciansGrid__notFoundIcon}>
                    <Icon name='magnifying-glass' width={32} height={32} viewBox='0 0 32 32' />
                  </div>
                  <h4 className={styles.musiciansGrid__notFoundTitle}>No results found!</h4>
                  <p className={styles.musiciansGrid__notFoundDescription}>
                    Adjust your search filters by choosing another instrument.
                  </p>
                </div>
              </>
            ) : (
              availableMusicians.map((musician, index) => {
                /* Musicians that don't have an image throws `undefined` which makes the whole code breaks, did this quick fix until it's resolved */
                //const tempImg = musician.image_url ? musician.image_url : (musician.image?.url ? musician.image?.url : "/images/musicians/avatar.png");

                const tempImg = `../../../assets/images/musicians-avatars/${musician.name}.png`;
                return (
                  <MusicianCard
                    key={musician.id}
                    id={musician.id}
                    avatar={tempImg}
                    name={musician.name}
                    bio={musician.bio}
                    instrument={getInstrumentsForMusician(musician)}
                    musician={musician}
                    onClick={() => selectMusician(musician)}
                    externalUrl={config.linksByName[musician.name]}
                    musicianUrl={`${process.env.REACT_APP_MUSICIAN_PROFILES_DOMAIN}/artists/${musician.slug}`}
                  />
                );
              })
            )}
          </div>
        </div> : <Spinner className={styles.spinner} isBlue />}
      </div>

      <SidePane
        isOpen={showPanel}
        isLarge
        onRequestClose={onPanelClose}
      >
        {selectedMusician && (
          <>
            <BookSessionForm
              musician={selectedMusician}
              musicianConfiguration={selectedMusicianConfig}
              instrument={getInstrumentsForMusician(selectedMusician)}
              timezone={localStorage.getItem('timezone') || user?.timezone || moment.tz.guess()}
              slots={slotsToShow}
              tracks={tracks}
              mobileVersion={width < 576}
              onClose={() => {
                setShowPanel(false);
              }}
            />
          </>
        )}
      </SidePane>
    </>
  );
};

export default BasicExplore;
