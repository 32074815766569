import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { RootState } from 'app/store/store';

import { parseThreadMessagesHelper } from '../../helpers/parse-thread-messages.helper';
import { MessageInterface, ThreadInterface } from '../../interfaces/messages.interface';
import { getThread } from '../actions/messages.actions';
import { SlicesNames } from './slices-names.enum';

type SliceState = {
  thread: ThreadInterface;
};

export const messages = createSlice({
  name: SlicesNames.MESSAGES_SLICE,
  initialState: {
    thread: null
  },
  reducers: {
    clearThread: (state: SliceState): void => {
      state.thread = null;
    },
    addNewMessage: (state: SliceState, { payload }: PayloadAction<MessageInterface>): void => {
      const threadCopy = cloneDeep(state.thread);
      threadCopy.messages = [payload, ...threadCopy.messages];
      state.thread = threadCopy;
    },
    updateThread: (state: SliceState, { payload }: PayloadAction<ThreadInterface>): void => {
      state.thread = parseThreadMessagesHelper(payload);
    }
  },
  extraReducers: {
    [getThread.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<ThreadInterface>): void => {
      state.thread = payload;
    }
  }
});

export const { clearThread, addNewMessage, updateThread } = messages.actions;
export const selectThread = (state: RootState) => state.messagesSlice.thread;

export default messages.reducer;
