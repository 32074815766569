import axios, { AxiosPromise } from 'axios';

import { ArtistConfigInterface } from '../../interfaces/artist-config.interface';
import { GetPartnersFiltersInterface } from '../../interfaces/get-partners-filters.interface';
import { InvoiceDataInterface } from '../../interfaces/invoice-data.interface';
import { PartnerInterface } from '../../interfaces/partner.interface';
import { ServiceInterface } from '../../interfaces/service.interface';

const API = `${process.env.REACT_APP_API_URL}/api`;
const MUSICIANS_API = `${process.env.REACT_APP_API_URL}/api/v2/musicians`;
const ARTISTS_STRAPI_API = `${process.env.REACT_APP_STRAPI}/artists`;
const PARTNERS_V2_API = `${process.env.REACT_APP_API_URL}/api/v2/partners`;

const axiosInstance = axios.create({
  baseURL: API,
  withCredentials: true
});

const musiciansInstance = axios.create({
  baseURL: MUSICIANS_API,
  withCredentials: true
});

const artistsInstance = axios.create({
  baseURL: ARTISTS_STRAPI_API
});

const partnersV2Instance = axios.create({
  baseURL: PARTNERS_V2_API,
  withCredentials: true
});

const getPartnersRequest = (): AxiosPromise => axiosInstance.get('/partners');
const getInstrumentsRequest = (): AxiosPromise => axiosInstance.get('/instruments');
const getMusicianAvailability = (id: number, days: number): AxiosPromise =>
  axiosInstance.get(`v2/availability?partner_id=${id}&days=${days}`);
const getMusicianConfiguration = (id: number): AxiosPromise => axiosInstance.get(`/partners/${id}`);

const getMusicianStats = ({ month, year }): AxiosPromise<InvoiceDataInterface> =>
  musiciansInstance.post('/stats', { month, year });

const getMusicianCalendar = ({
  timezone,
  from_date, // YYYY-MM-DD
  to_date // YYYY-MM-DD
}): AxiosPromise =>
  musiciansInstance.get('/calendar', {
    params: {
      timezone,
      from_date,
      to_date
    }
  });

const getArtistConfigRequest = (slug: string): AxiosPromise<ArtistConfigInterface> =>
  artistsInstance.get(`/${slug}?mode=configuration`);

const getExploreFilters = (): AxiosPromise<{ services: ServiceInterface[] }> => partnersV2Instance.get('/filters');

const gerPartners = ({
  startDate,
  endDate,
  startTime,
  endTime,
  timezone
}: GetPartnersFiltersInterface): AxiosPromise<{ partners: PartnerInterface[] }> =>
  partnersV2Instance.get('', {
    params: {
      start_date: startDate,
      end_date: endDate,
      start_time: startTime,
      end_time: endTime,
      timezone
    }
  });

export default {
  getPartnersRequest,
  getInstrumentsRequest,
  getMusicianStats,
  getMusicianCalendar,
  getArtistConfigRequest,
  getMusicianAvailability,
  getMusicianConfiguration,
  getExploreFilters,
  gerPartners
};
