import React, { ReactElement, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import classnames from 'classnames';
import { PlansCtaTypeEnum } from '../../../enums/plans-cta-type.enum';
import { StrapiIcons } from '../../../enums/strapi-icons.enum';
import { ProductTypes } from '../../../enums/product-types.enum';
import Icon from '../Icon/Icon';
import styles from './PlansSelector.module.scss';

interface PlansSelectorInterface {
  // children: ReactElement;
  header: string;
  bundleProducts: any[];
  mappedPlans: any[];
  orchestraProducts: any[];
  selectedPlan: any;
  selectedProductType: string;
  onCTAButtonClick: any;
  onPlanSelect: any;
  ctaType: any;
  showTopupPrice: boolean;
}

const BASE_PRICE = 99;

const PlansSelector = ({ header, bundleProducts, mappedPlans, orchestraProducts, selectedPlan, selectedProductType, onCTAButtonClick, onPlanSelect, ctaType, showTopupPrice = false }: PlansSelectorInterface): ReactElement => {
  const [productType, setProductType] = useState(null);
  const { blackFridaySale } = useFlags();

  const isSelected = (planId, productType): boolean => (
    planId === selectedPlan?.id && selectedProductType === productType
  );

  const buttonTextByctaType = (price): string => {
    if (price == undefined) {
      return 'Select a Product';
    }
    if (PlansCtaTypeEnum.UPGRADE === ctaType) {
      return `$${(price / 12).toFixed(2)}/Month`;
    }
    if (PlansCtaTypeEnum.DOWNGRADE === ctaType) {
      return `$${(price / 12).toFixed(2)}/Month plan`;
    }
    return `$${(price / 12).toFixed(2)}/Month`;
  };

  return (
    <div className={styles.container}>
      <span className={styles.header}>{header}</span>
      <div className={styles.plansContainer}>
        {bundleProducts?.map(plan =>
          <div
            key={plan.id}
            className={classnames(styles.plan, isSelected(plan.id, ProductTypes.BUNDLES) && styles.selectedPlan)}
            data-cy={'plan-price-tile'}
            onClick={onPlanSelect(plan, ProductTypes.BUNDLES)}
          >
            <div className={styles.row}>
              <Icon name={isSelected(plan.id, ProductTypes.BUNDLES) ? 'radio-selected' : 'radio-unselected'} className={styles.bulletIcon} />
              <span className={classnames(styles.bigText, isSelected(plan.id, ProductTypes.BUNDLES) && styles.highlight)}>
                {plan.title}
              </span>
              {/* <span
                className={classnames(styles.bigText, styles.bigTextGray)}>${numberWithCommas(plan.price)}/Year
              </span> */}
              </div>
            <div className={classnames(styles.row, styles.mediumRow)}>
              <div className={styles.bullets}>
                {plan?.bullets?.map((b, index) => (
                  <span key={index} className={styles.mediumText}>
                    <Icon name={b.icon ?? StrapiIcons.CHECKMARK} className={styles.creditsPeryearIcon} />{b.bullet}
                  </span>
                ))}
              </div>
            </div>
            {/* <div className={styles.row}>
              <span className={styles.smallText}>
                Top-up credit: ${plan.topup_price}
                <Icon name={'question-mark'} className={styles.icon} data-tip={plan.topup_info} />
              </span>
            </div> */}
          </div>
        )}
        { bundleProducts?.length > 0 && (
          <div className={styles.divider}></div>
        )}
        {mappedPlans?.map(plan =>
          <div
            key={plan.id}
            className={classnames(styles.plan, isSelected(plan.id, ProductTypes.MEMBERSHIPS) && styles.selectedPlan)}
            data-cy={'plan-price-tile'}
            onClick={onPlanSelect(plan, ProductTypes.MEMBERSHIPS)}
          >
            <div className={styles.row}>
              <Icon name={isSelected(plan.id, ProductTypes.MEMBERSHIPS) ? 'radio-selected' : 'radio-unselected'} className={styles.bulletIcon} />
              <span className={classnames(styles.bigText, isSelected(plan.id, ProductTypes.MEMBERSHIPS) && styles.highlight)}>
                {plan.title}
              </span>
              {/* <span
                className={classnames(styles.bigText, styles.bigTextGray)}>${numberWithCommas(plan.price)}/Year
              </span> */}
              </div>
            <div className={classnames(styles.row, styles.mediumRow)}>
              {blackFridaySale ?
                (
                  <div className={styles.bullets}>
                    {plan?.bullets?.map((b, index) => (
                      <span key={index} className={styles.mediumText}>
                        <Icon name={b.icon ?? StrapiIcons.CHECKMARK} className={styles.creditsPeryearIcon} />{b.bullet}
                      </span>
                    ))}
                    </div>
                ) :
                (
                  showTopupPrice ? (
                    <div className={styles.bullets}>
                      <span className={styles.mediumText}><Icon name={'package'} className={styles.creditsPeryearIcon} /> Get {plan.credits} credits/year</span>
                      <span className={styles.mediumText}>
                        <Icon name={'percent'} className={classnames(styles.creditsPeryearIcon, isSelected(plan.id, ProductTypes.MEMBERSHIPS) && styles.selectedOption)} /> Save up to {Math.round((1 - (plan.credits / BASE_PRICE)) * 100)}% ({plan.credits} each)</span>
                    </div>
                  ) : (
                    <span className={styles.mediumText}><Icon name={'package'} className={styles.creditsPeryearIcon} /> Get {plan.credits} credits/year</span>
                  )
                )
              }
            </div>
            {/* <div className={styles.row}>
              <span className={styles.smallText}>
                Top-up credit: ${plan.topup_price}
                <Icon name={'question-mark'} className={styles.icon} data-tip={plan.topup_info} />
              </span>
            </div> */}
          </div>
        )}
        { orchestraProducts?.length > 0 && (
          <div className={styles.sectionTitle}>Other Products</div>
        )}
        {orchestraProducts?.map(plan =>
          <div
            key={plan.id}
            className={classnames(styles.plan, isSelected(plan.id, ProductTypes.ORCHESTRAS) && styles.selectedPlan)}
            data-cy={'plan-price-tile'}
            onClick={onPlanSelect(plan, ProductTypes.ORCHESTRAS)}
          >
            <div className={styles.row}>
              <Icon name={isSelected(plan.id, ProductTypes.ORCHESTRAS) ? 'radio-selected' : 'radio-unselected'} className={styles.bulletIcon} />
              <span className={classnames(styles.bigText, isSelected(plan.id, ProductTypes.ORCHESTRAS) && styles.highlight)}>
                {plan.title}
              </span>
              {/* <span
                className={classnames(styles.bigText, styles.bigTextGray)}>${numberWithCommas(plan.price)}/Year
              </span> */}
              </div>
            <div className={classnames(styles.row, styles.mediumRow)}>
              <div className={styles.bullets}>
                {plan?.bullets?.map((b, index) => (
                  <span key={index} className={styles.mediumText}>
                    <Icon name={b.icon ?? StrapiIcons.CHECKMARK} className={styles.creditsPeryearIcon} />{b.bullet}
                  </span>
                ))}
              </div>
            </div>
            {/* <div className={styles.row}>
              <span className={styles.smallText}>
                Top-up credit: ${plan.topup_price}
                <Icon name={'question-mark'} className={styles.icon} data-tip={plan.topup_info} />
              </span>
            </div> */}
          </div>
        )}
      </div>
      {/* {selectedPlan && <ul className={styles.bullets}>
        {selectedPlan.pricingBullet?.map(({ bullet, id }) => <li key={id}>{bullet}</li>)}
      </ul>} */}
      <div className={styles.buttonFooter}>
        <button
          onClick={onCTAButtonClick}
          className={classnames(
            styles.plans__planButton,
            [PlansCtaTypeEnum.CURRENT, PlansCtaTypeEnum.UNABLE].includes(ctaType) && styles.isCurrent
          )}
        >
          {[PlansCtaTypeEnum.CURRENT, PlansCtaTypeEnum.UNABLE].includes(ctaType)
            ? ctaType
            : (
                [ProductTypes.ORCHESTRAS, ProductTypes.BUNDLES].includes(selectedProductType) ?
                 `$${selectedPlan?.price}` :
                 `${buttonTextByctaType(selectedPlan?.price)}`
              )
            }
        </button>
        <div className={styles.totalAmountMessage}>
          { selectedProductType === ProductTypes.MEMBERSHIPS ? (
            `Billed Yearly ($${selectedPlan?.price} Excl. VAT)`
          ) : (
            ''
          )
        }
        </div>
      </div>
    </div>
  );
};

export default PlansSelector;
