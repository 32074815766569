import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import Icon from '../Icon/Icon';
import { timeParserHelper } from '../../../helpers/time-parser.helper';
import {
  setIsUpsellPlansModal
} from '../../../store/slices/modals.slice';

import styles from './FileUploadsWarning.module.scss';

const LEARN_MORE_URL = 'https://help.musiversal.com/submitting-your-pre-session-materials';

interface FileUploadsWarningInterface {
  filesDeadline?: string;
  hasLimitations: boolean;
  warningHeader: string;
  warningBody: string;
  icon: string;
}

const FileUploadsWarning = ({ filesDeadline, hasLimitations = false, icon = 'warning', warningHeader = '', warningBody = '' }: FileUploadsWarningInterface): ReactElement => {
  const dispatch = useDispatch();

  const openUpsellModal = (): void => {
    dispatch(setIsUpsellPlansModal(true));
  };

  return hasLimitations ? (
    <div className={styles.contentWithIcon}>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <Icon name={icon} />
        </div>
        <div className={styles.message}>
          <div className={styles.headerText}>
            {warningHeader}
          </div>
          <div className={styles.body}>
            <p>
              {warningBody} <a onClick={openUpsellModal}>Learn more</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.content}>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <Icon name='warning' />
        </div>
        <p>
          You must upload your files {timeParserHelper(filesDeadline)} prior to the session. <a href={LEARN_MORE_URL} target='_blank' rel='noreferrer'>Learn more</a>
        </p>
      </div>
    </div>
  );
};
export default FileUploadsWarning;
