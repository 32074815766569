import classnames from 'classnames';
import { groupBy } from 'lodash';
import React, { MouseEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { CustomerSessionDetailsTabs } from '../../../enums/customer-session-details-tabs.enum';
import { PartnerSessionDetailsTabs } from '../../../enums/partner-session-details-tabs.enum';
import { UploadStatus } from '../../../enums/upload-status.enum';
import { progressCounter } from '../../../helpers/file-upload-progress-counter';
import { formatBytes } from '../../../helpers/format-bytes';
import { SingleUploadInterface, UploadCardInterface } from '../../../interfaces/upload-card.interface';
import { selectIsPartnersDashboard } from '../../../store/slices/global.slice';
import { removeUpload } from '../../../store/slices/uploads.slice';
import { RootState } from '../../../store/store';
import Icon from '../../Atoms/Icon/Icon';
import Spinner from '../../Atoms/Spinner/Spinner';
import styles from './parallel-upload.module.scss';

const UploadCard = ({
  progress,
  total,
  loaded,
  sessionId,
  filesCount,
  completedFilesCount,
  isCompleted,
  hasFailure,
  isUploading,
  uploadIds
}: UploadCardInterface): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard);

  const goToSessionDetails = (): void => {
    const tab = isPartnersDashboard
      ? PartnerSessionDetailsTabs.FILES_MANAGEMENT
      : CustomerSessionDetailsTabs.FILES_MANAGEMENT;
    history.push(`/sessions/${sessionId}/${tab}`);
  };

  const onCloseClick = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    uploadIds.forEach(id => dispatch(removeUpload(id)));
  };

  const header = (): ReactElement => {
    if (isCompleted) {
      return <p>Uploaded files for session #{sessionId}</p>;
    }
    if (hasFailure) {
      return <p>Error uploading files for session #{sessionId}</p>;
    }
    return <p>Uploading files for session #{sessionId}</p>;
  };

  const content = (): ReactElement => {
    if (isCompleted) {
      return (
        <>
          <span>Upload completed</span>
          <span>
            {completedFilesCount} out of {filesCount} uploaded ({progress}%)
          </span>
        </>
      );
    }
    if (hasFailure) {
      return (
        <>
          <span>Some of files failed to upload</span>
          <span>
            {completedFilesCount} out of {filesCount} uploaded ({progress}%)
          </span>
        </>
      );
    }
    return (
      <>
        <span>
          {completedFilesCount} out of {filesCount} uploaded ({progress}%)
        </span>
        <span>
          {formatBytes(loaded)} out of {formatBytes(total)}
        </span>
      </>
    );
  };

  return (
    <div
      onClick={goToSessionDetails}
      className={classnames(styles.uploadCard, isCompleted && styles.isReadyToGo, hasFailure && styles.isFailed)}
    >
      {!isUploading && (
        <div className={styles.closeButton} onClick={onCloseClick} data-tip={'Close'}>
          <Icon name='c-close' />
        </div>
      )}
      <div className={styles.content}>
        {header()}
        {content()}
      </div>
      {isUploading && <Spinner className={styles.spinner} isBlue />}
      {hasFailure && <Icon className={styles.icon} name={'error'} />}
      {isCompleted && <Icon className={styles.tick} name={'green-single-tick'} />}
      <ReactTooltip place='left' delayHide={1000} multiline textColor='#FFF' backgroundColor='#4B5563' effect='solid' />
    </div>
  );
};

const ParallelUpload = (): ReactElement => {
  const { uploads } = useSelector((state: RootState) => state.uploadsSlice, shallowEqual);
  const [isHidden, setIsHidden] = useState(false);

  const sessionUploads = useMemo((): UploadCardInterface[] => {
    const groupedUploads = Object.values(groupBy(uploads, 'sessionId'));
    const mappedCards = groupedUploads.map(
      group =>
        group.map(({ id, status, sessionId, total, loaded, uploadTimestamp }) => ({
          id,
          sessionId,
          status,
          total,
          loaded,
          progress: progressCounter(loaded, total),
          filesCount: 1,
          completedFilesCount: status === UploadStatus.COMPLETED ? 1 : 0,
          isCompleted: group.every(item => item.status === UploadStatus.COMPLETED),
          hasFailure: group.some(item => item.status === UploadStatus.FAILED),
          isUploading: group.some(item => item.status === UploadStatus.UPLOADING),
          uploadTimestamp
        })) as SingleUploadInterface[]
    );

    const reducedCards = mappedCards.map(items => {
      const uploadIds = items.map(item => item.id);
      const card = items.reduce((a, b) => ({
        id: a.id,
        sessionId: a.sessionId,
        total: a.total + b.total,
        loaded: a.loaded + b.loaded,
        filesCount: a.filesCount + b.filesCount,
        completedFilesCount: a.completedFilesCount + b.completedFilesCount,
        isCompleted: a.isCompleted,
        hasFailure: a.hasFailure,
        isUploading: a.isUploading,
        uploadTimestamp: b.uploadTimestamp
      }));
      return { ...card, uploadIds };
    });

    return reducedCards
      .map(card => ({
        ...card,
        progress: progressCounter(card.loaded, card.total)
      }))
      .sort((a, b) => (a.uploadTimestamp < b.uploadTimestamp ? -1 : 1));
  }, [uploads]);

  const globalProgress = useMemo((): UploadStatus | null => {
    if (!sessionUploads.length) {
      return null;
    }
    if (sessionUploads.every(upload => upload.isCompleted)) {
      return UploadStatus.COMPLETED;
    }
    if (sessionUploads.some(upload => upload.isUploading)) {
      return UploadStatus.UPLOADING;
    }
    if (sessionUploads.some(upload => upload.hasFailure)) {
      return UploadStatus.FAILED;
    }
  }, [sessionUploads]);

  const toggleIsHidden = (): void => setIsHidden(!isHidden);

  const renderBubbleContent = (): ReactElement => {
    switch (globalProgress) {
      case UploadStatus.COMPLETED:
        return <Icon className={styles.tick} name={'green-single-tick'} />;
      case UploadStatus.FAILED:
        return <Icon name={'error'} />;
      default:
        return <Spinner className={styles.spinner} isBlue />;
    }
  };

  useEffect((): void => {
    window.onbeforeunload = (): string =>
      globalProgress === UploadStatus.UPLOADING ? 'Some of the files are still uploading. Do you wish to leave?' : null;
  }, [globalProgress]);

  return (
    <>
      <div className={styles.overlay}>
        {isHidden ? (
          <div
            className={classnames(
              styles.bubble,
              globalProgress === UploadStatus.FAILED && styles.isFailed,
              globalProgress === UploadStatus.COMPLETED && styles.isReadyToGo
            )}
            data-tip={'Click to expand'}
            onClick={toggleIsHidden}
          >
            {renderBubbleContent()}
          </div>
        ) : (
          <div className={styles.container}>
            {globalProgress && (
              <span className={styles.hideButton} onClick={toggleIsHidden}>
                Minimize
              </span>
            )}
            {sessionUploads.map(upload => (
              <UploadCard key={upload.id} {...upload} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ParallelUpload;
