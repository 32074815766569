import React from 'react';
import ReactModal from 'react-modal';

const modalStyles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  content: {
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'relative',
    margin: '0 auto',
    background: '#F9FAFB',
    padding: '48px 0',
    textAlign: 'center',
    boxShadow:
      '0px 8px 24px rgba(0, 0, 0, 0.08), 0px 4px 18px rgba(0, 0, 0, .08), 0px 2px 16px -16px rgba(0, 0, 0, .08)',
    overflow: 'initial',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '16px',
    outline: 'none'
  }
};

const BillingModal =({ isOpen, onRequestClose, children }) =>
  <ReactModal
    appElement={document.getElementById('root')}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={modalStyles}
  >
    {children}
  </ReactModal>;

export default BillingModal;
