import moment, { Moment } from 'moment-timezone';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { InvoiceDataFields } from '../../../../enums/invoice-data-fields.enum';
import { getLabelValueObjectHelper } from '../../../../helpers/get-label-value-object.helper';
import { InvoiceDataInterface } from '../../../../interfaces/invoice-data.interface';
import MusiciansService from '../../../../services/musicians/musicians.service';
import { setIsShowGlobalLoader } from '../../../../store/slices/global.slice';
import { selectUser } from '../../../../store/slices/user.slice';
import Button from '../../../Atoms/Button/Button';
import CustomSelect from '../../../Atoms/Select/Select';
import styles from './Invoice.module.scss';

const months = moment.months().map((month, idx) => ({ label: month, value: (idx + 1).toString().padStart(2, '0') }));

const yearRange = (start: number, stop: number): number[] =>
  Array.from({ length: (stop - start) / -1 + 1 }, (_, i) => start + i * -1);

const BOTTOM_YEAR = 2020;

const Invoice = (): ReactElement => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const now = useMemo((): Moment => moment.tz(user.timezone), [user.timezone]);
  const years = useMemo(
    (): { value: string; label: string }[] =>
      yearRange(Number(now.format('YYYY')), BOTTOM_YEAR).map(year => ({
        value: year.toString(),
        label: year.toString()
      })),
    [now]
  );

  const [invoiceData, setInvoiceData] = useState<InvoiceDataInterface>(null);

  const monthNow = useMemo((): string => now.format('MM'), [now]);
  const yearNow = useMemo((): string => now.format('YYYY'), [now]);

  const { control, getValues, handleSubmit } = useForm({
    defaultValues: {
      month: { value: monthNow, label: getLabelValueObjectHelper(monthNow, months).label },
      year: { value: yearNow, label: yearNow }
    }
  });

  const assignInvoiceData = useCallback(
    async ({ month, year }): Promise<void> => {
      dispatch(setIsShowGlobalLoader(true));
      try {
        const { data } = await MusiciansService.getMusicianStats({ month, year });
        setInvoiceData(data);
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(setIsShowGlobalLoader(false));
      }
    },
    [dispatch]
  );

  useEffect((): void => {
    assignInvoiceData({ month: monthNow, year: yearNow }).finally();
  }, [monthNow, yearNow, assignInvoiceData]);

  const onSubmit = async data => {
    const {
      month: { value: monthValue },
      year: { value: yearValue }
    } = data;
    await assignInvoiceData({ month: monthValue, year: yearValue });
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomSelect
          control={control}
          label={'Month'}
          name={'month'}
          options={months}
          containerClassName={styles.selectContainer}
        />
        <CustomSelect
          control={control}
          label={'Year'}
          name={'year'}
          options={years}
          containerClassName={styles.selectContainer}
        />
        <Button type='submit' value='Get information' />
      </form>
      {invoiceData && (
        <div className={styles.group}>
          <h1 className={styles.title}>
            Invoice information - {getValues().month?.value}/{getValues().year?.value}
          </h1>
          {Object.keys(invoiceData).map(key => (
            <div key={key} className={styles.item}>
              <span>{InvoiceDataFields[key]}:</span>
              <span>{invoiceData[key]}</span>
            </div>
          ))}
        </div>
      )}
      <span className={styles.email}>
        If you have questions about the payment, email <a href='mailto:joao@musiversal.com'>joao@musiversal.com</a>
      </span>
    </div>
  );
};

export default Invoice;
