import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store/store';

import { SlicesNames } from './slices-names.enum';

type SliceState = {
  isShowSidePanel: boolean;
  isFullSessionLoaded: boolean;
};

export const sidePanel = createSlice({
  name: SlicesNames.SIDE_PANEL_SLICE,
  initialState: {
    isShowSidePanel: false,
    isFullSessionLoaded: false
  },
  reducers: {
    setIsShowSidePanel: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isShowSidePanel = payload;
    },
    setIsFullSessionLoaded: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isFullSessionLoaded = payload;
    }
  }
});

export const {
  setIsShowSidePanel,
  setIsFullSessionLoaded
} = sidePanel.actions;

export default sidePanel.reducer;
