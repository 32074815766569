import React, { ReactElement } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { UserStatuses } from '../../../../../enums/user-statuses.enum';
import {
  selectOnboardingModalData,
  setIsShowHowItWorksModal,
  setIsShowOnboardingModal
} from '../../../../../store/slices/modals.slice';
import { selectUser } from '../../../../../store/slices/user.slice';
import Button from '../../../../Atoms/Button/Button';
import Icon from '../../../../Atoms/Icon/Icon';
import styles from './ThankYouStep.module.scss';

const ThankYouStep = (): ReactElement => {
  const dispatch = useDispatch();
  const { status } = useSelector(selectUser, shallowEqual);
  const {
    finalStep: { title, body, buttonLabel }
  } = useSelector(selectOnboardingModalData, shallowEqual);

  const onButtonClick = (): void => {
    dispatch(setIsShowOnboardingModal(false));
    dispatch(setIsShowHowItWorksModal(true));
  };

  return (
    <>
      <div className={styles.icon}>
        <Icon name='greenCircleTick' />
      </div>
      <p className={styles.title}>{title}</p>
      <span className={styles.subtitle}>{body}</span>
      {status === UserStatuses.TRIAL && <Button onClick={onButtonClick} value={buttonLabel} />}
    </>
  );
};

export default ThankYouStep;
