import React, { ReactElement, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import GlobalLoader from '../../components/Atoms/GlobalLoader/global-loader';
import ModalsContainer from '../../components/Molecules/ModalsContainer/ModalsContainer';
import ParallelUpload from '../../components/Molecules/ParallelUpload/parallel-upload';
import StudioBanner from '../../components/Molecules/StudioBanner/StudioBanner';
import { Routes } from '../../enums/routes.enum';
import { UserStatuses } from '../../enums/user-statuses.enum';
import { getReferralData } from '../../store/actions/referral.actions';
import { selectIsPartnersDashboard } from '../../store/slices/global.slice';
import { setIsShowOnboardingModal, setIsShowTimezoneModal } from '../../store/slices/modals.slice';
import { selectReferralData } from '../../store/slices/referral.slice';
import { selectUser } from '../../store/slices/user.slice';
import Explore from '../main/components/explore/Explore';
import Profile from './components/profile/Profile';
import Sessions from './components/sessions/Sessions';

const Main = (): ReactElement => {
  const user = useSelector(selectUser, shallowEqual);
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);
  const referralData = useSelector(selectReferralData, shallowEqual);
  const dispatch = useDispatch();

  useEffect((): void => {
    if (user.status === UserStatuses.SIGNUP) {
      return;
    }
    if (!referralData) {
      dispatch(getReferralData());
    }
    if (!user.isOnboardingCompleted && !isPartnersDashboard) {
      dispatch(setIsShowOnboardingModal(true));
      return;
    }
    if (!user.timezone) {
      dispatch(setIsShowTimezoneModal(true));
    }
  }, [user, dispatch, isPartnersDashboard]);

  return (
    <>
      {user.status === UserStatuses.SIGNUP ? (
        <Redirect to='/payment-details' />
      ) : (
        <>
          <StudioBanner />
          <Switch>
            <Route path={Routes.PROFILE} component={Profile} />
            <Route path={Routes.REFERRAL} component={Profile} />
            <Route path='/sessions/:session_id/:tab' component={Sessions} />
            <Route path='/sessions/:session_id/edit' component={Sessions} />
            <Route path='/sessions/:session_id' component={Sessions} />
            <Route path='/sessions' component={Sessions} />
            <Route path='/offers' component={Explore} />
            <Route path='/explore/:slug' component={Explore} />
            <Route path='/explore' component={Explore} />
            <Route render={(): ReactElement => <Redirect to='/explore' />} />
          </Switch>
          <GlobalLoader />
          <ParallelUpload />
          <ModalsContainer />
        </>
      )}
    </>
  );
};

export default Main;
