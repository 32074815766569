import { Elements } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Icon from '../../components/Atoms/Icon/Icon';
import SignUpFlowNavbar from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar';
import { MixPanelSignUpEvents } from '../../enums/mixpanel-events.enum';
import { SignupSteps } from '../../enums/signup-steps.enum';
import { Mixpanel } from '../../helpers/mixpanel.helper';
import {
  blackFridayPromoText,
  changePlanText,
  freeTrialText,
  paymentDetailsHeader,
  planSelectionHeader,
  planSelectionHeaderSales,
  selectedPlanText,
  sessionsPerMonth,
  sessionsPerYear
} from './payment-details.const';
import styles from './PaymentDetails.module.scss';
import CheckoutForm from './checkout-form/CheckoutForm';
import useGetPaymentDetailsState from './useGetPaymentDetailsState';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedPlan, selectSelectedProduct, selectSelectedProductType, setSelectedPlan, setSelectedProductType, setSelectedProduct } from '../../store/slices/global.slice';
import { ProductTypes } from '../../enums/product-types.enum';
import AuthService from '../../services/auth/auth.service';
import PricingPlans from './pricing-plans/pricing-plans';
import OndemandRow from './ondemand-row/ondemand-row';
import ProductOptions from './product-options/product-options';

const PaymentDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedPlan = useSelector(selectSelectedPlan);
  const selectedProduct = useSelector(selectSelectedProduct);
  const selectedProductType = useSelector(selectSelectedProductType);
  const [isPlanSelection, setIsPlanSelection] = useState(true);
  const [stripePromise, setStripePromise] = useState(null);
  const { blackFridaySale, annualPlansDefault, studioTrialsOnSignup } = useFlags();
  const { user, plans } = useGetPaymentDetailsState({ setStripePromise, isAnnualPlansDefault: annualPlansDefault });
  const showPlansVersion2 = true;

  const onPlanChangeClick = () => {
    Mixpanel.track(MixPanelSignUpEvents.CLICKED_CHANGE_PLAN);
    setIsPlanSelection(true);
  };

  useEffect(() => {
    const highlightedPlan = plans?.memberships?.find(({ highlighted }) => highlighted) || plans?.memberships?.[0];
    dispatch(setSelectedPlan(highlightedPlan));
    dispatch(setSelectedProduct(highlightedPlan));
    dispatch(setSelectedProductType(ProductTypes.MEMBERSHIPS));
  }, [plans, dispatch]);

  const onOndemandSignup = async (): void => {
    await AuthService.ondemandSignup();
    const redirectRoute = '/thank-you';
    history.push(redirectRoute);
    // Mixpanel.track(MixPanelSignUpEvents.CHANGED_PLAN, {
    //   [MixPanelChangedPlanProperties.PLAN]: plan.title
    // });
  };

  return (
      <div className={styles.container}>
        <SignUpFlowNavbar signUpStep={isPlanSelection ? SignupSteps.CHOOSE_A_PLAN : SignupSteps.PAYMENT_DETAILS} />
        <div className={styles.leftImage}>
          <div className={styles.bg}/>
        </div>
        <div className={styles.rightImage}>
          <div className={styles.bg}/>
        </div>
        { !showPlansVersion2 ? (
          <div className={styles.content}>
            <div hidden={!isPlanSelection}>
              <h1>{planSelectionHeader}</h1>
              {!!plans?.memberships?.length && <PricingPlans plans={plans.memberships} setIsPlanSelection={setIsPlanSelection} />}
              <div className={styles.ondemand}>
                <OndemandRow />
              </div>
            </div>
            {selectedPlan && <div hidden={isPlanSelection}>
              <h1>{paymentDetailsHeader}</h1>
              <div className={styles.planInfo}>
                <div className={styles.topBar}>
                  <span className={styles.selectedPlan}>{selectedPlanText}</span>
                  <span className={styles.changePlan} onClick={onPlanChangeClick}>
                    {changePlanText}
                  </span>
                </div>
                <div className={styles.planContent}>
                  <div className={styles.logo}>
                    <Icon name={'logo'} />
                  </div>
                  <div className={styles.descriptions}>
                    <h4>
                      <span>{selectedPlan.title}</span>{studioTrialsOnSignup && ` - ${freeTrialText}`}
                    </h4>
                    <p>
                      ${selectedPlan.price} - {selectedPlan.credits}{' '}
                      {selectedPlan.isAnnual ? sessionsPerYear : sessionsPerMonth}
                      {/* <strong>{selectedPlan.isAnnual && blackFridaySale && blackFridayPromoText}</strong> */}
                    </p>
                  </div>
                </div>
              </div>
              {stripePromise && (
                <Elements stripe={stripePromise}>
                  <CheckoutForm user={user} isPlanSelection={isPlanSelection} />
                </Elements>
              )}
            </div>}
          </div>
        ) : (
          <div className={isPlanSelection ? styles.blackFridaycontent : styles.content}>
            <div hidden={!isPlanSelection} className={styles.wrapper}>
              <div className={styles.mainTitle}>
                <div className={styles.blackFridayHeader}>{blackFridaySale ? planSelectionHeaderSales : planSelectionHeader }</div>
                {/* <div className={styles.subheaderTitle}>Select a plan</div> */}
                <div className={styles.ondemandParagraph}>
                  <div className={styles.row}>
                    <span className={styles.highlighted}>Not ready to commit?</span><a className={styles.freeplanLink} onClick={onOndemandSignup}> Try our free plan</a>
                  </div>
                </div>
              </div>
              <div className={styles.allPlansContainer}>
                <ProductOptions products={plans} setIsPlanSelection={setIsPlanSelection} />
              </div>
            </div>
            {/* <div hidden={!isPlanSelection}>
              <h1>{planSelectionHeader}</h1>
              {!!plans?.length && <PricingPlans plans={plans} setIsPlanSelection={setIsPlanSelection} />}
              <div className={styles.ondemand}>
                <OndemandRow />
              </div>
            </div> */}
            {selectedPlan && <div hidden={isPlanSelection}>
              <h1>{paymentDetailsHeader}</h1>
              { selectedProductType === ProductTypes.MEMBERSHIPS && (
                <div className={styles.planInfo}>
                  <div className={styles.topBar}>
                    <span className={styles.selectedPlan}>{selectedPlanText}</span>
                    <span className={styles.changePlan} onClick={onPlanChangeClick}>
                      {changePlanText}
                    </span>
                  </div>
                  <div className={styles.planContent}>
                    <div className={styles.logo}>
                      <Icon name={'logo'} />
                    </div>
                    <div className={styles.descriptions}>
                      <h4>
                        <span>{selectedPlan.title}</span>{studioTrialsOnSignup && ` - ${freeTrialText}`}
                      </h4>
                      <p>
                        ${selectedPlan.price} - {selectedPlan.credits}{' '}
                        {selectedPlan.isAnnual ? sessionsPerYear : sessionsPerMonth}
                        {/* <strong>{selectedPlan.isAnnual && blackFridaySale && blackFridayPromoText}</strong> */}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              { selectedProductType === ProductTypes.BUNDLES && (
                <div className={styles.planInfo}>
                  <div className={styles.topBar}>
                    <span className={styles.selectedPlan}>{selectedPlanText}</span>
                    <span className={styles.changePlan} onClick={onPlanChangeClick}>
                      {changePlanText}
                    </span>
                  </div>
                  <div className={styles.planContent}>
                    <div className={styles.logo}>
                      <Icon name={'logo'} />
                    </div>
                    <div className={styles.descriptions}>
                      <h4>
                        <span>{selectedProduct.title}</span>
                      </h4>
                      <p>
                        ${selectedProduct.price} - {selectedProduct.credits}{' credits'}
                        {/* <strong>{selectedPlan.isAnnual && blackFridaySale && blackFridayPromoText}</strong> */}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {stripePromise && (
                <Elements stripe={stripePromise}>
                  <CheckoutForm user={user} isPlanSelection={isPlanSelection} selectedProduct={selectedProduct} selectedProductType={selectedProductType} />
                </Elements>
              )}
            </div>}
          </div>
        )}
      </div>
  );
};

export default PaymentDetails;
