import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ModalInterface } from '../../../interfaces/modal.interface';
import { setIsShowHowItWorksModal } from '../../../store/slices/modals.slice';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import BookACallStep from './BookACallStep/BookACallStep';
import styles from './HowItWorks.module.scss';
import HowItWorksStep from './HowItWorksStep/HowItWorksStep';

export enum HowItWorksSteps {
  HOW_IT_WORKS = 'HOW_IT_WORKS',
  HELP = 'HELP'
}

const HowItWorksModal = ({ isOpen }: ModalInterface): ReactElement => {
  const [onboardingStep, setOnboardingStep] = useState(HowItWorksSteps.HOW_IT_WORKS);
  const dispatch = useDispatch();

  const onRequestClose = (): void => {
    setOnboardingStep(HowItWorksSteps.HOW_IT_WORKS);
    dispatch(setIsShowHowItWorksModal(false));
  };

  return (
    <ModalWrapper isOpen={isOpen} className={styles.modal} onRequestClose={onRequestClose}>
      {onboardingStep === HowItWorksSteps.HOW_IT_WORKS && <HowItWorksStep setOnboardingStep={setOnboardingStep} />}
      {onboardingStep === HowItWorksSteps.HELP && (
        <BookACallStep setOnboardingStep={setOnboardingStep} onRequestClose={onRequestClose} />
      )}
    </ModalWrapper>
  );
};

export default HowItWorksModal;
