export enum Cohorts {
  HAS_EXCLUSIVE_PRICES = 'hasExclusivePrices',
  IS_PRE_PIVOT = 'isPrePivot'
}

export enum UserCohorts {
  PUBLIC = 'public',
  MEMBER = 'member',
  LEGACY = 'legacy',
  ONDEMAND = 'ondemand',
  CANCELLED = 'cancelled'
}