import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store/store';

import { SessionFilesFolder } from '../../enums/session-files-folder.enum';
import { OffboardingModalInterface } from '../../interfaces/offboarding-modal.interface';
import { OnboardingModalInterface } from '../../interfaces/onboarding-modal.interface';
import { getOffboardingModalData, getOnboardingModalData } from '../actions/modals.actions';
import { SlicesNames } from './slices-names.enum';

type SliceState = {
  isShowSpeedLimitModal: boolean;
  isShowBillingModal: boolean;
  isShowTimezoneModal: boolean;
  isShowOffboardingModal: boolean;
  isShowOnboardingModal: boolean;
  showFilesModal: { isShown: boolean; folder?: any };
  offboardingModalData: OffboardingModalInterface;
  onboardingModalData: OnboardingModalInterface;
  isShowBlockingModal: boolean;
  isShowHowItWorksModal: boolean;
  isShowServiceUpdateModal: boolean;
  isShowBillingAddressModal: boolean;
  isShowRatingModal: { isShown: boolean; sessionId?: any };
  isShowTopUpModal: { isShown: boolean; warningType?: any; message?: any; artistSlug?: any; initialAmount: number };
  isShowCancelSessionModal: { isShown: boolean; cancellationRecreditLimit?: any };
  isShowPlansModal: boolean;
  isUpsellPlansModal: boolean;
};

export const modals = createSlice({
  name: SlicesNames.MODALS_SLICE,
  initialState: {
    isShowSpeedLimitModal: false,
    isShowBillingModal: false,
    isShowTimezoneModal: false,
    isShowOffboardingModal: false,
    isShowOnboardingModal: false,
    showFilesModal: { isShown: false, folder: null },
    offboardingModalData: null,
    onboardingModalData: null,
    isShowBlockingModal: false,
    isShowHowItWorksModal: false,
    isShowServiceUpdateModal: false,
    isShowBillingAddressModal: false,
    isShowRatingModal: { isShown: false, sessionId: null },
    isShowTopUpModal: { isShown: false, warningType: null, message: '', artistSlug: '', initialAmount: 1 },
    isShowCancelSessionModal: { isShown: false, cancellationRecreditLimit: 48 },
    isShowPlansModal: false,
    isUpsellPlansModal: false
  },
  reducers: {
    setIsShowSpeedLimitModal: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isShowSpeedLimitModal = payload;
    },
    setIsShowBillingModal: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isShowBillingModal = payload;
    },
    setIsShowTimezoneModal: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isShowTimezoneModal = payload;
    },
    setIsShowOffboardingModal: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isShowOffboardingModal = payload;
    },
    setIsShowOnboardingModal: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isShowOnboardingModal = payload;
    },
    setShowFilesModal: (
      state: SliceState,
      { payload }: PayloadAction<{ isShown: boolean; folder?: SessionFilesFolder }>
    ): void => {
      state.showFilesModal = payload;
    },
    setIsShowBlockingModal: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isShowBlockingModal = payload;
    },
    setIsShowHowItWorksModal: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isShowHowItWorksModal = payload;
    },
    setIsShowServiceUpdateModal: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isShowServiceUpdateModal = payload;
    },
    setIsShowBillingAddressModal: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isShowBillingAddressModal = payload;
    },
    setIsShowRatingModal: (state: SliceState, { payload }: PayloadAction<{ isShown: boolean; sessionId?: any }>): void => {
      state.isShowRatingModal = payload;
    },
    setIsShowTopUpModal: (
      state: SliceState,
      { payload }: PayloadAction<{ isShown: boolean; warningType?: any; message?: any, artistSlug?: any; initialAmount: number }>
      ): void => {
      state.isShowTopUpModal = payload;
    },
    setIsShowCancelSessionModal: (
      state: SliceState,
      { payload }: PayloadAction<{ isShown: boolean; cancellationRecreditLimit: any }>
    ): void => {
      state.isShowCancelSessionModal = payload;
    },
    setIsShowPlansModal: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isShowPlansModal = payload;
    },
    setIsUpsellPlansModal: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isUpsellPlansModal = payload;
    }
  },
  extraReducers: {
    [getOffboardingModalData.fulfilled.type]: (
      state: SliceState,
      { payload }: PayloadAction<OffboardingModalInterface>
    ): void => {
      state.offboardingModalData = payload;
    },
    [getOnboardingModalData.fulfilled.type]: (
      state: SliceState,
      { payload }: PayloadAction<OnboardingModalInterface>
    ): void => {
      state.onboardingModalData = payload;
    }
  }
});

export const {
  setIsShowSpeedLimitModal,
  setIsShowBillingModal,
  setIsShowTimezoneModal,
  setIsShowOffboardingModal,
  setIsShowOnboardingModal,
  setShowFilesModal,
  setIsShowBlockingModal,
  setIsShowHowItWorksModal,
  setIsShowServiceUpdateModal,
  setIsShowBillingAddressModal,
  setIsShowRatingModal,
  setIsShowTopUpModal,
  setIsShowCancelSessionModal,
  setIsShowPlansModal,
  setIsUpsellPlansModal
} = modals.actions;

export const selectCancellationCreditsCancellationModal = (state: RootState) => state.modalsSlice.isShowCancelSessionModal;
export const selectTopupsModal = (state: RootState) => state.modalsSlice.isShowTopUpModal;
export const selectRatingModal = (state: RootState) => state.modalsSlice.isShowRatingModal;
export const selectShowFilesModal = (state: RootState) => state.modalsSlice.showFilesModal;
export const selectOffboardingModalData = (state: RootState) => state.modalsSlice.offboardingModalData;
export const selectOnboardingModalData = (state: RootState) => state.modalsSlice.onboardingModalData;

export default modals.reducer;
