import classNames from 'classnames';
import React, { ReactElement } from 'react';

import Icon from '../Icon/Icon';
import styles from './BackButton.module.scss';

interface BackButtonInterface {
  onClick: () => void;
  className?: string;
}

const BackButton = ({ onClick, className }: BackButtonInterface): ReactElement => (
  <button onClick={onClick} className={classNames(styles.button, className)}>
    <Icon name={'backArrow'} />
    Back
  </button>
);

export default BackButton;
