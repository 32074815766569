import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PricingV4DataInterface, OnDemandLimitationsInterface } from '../../interfaces/pricing-data.interface';
import { ServiceInterface } from '../../interfaces/service.interface';
import {
  getLimitations
} from '../actions/limitations.actions';
import { RootState } from '../store';
import { SlicesNames } from './slices-names.enum';

type SliceState = {
  ondemandLimitations: OnDemandLimitationsInterface;
};

export const limitations = createSlice({
  name: SlicesNames.LIMITATIONS_SLICE,
  initialState: {
    ondemandLimitations: null
  },
  reducers: {},
  extraReducers: {
    [getLimitations.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<any>): void => {
      state.ondemandLimitations = payload;
    }
  }
});

export const selectLimitations = (state: RootState) =>
  state.limitationsSlice.ondemandLimitations;

export default limitations.reducer;
