import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Dispatch, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { UserStatuses } from '../../enums/user-statuses.enum';
import useIsPartnerGuard from '../../guards/useIsPartnerDashboard.guard';
import { UserInterface } from '../../interfaces/user.interface';
import { getPlans } from '../../store/actions/global.actions';
import { setSelectedPlan } from '../../store/slices/global.slice';
import { RootState } from '../../store/store';
import { getPlanIdxWithCohort } from './pricing-slider/pricing-slider';
import { PricingPlanInterface } from './pricing-slider/pricing-slider.interface';

interface PropsInterface {
  setStripePromise: Dispatch<Promise<Stripe | null>>;
  isAnnualPlansDefault: boolean;
}

interface ReturnInterface {
  user: UserInterface;
  plans: PricingPlanInterface[];
}

const useGetPaymentDetailsState = ({ setStripePromise, isAnnualPlansDefault }: PropsInterface): ReturnInterface => {
  useIsPartnerGuard();

  const { user, plansByPeriod, plans } = useSelector(
    (state: RootState) => ({
      user: state.userSlice.user,
      plansByPeriod: state.globalSlice.plansByPeriod,
      plans: state.globalSlice.plans
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!process.env.REACT_APP_STRIPE_ID) {
      return;
    }
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_ID, { locale: 'en' }));
    const getData = async () => {
      await dispatch(getPlans(user));
    };
    getData().finally();
  }, [dispatch, setStripePromise]);

  useEffect(() => {
    if (plansByPeriod.length) {
      dispatch(setSelectedPlan(plansByPeriod[getPlanIdxWithCohort(plansByPeriod, user, isAnnualPlansDefault)]));
    }
  }, [plansByPeriod, dispatch, user, isAnnualPlansDefault]);

  useEffect(() => {
    if (user.status !== UserStatuses.SIGNUP) {
      history.push('/profile#billing');
    }
    // eslint-disable-next-line
  }, [user.status]);

  return { user, plans };
};

export default useGetPaymentDetailsState;
