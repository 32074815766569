import { ProductTypes } from '../../../enums/product-types.enum';

export const sectionTitles = {
  [ProductTypes.BUNDLES]: 'Musiversal Studio',
  [ProductTypes.MEMBERSHIPS]: '',
  [ProductTypes.ORCHESTRAS]: 'Other offers'
};

export const KEYS_TO_SHOW = [
  ProductTypes.BUNDLES,
  ProductTypes.MEMBERSHIPS,
  ProductTypes.ORCHESTRAS
];