import React, { ReactElement } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { UserStatuses } from '../../../../../enums/user-statuses.enum';
import { selectOnboardingModalData } from '../../../../../store/slices/modals.slice';
import { selectUser } from '../../../../../store/slices/user.slice';
import Button from '../../../../Atoms/Button/Button';
import Icon from '../../../../Atoms/Icon/Icon';
import { OnboardingStepInterface, Steps } from '../../OnboardingModal';
import styles from './WelcomeStep.module.scss';

const WelcomeStep = ({ goToStep }: OnboardingStepInterface): ReactElement => {
  const { status, name } = useSelector(selectUser, shallowEqual);
  const { Step1a, Step1b } = useSelector(selectOnboardingModalData, shallowEqual);
  const firstName = name.split(' ')[0];

  const step1bTitle = Step1b.title.replace('{{username}}', firstName);

  return status === UserStatuses.TRIAL ? (
    <>
      <div className={styles.icon}>
        <Icon name='welcome' />
      </div>
      <p className={styles.title}>{Step1a.title}</p>
      <span className={styles.subtitle}>{Step1a.body}</span>
      <Button onClick={goToStep(Steps.TIMEZONE)} value={'Next'} />
    </>
  ) : (
    <>
      <div className={styles.icon}>
        <Icon name='hi' />
      </div>
      <p className={styles.title}>{step1bTitle}</p>
      <span className={styles.subtitle}>{Step1b.body}</span>
      <Button onClick={goToStep(Steps.TIMEZONE)} value={'Next'} />
    </>
  );
};

export default WelcomeStep;
