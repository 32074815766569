import classnames from 'classnames';
import React, { ReactElement, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { SignupSteps } from '../../../enums/signup-steps.enum';
import { getSignupFlowStepCount } from '../../../helpers/get-signup-flow-step-count';
import { selectIsReferralCodeValid } from '../../../store/slices/global.slice';
import Icon from '../../Atoms/Icon/Icon';
import MobileSignUpFlowNavbar from './MobileNavbar/MobileSignUpFlowNavbar';
import { homeLink, loginText, signUpText } from './SignUpFlowNavbar.const';
import { SignUpFlowNavbarInterface } from './SignUpFlowNavbar.interface';
import styles from './SignUpFlowNavbar.module.scss';

const stepsTotalCount = Object.values(SignupSteps).length;

const SignUpFlowNavbar = ({
  isPartnersDashboard,
  signUpStep,
  isFinalStep
}: SignUpFlowNavbarInterface): ReactElement => {
  const isReferralCodeValid = useSelector(selectIsReferralCodeValid, shallowEqual);

  const steps = useMemo((): SignupSteps[] => {
    const signupSteps = Object.values(SignupSteps);
    return isReferralCodeValid ? signupSteps : signupSteps.filter(item => item !== SignupSteps.WELCOME);
  }, [isReferralCodeValid]);

  return (
    <>
      <div className={styles.container}>
        <Link to={homeLink}>
          <span>
            <Icon name={'logo-long'} className={styles.logo} />
          </span>
        </Link>
        {!isPartnersDashboard && !signUpStep && !isFinalStep && (
          <Link to={'/signup'}>
            <span className={styles.signup}>{signUpText}</span>
          </Link>
        )}
        {!isPartnersDashboard && signUpStep === SignupSteps.SIGN_UP && (
          <Link to={'/login'}>
            <span className={styles.login}>{loginText}</span>
          </Link>
        )}
        {signUpStep && (
          <div className={classnames(styles.steps, isReferralCodeValid && styles.longerSteps)}>
            <div
              className={styles.progress}
              style={{ width: `${(getSignupFlowStepCount(signUpStep, steps) * 100) / stepsTotalCount}%` }}
            />
            {steps.map((step, idx) => (
              <div
                key={step}
                className={classnames(styles.step, signUpStep === step && styles.activeStep)}
                style={{ justifyContent: idx === 0 ? 'flex-start' : idx === 1 ? 'center' : 'flex-end' }}
              >
                {steps.indexOf(signUpStep) > idx && <Icon name={'tick'} />}
                <span>{step}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.mobileContainer}>
        <MobileSignUpFlowNavbar
          isPartnersDashboard={isPartnersDashboard}
          signUpStep={signUpStep}
          isFinalStep={isFinalStep}
          isReferralCodeValid={isReferralCodeValid}
          steps={steps}
        />
      </div>
    </>
  );
};

export default SignUpFlowNavbar;
