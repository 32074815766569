import React, { ReactElement } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ReferralDataInterface } from '../../../../interfaces/referral-data.interface';
import { selectReferralData } from '../../../../store/slices/referral.slice';
import QAContainer from '../../../Atoms/QAContainer/QAContainer';
import styles from './Referral.module.scss';
import CreateReferral from './components/CreateReferral/CreateReferral';
import QAStyles from './components/QuestionAnswer/QuestionAnswer.module.scss';
import ReferralHistory from './components/ReferralHistory/ReferralHistory';

const Referral = (): ReactElement => {
  const referralData = useSelector(selectReferralData, shallowEqual) as ReferralDataInterface;
  const { referralTabTitle, referralTabBody, StudioProfileFAQs } = referralData || {};

  if (!referralData) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{referralTabTitle}</h2>
        <p className={styles.description}>{referralTabBody}</p>
      </div>
      <CreateReferral />
      <ReferralHistory />
      <QAContainer title={'FAQ'} items={StudioProfileFAQs?.faq} QAStyles={QAStyles} />
    </div>
  );
};

export default Referral;
