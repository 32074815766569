import { Dispatch } from 'react';

import { CreateMessagePayloadInterface } from '../../../../interfaces/messages.interface';
import { ConfirmBookingPayloadInterface, SessionInterface } from '../../../../interfaces/session.interface';
import { TrackInterface } from '../../../../interfaces/track.interface';
import MessagesService from '../../../../services/messages/messages.service';
import SessionsService from '../../../../services/sessions/sessions.service';

interface OnBookSessionFormSubmitInterface {
  info: ConfirmBookingPayloadInterface;
  setSubmitting?: Dispatch<boolean>;
  setError?: any;
  onSubmitCallback?: () => void;
  message?: CreateMessagePayloadInterface;
}

export const onBookSessionFormSubmit = async ({
  info,
  setSubmitting,
  setError,
  onSubmitCallback,
  message
}: OnBookSessionFormSubmitInterface): Promise<SessionInterface> => {
  try {
    const session = (await SessionsService.confirmBooking(info)).data;
    if (message?.body) {
      await MessagesService.createMessage(message);
    }
    setSubmitting?.(false);
    onSubmitCallback?.();
    return session;
  } catch (err) {
    if (err?.response?.status === 401) {
      setSubmitting(false);
      setError('track_name', {
        type: 'server',
        message: 'You are not allowed to make changes on this session'
      });
    } else {
      setError('server', {
        type: 'manual',
        message: 'We have some issues connecting to our servers. Please try again later.'
      });
    }
    setSubmitting?.(false);
    throw new Error(err);
  }
};

export const prepareTrackDetailsInfo = ({ data, session, extraConfig }): Record<string, any> => {
  const trackInfo = {} as TrackInterface;
  const { track_title, track_genre, reference_track, meter, bpm, sampleRate, extra } = data;

  if (!track_title?.__isNew__ || !track_title?.addedOnBlur) {
    trackInfo.id = track_title?.value;
  }

  const trackData = {
    title: track_title?.label,
    track_genre: track_genre.value,
    reference_track,
    meter,
    bpm,
    sampleRate: sampleRate?.value,
    options: extra.value ?? extra
  };

  /* Prebook session */
  const info = {
    session_id: session.id,
    track: { ...trackInfo, ...trackData },
    session_info: {
      ...trackData
    }
  };

  if (session.deliverables === null) {
    info['deliverables'] = { files_format: extraConfig.deliverablesOptions.options[0] };
  }

  return info;
};

export const prepareDeliverablesInfo = ({ data, session }): Record<string, any> => ({
  session_id: session.id,
  deliverables: {
    files_format: data.files_format,
    attendance: data.attendance
  }
});
