import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store/store';

import { UploadStatus } from '../../enums/upload-status.enum';
import { SingleUploadInterface } from '../../interfaces/upload-card.interface';
import { SlicesNames } from './slices-names.enum';

type SliceState = {
  isUploadsShown: boolean;
  uploads: SingleUploadInterface[];
  uploadStatus: UploadStatus | null;
};

export const uploads = createSlice({
  name: SlicesNames.UPLOADS_SLICE,
  initialState: {
    isUploadsShown: false,
    uploads: [],
    uploadStatus: null
  },
  reducers: {
    setIsUploadsShown: (state: SliceState, { payload }: PayloadAction<boolean>): void => {
      state.isUploadsShown = payload;
    },
    setUploadStatus: (state: SliceState, { payload }: PayloadAction<UploadStatus>): void => {
      state.uploadStatus = payload;
    },
    addUpload: (state: SliceState, { payload }: PayloadAction<SingleUploadInterface>): void => {
      state.uploads = [...state.uploads, payload];
    },
    updateUpload: (state: SliceState, { payload }: PayloadAction<SingleUploadInterface>): void => {
      state.uploads = state.uploads.map(upload => (upload.id === payload.id ? payload : upload));
    },
    removeUpload: (state: SliceState, { payload }: PayloadAction<string>): void => {
      state.uploads = state.uploads.map(upload => (upload.id === payload ? null : upload)).filter(upload => !!upload);
    },
    clearUploads: (state: SliceState): void => {
      state.uploads = [];
    }
  }
});

export const {
  setIsUploadsShown,
  addUpload,
  updateUpload,
  removeUpload,
  clearUploads,
  setUploadStatus
} = uploads.actions;
export const selectIsUploadsShown = (state: RootState) => state.uploadsSlice.isUploadsShown;
export const selectUploadStatus = (state: RootState) => state.uploadsSlice.uploadStatus;
export const selectUploads = (state: RootState) => state.uploadsSlice.uploads;

export default uploads.reducer;
