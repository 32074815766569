import { UserCohorts } from '../enums/cohorts.enum';
import { UserStatuses } from '../enums/user-statuses.enum';

export const getUserCohort = (user: any): string => {
  const { status: userStatus } = user;
  switch (userStatus) {
    case UserStatuses.SIGNUP:
      return UserCohorts.PUBLIC;
    case UserStatuses.ONDEMAND:
      return UserCohorts.ONDEMAND;
    case UserStatuses.CANCELLED:
      return UserCohorts.CANCELLED;
    case UserStatuses.SUBSCRIBER:
      if (user.subscription && !user.subscription.legacy) {
        return UserCohorts.MEMBER;
      }
      return UserCohorts.LEGACY;
      break;
    case UserStatuses.PENDING_CANCELLATION:
      if (user.subscription && !user.subscription.legacy) {
        return UserCohorts.MEMBER;
      }
      return UserCohorts.LEGACY;
      break;
    default:
      return UserCohorts.LEGACY;
      break;
  }
};
