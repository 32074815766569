import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from '../../../Atoms/Button/Button.js';
import InputError from '../../../Atoms/InputError/InputError.js';
import styles from './SecurityForm.module.css';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import UserService from '../../../../services/user/user.service';
import { hideToast, showToast } from '../../../../store/slices/toast.slice';

export default function SecurityForm({ user }) {

  const dispatch = useDispatch();
  const { register, handleSubmit, setError, formState: { errors }, reset, clearErrors, watch } = useForm();
  const password = useRef({});
  password.current = watch('password', '');
  const [submitting, setSubmitting] = useState(false);

  const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

  const sendToast = (message, toastType, delay = 4000) => {
    const payload = {
      message,
      toastType
    };
    dispatch(showToast(payload));
    setTimeout(() => {
      dispatch(hideToast());
    }, delay);
  };

  const onSubmit = async data => {
    setSubmitting(true);

    const payload = {
      current_password: data.current_password,
      new_password: data.new_password
    };

    try {
      const res = await UserService.changePassword(payload);
      if (res.status === 200) {
        setSubmitting(false);
        sendToast('Password was changed successfully', 'success');
        reset();
      }
    } catch (err) {
      if (err.response.status === 401) {
        setSubmitting(false);
        setError('current_password', {
          type: 'manual',
          message: 'Incorrect password'
        });
      } else {
        setError('server', {
          type: 'manual',
          message: 'We have some issues connecting to our servers. Please try again later.'
        });
      }
      setSubmitting(false);
    }
  };

  return (
    <>
      {user.provider === 'manual' ? (
        <>
          <form className={styles.profileSecurity} onSubmit={handleSubmit(onSubmit)}>
            <label className={styles.profileSecurity__label + ' ' + styles.mbSmall}>
              {' '}
              Current Password
              <input
                className={styles.profileSecurity__input}
                id='current_password'
                type='password'
                name='current_password'
                onClick={() => clearErrors()}
                {...register('current_password', {
                  required: 'You must specify your current password',
                  minLength: {
                    value: 8,
                    message: 'Password must have at least 8 characters'
                  }
                })}
              />
            </label>
            <Link to={'/reset-password'} className={styles.profileSecurity__forgot}>
              Forgot Password?
            </Link>
            {errors.current_password && <InputError message={errors.current_password.message} />}

            <label className={styles.profileSecurity__label + ' ' + styles.mtMedium}>
              {' '}
              New Password
              <input
                className={styles.profileSecurity__input}
                id='new_password'
                type='password'
                name='new_password'
                onClick={() => clearErrors()}
                {...register('new_password', {
                  required: 'You must specify a new password',
                  minLength: {
                    value: 8,
                    message: 'Password must have at least 8 characters'
                  }
                })}
              />
              {errors.new_password && <InputError message={errors.new_password.message} />}
            </label>

            <label className={styles.profileSecurity__label}>
              {' '}
              Confirm password
              <input
                className={styles.profileSecurity__input}
                id='password_confirmation'
                type='password'
                name='password_confirmation'
                onClick={() => clearErrors()}
                {...register('password_confirmation', {
                  validate: value =>
                    value === watch('new_password') || 'The new password and its confirmation do not match'
                })}
              />
              {errors.password_confirmation && <InputError message={errors.password_confirmation.message} />}
              {errors.server && <InputError message={errors.server.message} />}
            </label>

            <Button
              className={styles.button + ' ' + styles.mbMedium}
              type='submit'
              disabled={submitting}
              value={submitting ? ' Saving...' : ' Save changes'}
            />
          </form>
        </>
      ) : (
        <>
          <p>
            You're currently signed in with {capitalizeFirstLetter(user.provider)}. If you'd like your account to be set
            up with Email / Password instead, please contact support.
          </p>
        </>
      )}
    </>
  );
}
