import axios, { AxiosPromise } from 'axios';

import {
  CreateMessagePayloadInterface,
  EditThreadPayloadInterface,
  GetThreadPayloadInterface,
  MessageInterface,
  ThreadInterface
} from '../../interfaces/messages.interface';

const BASE_API = `${process.env.REACT_APP_API_URL}/api/v2`;

export const MESSAGES_PAGE_SIZE = 1000;

const axiosInstance = axios.create({
  baseURL: BASE_API,
  withCredentials: true
});

const createMessage = (payload: CreateMessagePayloadInterface): AxiosPromise<{ message: MessageInterface }> =>
  axiosInstance.post('/messages', payload);

const getThreadRequest = ({
  threadId,
  page = 0,
  size = MESSAGES_PAGE_SIZE
}: GetThreadPayloadInterface): AxiosPromise<{ thread: ThreadInterface }> =>
  axiosInstance.get(`/threads/${threadId}/messages`, { params: { page, size } });

const editThread = (payload: EditThreadPayloadInterface): AxiosPromise<{ thread: ThreadInterface }> =>
  axiosInstance.put('/threads', payload);

export default {
  createMessage,
  getThreadRequest,
  editThread
};
