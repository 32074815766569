import React, { ReactElement } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from '../../../store/store';
import BillingAddress from '../../Modals/BillingAddressModal/BillingAddressModal';
import BlockingModal from '../../Modals/BlockingModal/BlockingModal';
import HowItWorksModal from '../../Modals/HowItWorksModal/HowItWorksModal';
import OffboardingModal from '../../Modals/OffboardingModal/OffboardingModal';
import OnboardingModal from '../../Modals/OnboardingModal/OnboardingModal';
import PlanSelectionModal from '../../Modals/PlanSelectionModal/PlanSelectionModal';
import PlansModal from '../../Modals/PlansModal/PlansModal';
import ServiceUpdateModal from '../../Modals/ServiceUpdateModal/ServiceUpdateModal';
import SpeedLimitModal from '../../Modals/SpeedLimitModal/SpeedLimitModal';
import TimezoneModal from '../../Modals/TimezoneModal/TimezoneModal';
import TopUpModal from '../../Modals/TopUpModal/TopUpModal';
import CancelSessionModal from '../../Modals/CancelSessionModal/CancelSessionModal';
import RatingModal from '../../Modals/RatingModal/RatingModal';
import UpsellPlansModal from '../../Modals/UpsellPlansModal/UpsellPlansModal';

const ModalsContainer = (): ReactElement => {
  const {
    isShowBillingModal,
    isShowSpeedLimitModal,
    isShowTimezoneModal,
    isShowOffboardingModal,
    isShowOnboardingModal,
    isShowHowItWorksModal,
    isShowBlockingModal,
    isShowServiceUpdateModal,
    isShowBillingAddressModal,
    isShowRatingModal,
    isShowTopUpModal,
    isShowCancelSessionModal,
    isShowPlansModal,
    isUpsellPlansModal
  } = useSelector((state: RootState) => state.modalsSlice, shallowEqual);

  return (
    <>
      <PlanSelectionModal isOpen={isShowBillingModal} />
      <SpeedLimitModal isOpen={isShowSpeedLimitModal} />
      <TimezoneModal isOpen={isShowTimezoneModal} />
      <OffboardingModal isOpen={isShowOffboardingModal} />
      <OnboardingModal isOpen={isShowOnboardingModal} />
      <BlockingModal isOpen={isShowBlockingModal} />
      <HowItWorksModal isOpen={isShowHowItWorksModal} />
      <ServiceUpdateModal isOpen={isShowServiceUpdateModal} />
      <TopUpModal isOpen={isShowTopUpModal.isShown} />
      <CancelSessionModal isOpen={isShowCancelSessionModal.isShown} />
      <PlansModal isOpen={isShowPlansModal} />
      <UpsellPlansModal isOpen={isUpsellPlansModal} />
      <BillingAddress isOpen={isShowBillingAddressModal} />
      <RatingModal isOpen={isShowRatingModal.isShown} />
    </>
  );
};

export default ModalsContainer;
