import { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useDispatch } from 'react-redux';

import { ReferralCodeError, ReferralCodeResponse } from '../../services/referral/referral.interface';
import ReferralService from '../../services/referral/referral.service';
import { setIsReferralCodeValid } from '../../store/slices/global.slice';

interface UseCheckCodeHookInterface {
  referralCode: string;
  setValue: UseFormSetValue<any>;
}

interface UseCheckCodeResult {
  isLoading: boolean;
  referral: ReferralCodeResponse;
  referralError: ReferralCodeError;
  referralFirstName: string;
}

export const useCheckCodeHook = ({ referralCode, setValue }: UseCheckCodeHookInterface): UseCheckCodeResult => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [referral, setReferral] = useState<ReferralCodeResponse>(null);
  const [referralFirstName, setReferralFirstName] = useState<string>('');
  const [referralError, setReferralError] = useState<ReferralCodeError>(null);

  useEffect((): void => {
    if (!referralCode) {
      return;
    }
    const getReferral = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const referralResponse = (await ReferralService.checkCode(referralCode)).data;
        const { isUsed, email, name } = referralResponse;
        setValue('email', email);
        if (isUsed) {
          setReferralError({ isUsed });
          return;
        }
        setReferral(referralResponse);
        setReferralFirstName(name.split(' ')[0]);
        dispatch(setIsReferralCodeValid(true));
      } catch (e) {
        setReferralError({ invalid: true });
        console.error(e);
      }
    };
    getReferral().finally((): void => setIsLoading(false));
  }, [setValue, referralCode, dispatch]);

  return {
    isLoading,
    referral,
    referralError,
    referralFirstName
  };
};
