import { ToastTypes } from '../enums/toast-types.enum';
import { useToast } from './useToast';

export const useCopyToClipboard = (): ((text: string) => void) => {
  const { sendToast } = useToast();

  return (text: string): void => {
    if (text) {
      navigator.clipboard.writeText(text).finally();
      sendToast({ message: 'Copied to clipboard.', toastType: ToastTypes.SUCCESS });
    }
  };
};
