import { createAsyncThunk } from '@reduxjs/toolkit';

import { PricingV3DataInterface } from '../../interfaces/pricing-data.interface';
import StrapiService from '../../services/cms/cms.service';
import { SlicesNames } from '../slices/slices-names.enum';

export const getLimitations = createAsyncThunk(
  `${SlicesNames.LIMITATIONS_SLICE}/getLimitations`,
  async (): Promise<any> => {
    try {
      const { ondemandLimitations } = (await StrapiService.getPricingV4StudioRequest()).data;
      return ondemandLimitations;
    } catch (e) {
      return;
    }
  }
);