import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { localStorageKeys } from '../../../constants/localStorageKeys.const';
import { PlansCtaTypeEnum } from '../../../enums/plans-cta-type.enum';
import { translateUserSubscription } from '../../../helpers/translate-user-subscription';
import SubscriptionsService from '../../../services/subscriptions/subscriptions.service';
import { getMe } from '../../../store/actions/user.actions';
import { getPlans } from '../../../store/actions/global.actions';
import { selectCurrentPlan, selectPlans, setIsShowGlobalLoader  } from '../../../store/slices/global.slice';
import { PaymentProviders } from '../../../enums/payment-providers.enum';
import PaypalService from '../../../services/subscriptions/paypal.service';
import { getPlanPriceIdByEnvHelper } from '../../../helpers/get-plan-price-id-by-env.helper';
import { useHistory } from 'react-router-dom';
import ModalWrapper from '../../Modals/ModalWrapper/ModalWrapper';
import { useFlags } from 'launchdarkly-react-client-sdk';
import classnames from 'classnames';
import { CreditsRolloverModesEnum } from '../../../enums/credits-rollover-modes.enum';
import { creditsBankMaxCounter } from '../../../helpers/credits-bank-max-counter.helper';
import { selectUser } from '../../../store/slices/user.slice';
import PlanChangeModal from '../../Atoms/PlanChangeModal/PlanChangeModal';
import { PlansModalEnum } from './PlansModal.enum';
import { paymentMethodsData } from './PlansModal.const';
import { setIsShowPlansModal } from '../../../store/slices/modals.slice';
import Icon from '../../Atoms/Icon/Icon';
import PlansSelector from '../../Atoms/PlansSelector/PlansSelector';
import image from '../../../../assets/images/plans-modal/plans-modal-background.png';
import { numberWithCommas } from '../../../helpers/number-with-commas.helper';
import ReactTooltip from 'react-tooltip';
import { UserStatuses } from '../../../enums/user-statuses.enum';
import styles from './PlansModal.module.scss';

let stripePromise = null;
if (process.env.REACT_APP_STRIPE_ID) {
  import('@stripe/stripe-js').then(({ loadStripe }) => (stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ID)));
}

const joinUserStatuses = [
  UserStatuses.TRIAL,
  UserStatuses.PENDING_CANCELLATION,
  UserStatuses.WIX_USER,
  UserStatuses.CANCELLED,
  UserStatuses.ONDEMAND
];
export const showPaymentMethodModalStatuses = [UserStatuses.WIX_USER, UserStatuses.CANCELLED];

const PlansModal = ({ isOpen }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser, shallowEqual);
  const plans = useSelector(selectPlans, shallowEqual);
  const currentPlan = useSelector(selectCurrentPlan, shallowEqual);
  const [showModal, setShowModal] = useState(false);
  const [newPlan, setNewPlan] = useState(null);
  const [modalType, setModalType] = useState(PlansModalEnum.DOWNGRADE);
  const [rolloverMode, setRolloverMode] = useState(null);
  const [isPaymentMethodModalShown, setIsPaymentMethodModalShown] = useState(false);
  const [isCreditsBankWarningShown, setIsCreditsBankWarningShown] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(PaymentProviders.STRIPE);
  const { paypalPayments } = useFlags();

  const {
    currentPrice,
    tierNumber,
    nextBillingDate,
    isTrial,
    isAnnual,
    paymentsProvider: userPaymentsProvider
  } = translateUserSubscription(user);

  const [paymentsProvider, setPaymentsProvider] = useState(userPaymentsProvider || PaymentProviders.STRIPE);

  useEffect(() => setPaymentsProvider(userPaymentsProvider), [userPaymentsProvider]);

  useEffect(() => {
    if (plans?.length) {
      return;
    }
    dispatch(getPlans(user));
  }, [dispatch, plans]);

  const mappedPlans = useMemo(() => {
    if (!plans || !plans?.length) {
      return;
    }

    return plans?.map(plan => {
      const { credits } = plan;
      const priceId = getPlanPriceIdByEnvHelper(plan, paymentsProvider);
      return {
        ...plan,
        credits,
        priceId
      };
    }).sort((a, b) => +a.tier < +b.tier ? -1 : 1);
  }, [plans, paymentsProvider]);

  useEffect(() => {
    if (!mappedPlans?.length) {
      return;
    }
    const planToSelect = mappedPlans.find(({ highlighted }) => highlighted);
    setSelectedPlan(planToSelect ?? mappedPlans[1]);
  }, [mappedPlans, isOpen]);

  const openPlanChangeModal = () => {
    setShowModal(true);
  };

  const closePlanChangeModal = () => {
    setShowModal(false);
  };

  const onRequestClose = () => {
    dispatch(setIsShowPlansModal(false));
  };

  const setLocalStorageCurrentPlanId = () => localStorage.setItem(localStorageKeys.CURRENT_PLAN_ID, currentPrice);

  const selectPaymentMethod = method => () => setPaymentMethod(method);

  const redirectToPlanUrl = async planId => {
    dispatch(setIsShowGlobalLoader(true));
    try {
      const res = await SubscriptionsService.createCheckoutSession(planId);
      if (res.status === 200) {
        const stripe = await stripePromise;

        const { sessionId } = res.data;
        setLocalStorageCurrentPlanId();
        stripe
          .redirectToCheckout({
            sessionId
          })
          .finally();
      }
    } catch (err) {
      console.error(err);
      dispatch(setIsShowGlobalLoader(false));
    }
  };

  const downgradeStripePlan = async () => {
    dispatch(setIsShowGlobalLoader(true));
    try {
      const priceId = getPlanPriceIdByEnvHelper(newPlan, PaymentProviders.STRIPE);
      await SubscriptionsService.downgradeSubscription(priceId);
      setLocalStorageCurrentPlanId();
      history.push('/profile#billing?session_id=true');
      dispatch(getMe());
      dispatch(setIsShowGlobalLoader(false));
    } catch (err) {
      console.error(err);
      dispatch(setIsShowGlobalLoader(false));
    }
    closePlanChangeModal();
    onRequestClose();
  };

  const showUpgradeModal = async plan => {
    await handleRolloverMode({ plan, isDowngrading: false });
  };

  const upgradePlan = async plan => {
    if (showPaymentMethodModalStatuses.includes(user.status) && paypalPayments) {
      setIsCreditsBankWarningShown(false);
      setIsPaymentMethodModalShown(true);
      return;
    }
    const priceId = getPlanPriceIdByEnvHelper(plan, paymentsProvider);
    if (paymentsProvider === PaymentProviders.PAYPAL) {
      dispatch(setIsShowGlobalLoader(true));
      const { url } = (await PaypalService.upgradeSubscription(priceId)).data;
      window.location.href = url;
      dispatch(setIsShowGlobalLoader(false));
      return;
    }
    await redirectToPlanUrl(priceId);
    onRequestClose();
  };

  const joinFromModal = async () => {
    const priceId = getPlanPriceIdByEnvHelper(newPlan, paymentMethod);
    if (paymentMethod === PaymentProviders.PAYPAL) {
      dispatch(setIsShowGlobalLoader(true));
      const { url } = (await PaypalService.createPaypalSubscription(priceId)).data;
      window.location.href = url;
      dispatch(setIsShowGlobalLoader(false));
      return;
    }
    await redirectToPlanUrl(priceId);

  };

  const downgradePlan = async plan => {
    const priceId = getPlanPriceIdByEnvHelper(plan, paymentsProvider);
    if (paymentsProvider === PaymentProviders.STRIPE) {
      await downgradeStripePlan();
    } else {
      setIsCreditsBankWarningShown(false);
      dispatch(setIsShowGlobalLoader(true));
      try {
        const { url } = (await PaypalService.downgradeSubscription(priceId)).data;
        window.location.href = url;
      } catch (e) {
        dispatch(setIsShowGlobalLoader(false));
      }
    }
  };

  const showDowngradeModal = async plan => {
    await handleRolloverMode({ plan, isDowngrading: true });
  };

  const handleRolloverMode = async ({ plan, isDowngrading }) => {
    if (!currentPlan) {
      await upgradePlan(plan);
      return;
    }
    if (isDowngrading) {
      if (paymentsProvider === PaymentProviders.PAYPAL) {
        setIsCreditsBankWarningShown(true);
      } else {
        await openPlanChangeModal(plan);
      }

      if (currentPlan.has_rollover && plan.has_rollover) {
        setRolloverMode(CreditsRolloverModesEnum.DOWNGRADE_WITH_ROLLOVER);
      } else if (currentPlan.has_rollover && !plan.has_rollover) {
        setRolloverMode(CreditsRolloverModesEnum.DOWNGRADE_WITHOUT_ROLLOVER);
      } else {
        setRolloverMode(null);
      }
    } else {
      if (currentPlan.has_rollover && !plan.has_rollover) {
        setRolloverMode(CreditsRolloverModesEnum.SWITCH);
        setIsCreditsBankWarningShown(true);
        return;
      }
      await upgradePlan(plan);
    }
  };

  const onCTAButtonClick = async () => {
    setRolloverMode(null);
    setNewPlan(selectedPlan);
    const { name } = selectedPlan;

    switch (ctaType) {
      case PlansCtaTypeEnum.UPGRADE:
      case PlansCtaTypeEnum.SWITCH:
        TagManager.dataLayer({
          dataLayer: { event: 'upgradePlan', oldPlan: currentPlan, newPlan: name }
        });
        await showUpgradeModal(selectedPlan);
        break;
      case PlansCtaTypeEnum.NEW:
        TagManager.dataLayer({
          dataLayer: { event: 'newPlan', newPlan: name }
        });
        await showUpgradeModal(selectedPlan);
        break;
      case PlansCtaTypeEnum.DOWNGRADE:
        await showDowngradeModal(selectedPlan);
        break;
      default:
        return;
    }
  };

  const ctaType = useMemo(() => {
    if (!selectedPlan) {
      return;
    }
    const { tier } = selectedPlan;
    const priceId = getPlanPriceIdByEnvHelper(selectedPlan, paymentsProvider);

    if (currentPrice === undefined || joinUserStatuses.includes(user.status)) {
      return PlansCtaTypeEnum.NEW;
    }
    if (priceId === currentPrice) {
      return PlansCtaTypeEnum.CURRENT;
    }
    if (isTrial) {
      return PlansCtaTypeEnum.NEW;
    }
    if (!isAnnual || user.status === UserStatuses.WIX_USER || (isAnnual && (tier === tierNumber))) {
      return PlansCtaTypeEnum.SWITCH;
    }
    if (tierNumber > tier) {
      return PlansCtaTypeEnum.DOWNGRADE;
    }
    return PlansCtaTypeEnum.UPGRADE;
  }, [selectedPlan, paymentsProvider, currentPrice, user.status, isTrial, isAnnual, tierNumber]);

  const onPlanSelect = plan => () => {
    setSelectedPlan(plan);
  };

  if (!mappedPlans?.length) {
    return <></>;
  }

  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onRequestClose} className={styles.modalWrapper}>
      <div className={styles.plans__body}>
        <div className={styles.leftSide}>
          <img src={image} alt='background' />
        </div>
        <div className={styles.rightSide}>
          <PlansSelector
            header={'Upgrade your experience'}
            mappedPlans={mappedPlans}
            selectedPlan={selectedPlan}
            onCTAButtonClick={onCTAButtonClick}
            onPlanSelect={onPlanSelect}
            showTopupPrice={true}
          />
        </div>
      </div>

      <PlanChangeModal
        appElement={document.getElementById('root')}
        isOpen={showModal}
        onRequestClose={closePlanChangeModal}
      >
        {newPlan && modalType === PlansModalEnum.DOWNGRADE && (
          <div className='downgradeModal'>
            <button className='downgradeModal__close' onClick={closePlanChangeModal}>
              Close
            </button>
            <h1 className='downgradeModal__title'>Confirm your new plan</h1>

            <>
              <p className='downgradeModal__currentHeading'>Changing to</p>
              <div className='downgradeModal__currentPlan'>
                <div className='downgradeModal__currentPlanLeft'>
                  <h4 className='downgradeModal__currentPlanTitle'> {newPlan?.title}</h4>
                  <p className='downgradeModal__currentPlanDescription'>
                    What you'll pay {newPlan?.isAnnual ? 'yearly' : 'monthly'} starting{' '}
                    <span>{moment(nextBillingDate).tz(user.timezone).format('MMMM DD YYYY')}</span>
                  </p>
                </div>
                <div className='downgradeModal__currentPlanRight'>${newPlan?.price}</div>
              </div>

              <div className='downgradeModal__currentPlanDue'>
                <div className='downgradeModal__currentPlanLeft'>
                  <h4 className='downgradeModal__currentPlanDueTitle'>Amount Due today</h4>
                </div>
                <div className='downgradeModal__currentPlanRight'>$00.00</div>
              </div>
              <div className='downgradeModal__currentPlanDue'>
                <div className={styles.vat}>
                  <span>+23% VAT may apply (to EU members)</span>
                </div>
              </div>

              {rolloverMode === CreditsRolloverModesEnum.DOWNGRADE_WITH_ROLLOVER &&
              <div className={styles.rolloverInfoContainer}>
                <span>Your Credits Bank limit will be reduced to {creditsBankMaxCounter(newPlan?.credits)} Credits.</span>
                <span>Your current Credits Bank balance will not be affected<br />(you will not lose any stored Credits).</span>
              </div>}

              {rolloverMode === CreditsRolloverModesEnum.DOWNGRADE_WITHOUT_ROLLOVER &&
              <div className={styles.rolloverInfoContainer}>
                <span>You are downgrading to a plan which does not have Credits Rollover.</span>
                <span>Your current Credits Bank balance will not be affected (you will not lose any stored Credits). However, you will no longer be able to rollover Credits and store them for future use. Are you sure you wish to downgrade?</span>
              </div>}

              <div className='downgradeModal__currentPlanActions'>
                <a className={styles.confirmButton} onClick={downgradeStripePlan}>
                  Confirm
                </a>
                <button className='downgradeModal__currentPlanActionsCancel' onClick={closePlanChangeModal}>
                  Go Back
                </button>

                <p>
                  By confirming your new plan, you agree to Musiverse.Ida's{' '}
                  <a target='_blank' href='https://www.musiversal.com/terms-of-use' rel='noreferrer'>
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a target='_blank' href='https://www.musiversal.com/privacy-policy' rel='noreferrer'>
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
            </>
          </div>
        )}

        {modalType === 'credits' && (
          <div className='downgradeModal'>
            <h1 className='downgradeModal__title'>Confirm your new plan</h1>

            <>
              <p className='downgradeModal__currentHeading'>Changing To</p>

              <div className='downgradeModal__currentPlan'>
                <div className='downgradeModal__currentPlanLeft'>
                  <h4 className='downgradeModal__currentPlanTitle'> {newPlan.title}</h4>
                  <p className='downgradeModal__currentPlanDescription'>
                    What you'll pay {newPlan?.isAnnual ? 'yearly' : 'monthly'} starting today
                  </p>
                </div>
                <div className='downgradeModal__currentPlanRight'>${newPlan.price}</div>
              </div>

              <div className='downgradeModal__currentPlanDue'>
                <div className='downgradeModal__currentPlanLeft'>
                  <h4 className='downgradeModal__currentPlanDueTitle'>
                    On your next billing date, you will be credited an amount corresponding to the time left on your
                    previous unlimited plan.
                  </h4>
                  <h5 className={styles.switchPlanExplanation}>
                    For example, if you had 15 days remaining on a $99 unlimited plan, you’ll be credited $44.50 on your
                    next billing date.
                  </h5>
                </div>
              </div>

              <div className='downgradeModal__currentPlanActions'>
                <a
                  className='downgradeModal__currentPlanActionsConfirm'
                  onClick={() => redirectToPlanUrl(getPlanPriceIdByEnvHelper(newPlan, paymentsProvider))}
                >
                  Confirm
                </a>
                <button className='downgradeModal__currentPlanActionsCancel' onClick={closePlanChangeModal}>
                  Go Back
                </button>

                <p>
                  By confirming your new plan, you agree to Musiverse.Ida's{' '}
                  <a target='_blank' href='https://www.musiversal.com/terms-of-use' rel='noreferrer'>
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a target='_blank' href='https://www.musiversal.com/privacy-policy' rel='noreferrer'>
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
            </>
          </div>
        )}
      </PlanChangeModal>

      {/*SELECT PAYMENT MODAL*/}
      <ModalWrapper isOpen={isPaymentMethodModalShown} onRequestClose={() => setIsPaymentMethodModalShown(false)}>
        <div className={styles.paymentMethodModal}>
          <h1>Select Payment Method:</h1>
          <div className={styles.methods}>
            {paymentMethodsData.map(({ name, icon, provider }) =>
              <div
                key={name}
                onClick={selectPaymentMethod(provider)}
                className={classnames(styles.method, provider === paymentMethod && styles.methodSelected)}>
                <p>{name}</p>
                <Icon name={icon} style={{ transform: 'scale(1.75)' }} />
              </div>
            )}
          </div>
          <button
            onClick={joinFromModal}
            className={styles.joinButton}>
            {PlansCtaTypeEnum.NEW} {newPlan?.title}
          </button>
        </div>
      </ModalWrapper>

      {/*ROLLOVER WARNING MODAL*/}
      <ModalWrapper isOpen={isCreditsBankWarningShown} onRequestClose={() => setIsCreditsBankWarningShown(false)}>
        <div className={styles.rolloverWarningModal}>
          {rolloverMode === CreditsRolloverModesEnum.SWITCH && <>
            <h1>Are you sure you wish to switch?</h1>
            <div className={styles.rolloverInfoContainer}>
              <span>You are switching to a plan which does not have Credits Rollover.</span>
              <span>Your current Credits Bank balance will not be affected (you will not lose any stored Credits). However, you will no longer be able to rollover Credits and store them for future use. Are you sure you wish to switch?</span>
            </div>
          </>}
          {rolloverMode === CreditsRolloverModesEnum.DOWNGRADE_WITH_ROLLOVER && <>
            <h1>Are you sure you wish to downgrade?</h1>
            <div className={styles.rolloverInfoContainer}>
              <span>Your Credits Bank limit will be reduced to {creditsBankMaxCounter(newPlan?.credits)} Credits.</span>
              <span>Your current Credits Bank balance will not be affected<br />(you will not lose any stored Credits).</span>
            </div>
          </>}
          {rolloverMode === CreditsRolloverModesEnum.DOWNGRADE_WITHOUT_ROLLOVER && <>
            <h1>Are you sure you wish to downgrade?</h1>
            <div className={styles.rolloverInfoContainer}>
              <span>You are downgrading to a plan which does not have Credits Rollover.</span>
              <span>Your current Credits Bank balance will not be affected (you will not lose any stored Credits). However, you will no longer be able to rollover Credits and store them for future use. Are you sure you wish to downgrade?</span>
            </div>
          </>}
          <button
            onClick={() => rolloverMode === CreditsRolloverModesEnum.SWITCH ? upgradePlan(newPlan) : downgradePlan(newPlan)}
            className={styles.joinButton}>
            {rolloverMode === CreditsRolloverModesEnum.SWITCH ? PlansCtaTypeEnum.SWITCH : PlansCtaTypeEnum.DOWNGRADE} {newPlan?.title}
          </button>
        </div>
      </ModalWrapper>
      <ReactTooltip place='top' delayHide={200} textColor='#FFF' backgroundColor='#4B5563' effect='solid' html />
    </ModalWrapper>);
};

export default PlansModal;
