import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getMe } from 'app/store/actions/user.actions';
import { RootState } from 'app/store/store';

import { UserInterface } from '../../interfaces/user.interface';
import { SlicesNames } from './slices-names.enum';

type SliceState = {
  user: UserInterface;
};

export const user = createSlice({
  name: SlicesNames.USER_SLICE,
  initialState: {
    user: null
  },
  reducers: {
    setUser: (state: SliceState, { payload }: PayloadAction<UserInterface>): void => {
      state.user = payload;
    },
    resetUser: (state: SliceState): void => {
      state.user = null;
    }
  },
  extraReducers: {
    [getMe.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<UserInterface>): void => {
      state.user = payload;
    }
  }
});

export const { setUser, resetUser } = user.actions;
export const selectUser = (state: RootState) => state.userSlice.user;

export default user.reducer;
