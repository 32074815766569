import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { ReactElement } from 'react';

import BasicExplore from './basic-explore/basic-explore';
import ProExplore from './pro-explore/pro-explore';

const Explore = (): ReactElement => {
  const { proExploreFilters } = useFlags();
  return proExploreFilters ? <ProExplore /> : <BasicExplore />;
};

export default Explore;
