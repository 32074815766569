import countryList from 'country-list';
import { cloneDeep } from 'lodash';
import React, { ReactElement } from 'react';
import { Control } from 'react-hook-form';

import { editedCountryNames } from '../../../constants/country-list-rename.const';
import CustomSelect from '../Select/Select';

interface CountrySelectInterface {
  control: Control<any>;
  containerClassName?: string;
  labelClassName?: string;
  className?: string;
  stylesOverride?: Record<any, any>;
}

countryList.overwrite(editedCountryNames); // needed to refactor some of country names, that were too long or too formal
const countries = countryList.getData().map(c => ({ value: c.code, label: c.name }));

countries.sort((a, b) => {
  if (a.name === 'United States' || a.name === 'Canada' || a.name === 'United Kingdom') {
    return -1;
  } else if (b.name === 'United States' || b.name === 'Canada' || b.name === 'United Kingdom') {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  }
  return 0;
});

const CountrySelect = ({
  control,
  className,
  containerClassName,
  labelClassName,
  stylesOverride
}: CountrySelectInterface): ReactElement => (
  <CustomSelect
    containerClassName={containerClassName}
    labelClassName={labelClassName}
    className={className}
    label={'Country'}
    name={'country'}
    control={control}
    options={countries}
    stylesOverride={stylesOverride}
  />
);

export default CountrySelect;
