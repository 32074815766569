export enum UserSessionProperties {
  AFTER_BOOKING_SESSION = 'after_booking_session',
  BEFORE_SESSION_24_REMINDER = 'before_session_24h_reminder',
  BEFORE_SESSION_1_REMINDER = 'before_session_1h_reminder',
  ON_SESSION_CANCELLED = 'on_session_cancelled',
  ON_NEW_MESSAGE_SENT = 'on_new_message_sent'
}

export enum UserFileProperties {
  BEFORE_SESSION_72_FILE_REMINDER = 'before_session_72_file_reminder',
  FILE_UPDATE_REQUIRED = 'file_update_required',
  FILES_READY_AFTER_SESSION = 'files_ready_after_session'
}

export enum UserNewsletterProperties {
  CAMPAIGNS_AND_OFFERS = 'campaigns_and_offers',
  MARKETING = 'marketing'
}

export enum PartnerSessionProperties {
  AFTER_RECEIVE_BOOKING = 'after_receive_booking',
  AFTER_RECEIVE_BOOKING_24 = 'after_receive_booking_within_24',
  SEND_WEEKLY_DIGEST = 'send_weekly_digest',
  AFTER_BOOKING_CANCELLATION = 'after_booking_cancellation',
  AFTER_MUSIVERSAL_CANCELLATION = 'after_musiversal_cancellation',
  SEND_ADDITIONAL_NOTES_EMAILS = 'send_additional_notes_emails',
  ON_NEW_MESSAGE_SENT = 'on_new_message_sent'
}

export enum PartnerFileProperties {
  USER_FILES_UPLOADED = 'user_files_uploaded',
  USER_FILES_UPDATED = 'user_files_updated'
}

export enum NotificationCategories {
  SESSIONS = 'sessions',
  FILES = 'files',
  NEWSLETTER = 'newsletter'
}
