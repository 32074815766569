import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import { UploadStatus } from '../../../../enums/upload-status.enum';
import { progressCounter } from '../../../../helpers/file-upload-progress-counter';
import { formatBytes } from '../../../../helpers/format-bytes';
import { SingleUploadInterface } from '../../../../interfaces/upload-card.interface';
import { removeUpload } from '../../../../store/slices/uploads.slice';
import styles from './SingleFileUpload.module.scss';

const SingleFileUpload = ({ fileName, loaded, total, status, source, id }: SingleUploadInterface): ReactElement => {
  const dispatch = useDispatch();

  const isCompleted = status === UploadStatus.COMPLETED;
  const isUploading = status === UploadStatus.UPLOADING;
  const isFailed = status === UploadStatus.FAILED;

  const cancelUpload = async (): Promise<void> => {
    source.cancel('Cancelled by user');
    dispatch(removeUpload(id));
  };

  return (
    <div className={styles.uploadWrapper}>
      <div className={styles.upload}>
        <div className={styles.progress} style={{ width: progressCounter(loaded, total) + '%' }} />
        <div className={classnames(styles.content, isFailed && styles.bgFailed, isCompleted && styles.bgCompleted)}>
          <span className={styles.fileName}>{fileName}</span>
          <span>
            {formatBytes(loaded)} out of {formatBytes(total)} - {progressCounter(loaded, total)}%
          </span>
        </div>
      </div>
      <div className={styles.description}>
        {isCompleted && <span className={styles.completed}>Completed</span>}
        {isUploading && (
          <span className={styles.cancel} onClick={cancelUpload}>
            Cancel
          </span>
        )}
        {isFailed && <span className={styles.failed}>Failed</span>}
      </div>
    </div>
  );
};

export default SingleFileUpload;
