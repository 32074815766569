import React, { ReactElement, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { SendOnboardingDataInterface } from '../../../../../services/user/user.interface';
import UserService from '../../../../../services/user/user.service';
import { setIsShowGlobalLoader } from '../../../../../store/slices/global.slice';
import { selectOnboardingModalData } from '../../../../../store/slices/modals.slice';
import { selectUser, setUser } from '../../../../../store/slices/user.slice';
import BackButton from '../../../../Atoms/BackButton/BackButton';
import Button from '../../../../Atoms/Button/Button';
import CustomCheckbox from '../../../../Atoms/CustomCheckbox/CustomCheckbox';
import { OnboardingStepInterface, Steps } from '../../OnboardingModal';
import styles from './InterestsStep.module.scss';

const InterestsStep = ({
  goToStep,
  control,
  getValues,
  register,
  setValue,
  watch
}: OnboardingStepInterface): ReactElement => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser, shallowEqual);
  const {
    Step6: { options, title }
  } = useSelector(selectOnboardingModalData, shallowEqual);

  const checkboxOptions = options.map(({ value }) => value);

  const watchers = watch('comments', ...checkboxOptions);

  const isButtonDisabled = useMemo((): boolean => {
    const selectedOptions = checkboxOptions.map(val => (getValues()[val] ? val : null)).filter(v => !!v);

    if (selectedOptions.includes('other') && !getValues().comments) {
      return true;
    }

    return !selectedOptions.length;
  }, [checkboxOptions, getValues]);

  useEffect((): void => {
    if (getValues().comments) {
      setValue('other', true);
    }
  }, [watchers, getValues, setValue]);

  const handleSubmit = async (): Promise<void> => {
    dispatch(setIsShowGlobalLoader(true));
    const data = getValues();
    const {
      personaType: persona_type,
      personaLevel: persona_level,
      personaDiy: persona_diy,
      comments,
      timezone
    } = data;
    const interests = checkboxOptions.map(val => (data[val] ? val : null)).filter(v => !!v);
    const onboardingData: SendOnboardingDataInterface = {
      persona_type,
      persona_level,
      persona_diy,
      interests,
      comments
    };
    try {
      const info = {
        user: {
          timezone: timezone.value
        }
      };
      const res = await UserService.updateMe(info);
      await dispatch(setUser({ ...user, ...res.data.user }));

      await UserService.sendOnboardingData(onboardingData);

      goToStep(Steps.THANK_YOU)();
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }
  };

  return (
    <>
      <p className={styles.title}>{title}</p>
      {options.map(({ id, value, label }) => (
        <CustomCheckbox
          key={id}
          required
          name={value}
          label={label}
          control={control}
          containerClassName={styles.checkbox}
          checkedContainerClassName={styles.checked}
          labelClassName={styles.label}
        />
      ))}
      <textarea name='comments' placeholder='Tell us more.' maxLength={255} {...register('comments')} />
      <div className={styles.buttons}>
        <BackButton onClick={goToStep(Steps.PERSONA_DIY)} />
        <Button disabled={isButtonDisabled} className={styles.nextButton} onClick={handleSubmit} value={'Next'} />
      </div>
    </>
  );
};

export default InterestsStep;
