import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import styles from './StarSelector.module.scss';

const MAX_STARS = 5;
const STAR_SIZE = 36;
const STAR_WIDTH = 36;

interface StarSelectorInterface {
  rating: any;
  onOpenRatingButtonClick: any;
  setSelectedScore: any;
  selectedScore: any;
}

const StarSelector = ({ rating, onOpenRatingButtonClick, selectedScore, setSelectedScore }: StarSelectorInterface): ReactElement => {
  const { rating: ratingScore = 0, status } = rating || {};

  const [score, setScore] = useState(0);
  const [clicked, setClicked] = useState(false);

  const hoverStar = (score): void => {
    setScore(score);
  };

  const onStarClick = (score): void => {
    setClicked(true);
    setSelectedScore(score);
    setScore(score);
  };

  const clearStars = (): void => {
    if (clicked == false) {
      setScore(0);
    } else {
      setScore(selectedScore);
    }
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.wrapper}>
          {[...Array(score)].map((e, i) => <button key={i + 1} onMouseOver={() => hoverStar(i + 1)}  onMouseLeave={() => clearStars()} onClick={() => onStarClick(i + 1)}><Icon key={i} name='star-on' width={STAR_WIDTH} height={STAR_SIZE} viewBox='3 0 10 18' /></button>)}
          {[...Array(MAX_STARS - score)].map((e, i) => <button key={i + score + 1} onMouseOver={() => hoverStar(i + score + 1)}><Icon key={i} name='star-off' width={STAR_WIDTH} height={STAR_SIZE} viewBox='3 0 10 18' /></button>)}
        </div>
      </div>
    </>
  );
};

export default StarSelector;
