import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { ReactElement, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import avatarImg from '../../../../../../assets/images/avatar.png';
import { PartnerSessionDetailsTabs } from '../../../../../enums/partner-session-details-tabs.enum';
import { ServiceType } from '../../../../../enums/service-type';
import { SessionFilesFolder } from '../../../../../enums/session-files-folder.enum';
import { SessionStatus } from '../../../../../enums/session-status.enum';
import { SessionType } from '../../../../../enums/session-type.enum';
import { useCopyToClipboard } from '../../../../../hooks/useCopyToClipboard';
import SessionsService from '../../../../../services/sessions/sessions.service';
import { getSingleSession } from '../../../../../store/actions/sessions.actions';
import { setIsShowGlobalLoader } from '../../../../../store/slices/global.slice';
import { setShowFilesModal } from '../../../../../store/slices/modals.slice';
import { selectCurrentlyBookingArtistConfig } from '../../../../../store/slices/musicians.slice';
import { selectSession } from '../../../../../store/slices/sessions.slice';
import { selectUser } from '../../../../../store/slices/user.slice';
import FeedbackSessionForm from '../../../../Molecules/FeedbackSessionForm/FeedbackSessionForm';
import Messages from '../../../../Molecules/Messages/Messages';
import WarningV2 from '../../../../Atoms/WarningV2/WarningV2';
import FileUploadDropzone from '../../../FileUploadDropzone/FileUploadDropzone';
import Icon from '../../../Icon/Icon';
import { attendanceLivestreamPartners, firstSessionMessage } from '../../SessionDetails.const';
import styles from '../../SessionDetails.module.scss';

interface PartnerSessionDetailsInterface {
  onPanelClose: () => void;
}

const PartnerSessionDetails = ({ onPanelClose }: PartnerSessionDetailsInterface): ReactElement => {
  const user = useSelector(selectUser, shallowEqual);
  const session = useSelector(selectSession, shallowEqual);
  const artistConfig = useSelector(selectCurrentlyBookingArtistConfig, shallowEqual);
  const selectedService =
    artistConfig.services.servicesList.find(({ value }) => value === session?.session_info?.options) || {};
  const formToggles = selectedService.form || {};
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(PartnerSessionDetailsTabs.SESSION_DETAILS);
  const [isShowFeedbackForm, setIsShowFeedbackForm] = useState(false);

  const copyToClipboard = useCopyToClipboard();

  const timezone = user.timezone || moment.tz.guess();

  const sessionTime = `${moment(session.start_date).tz(timezone).format('HH:mm')} (${timezone})`;
  const sessionDate = moment(session.start_date).tz(timezone).format("ddd DD MMM 'YY");

  const { tab } = useParams<{ tab: string }>();
  const tabs = Object.values(PartnerSessionDetailsTabs);

  useEffect((): void => {
    if (!tab) {
      return;
    }
    setCurrentTab(tab as PartnerSessionDetailsTabs);
  }, [tab]);

  const {
    id: sessionId,
    type: sessionType,
    status: sessionStatus,
    user: { avatar: sessionAvatar, name: sessionUserName },
    attendance: sessionAttendance,
    files_format: sessionFilesFormat,
    session_info: {
      options: sessionInstrument,
      title: sessionTrack,
      meter: sessionMeter,
      bpm: sessionMBpm,
      track_genre: sessionGenre,
      reference_track: sessionReferenceTrack,
      sampleRate
    }
  } = session;

  const isShowReviewButton =
    sessionType === SessionType.UPCOMING &&
    [
      SessionStatus.CLIENT_FILES_UPLOAD_REQUIRED,
      SessionStatus.CLIENT_FILES_PENDING_REVIEW,
      SessionStatus.CLIENT_FILES_UPDATE_REQUIRED,
      SessionStatus.PARTNER_READY_TO_GO
    ].includes(sessionStatus);

  const isShowReadyToGoButton = ![
    SessionStatus.PARTNER_READY_TO_GO,
    SessionStatus.PARTNER_UPLOAD_REQUIRED,
    SessionStatus.PARTNER_DELIVERABLES_READY
  ].includes(sessionStatus);

  const closeFeedbackForm = (): void => {
    setIsShowFeedbackForm(false);
    onPanelClose();
  };

  const openFileManager = (): void => {
    dispatch(setShowFilesModal({ isShown: true, folder: SessionFilesFolder.USER_FILES }));
  };

  const approveSession = async (): Promise<void> => {
    try {
      dispatch(setIsShowGlobalLoader(true));
      await SessionsService.approveSession(sessionId);
      await dispatch(getSingleSession(sessionId));
      onPanelClose();
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }
  };

  const renderHeader = (): ReactElement => (
    <div className={classNames(styles.header, styles.partnersHeader)}>
      <h3 className={styles.headerTitle}>
        {isShowFeedbackForm ? (
          'Leave feedback'
        ) : (
          <>
            Session{' '}
            <span
              onClick={() => copyToClipboard(window.location.href)}
              data-tip={'Copy to clipboard'}
              className={styles.headerTitleSessionID}
            >
              #{session.id}
            </span>
          </>
        )}
      </h3>

      <div className={styles.headerUser}>
        <p className={styles.headerUserName}>{sessionUserName}</p>
        <div className={styles.headerUserAvatar}>
          <img
            className={styles.headerUserAvatarImg}
            src={sessionAvatar || avatarImg}
            alt={sessionUserName}
            width={64}
            height={64}
          />
        </div>
      </div>
    </div>
  );

  const renderTabs = (): ReactElement => (
    <div className={styles.tabs}>
      {tabs.map(headerTab => (
        <div key={headerTab} className={classNames(styles.tabsItem, currentTab === headerTab && styles.isActive)}>
          <a onClick={(): void => setCurrentTab(headerTab)}>{headerTab}</a>
        </div>
      ))}
    </div>
  );

  const renderFeedbackForm = (): ReactElement => (
    <div className={styles.bodyContent}>
      <FeedbackSessionForm session={session} closeFeedbackForm={closeFeedbackForm} />
    </div>
  );

  const renderSessionDetailsRegularItem = ({
    icon,
    label,
    value
  }: {
    icon: string;
    label: string;
    value: string;
  }): ReactElement => (
    <div className={styles.item}>
      <div className={styles.icon}>
        <Icon name={icon} width={24} height={24} viewBox='0 0 24 24' />
      </div>
      <div>
        <h3>{label}</h3>
        <p>{value}</p>
      </div>
    </div>
  );

  const renderActionButtons = (): ReactElement => (
    <>
      <div className={styles.actionButtons}>
        {isShowReviewButton && (
          <a
            onClick={(): void => setIsShowFeedbackForm(true)}
            className={classNames(styles.actionButton, styles.activeActionButton, styles.reviewButton)}
          >
            Review
          </a>
        )}
        {isShowReadyToGoButton && (
          <a
            onClick={approveSession}
            className={classNames(styles.actionButton, styles.activeActionButton, styles.approveButton)}
          >
            Ready to go!
          </a>
        )}
      </div>
    </>
  );

  const renderSessionDetails = (): ReactElement => (
    <div className={styles.sessionDetails}>
      { session?.is_first_session && (
        <WarningV2
          icon={'warning'}
          content={firstSessionMessage}
        />
      )}
      <div className={styles.items}>
        <div className={styles.itemRow}>
          {renderSessionDetailsRegularItem({ icon: 'calendar', label: 'Date', value: sessionDate })}
          {renderSessionDetailsRegularItem({ icon: 'clock', label: 'Starts', value: sessionTime })}
        </div>
        <div className={styles.itemRow}>
          {selectedService.session_type === ServiceType.ALL &&
            renderSessionDetailsRegularItem({
              icon: 'live-stream',
              label: 'Attending Livestream',
              value: attendanceLivestreamPartners[sessionAttendance]
            })}
          {renderSessionDetailsRegularItem({
            icon: 'timer',
            label: 'Session Length',
            value: `${session.duration} min`
          })}
        </div>
        <div className={styles.itemRow}>
          {renderSessionDetailsRegularItem({ icon: 'music', label: 'Track', value: sessionTrack })}
          {(formToggles.track_genre ?? true) &&
            renderSessionDetailsRegularItem({
              icon: 'music-album',
              label: 'Genre',
              value: sessionGenre
            })}
        </div>
        <div className={styles.itemRow}>
          {(formToggles.meter ?? true) &&
            renderSessionDetailsRegularItem({
              icon: 'sound-wave',
              label: 'Meter',
              value: sessionMeter
            })}
          {(formToggles.bpm ?? true) &&
            renderSessionDetailsRegularItem({
              icon: 'bpm',
              label: 'BPM',
              value: sessionMBpm
            })}
        </div>
        <div className={styles.itemRow}>
          <div className={classNames(styles.item)}>
            <div className={styles.icon}>
              <Icon name={'folder-music'} width={24} height={24} viewBox='0 0 24 24' />
            </div>
            <div>
              <h3>Files Management</h3>
              <button onClick={openFileManager} className={styles.itemButton}>
                Review Session Files
              </button>
            </div>
          </div>
          {(formToggles.reference_track ?? true) && (
            <div className={styles.item}>
              <div className={styles.icon}>
                <Icon name={'folder-music'} width={24} height={24} viewBox='0 0 24 24' />
              </div>
              <div>
                <h3>Reference Track</h3>
                <p>
                  {sessionReferenceTrack ? (
                    <a href={sessionReferenceTrack} className={styles.referenceTrack} target='_blank' rel='noreferrer'>
                      {sessionReferenceTrack}
                    </a>
                  ) : (
                    'No reference track was provided'
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className={styles.itemRow}>
          {renderSessionDetailsRegularItem({
            icon: 'music',
            label: 'Service',
            value: sessionInstrument
          })}
          {(formToggles.deliverables ?? true) &&
            renderSessionDetailsRegularItem({
              icon: 'attach',
              label: 'Deliverables format',
              value: sessionFilesFormat
            })}
        </div>
        <div className={styles.itemRow}>
          {(formToggles.sample_rate ?? true) &&
            renderSessionDetailsRegularItem({
              icon: 'sound-wave',
              label: 'Sample Rate',
              value: sampleRate
            })}
          {renderSessionDetailsRegularItem({
            icon: 'reverse-time',
            label: 'Last Updated',
            value: moment(session.last_update).tz(timezone).format('DD MMM YYYY [at] HH:mm')
          })}
        </div>
      </div>
      {renderActionButtons()}
    </div>
  );

  return (
    <div className={styles.container}>
      {renderHeader()}
      {isShowFeedbackForm ? (
        renderFeedbackForm()
      ) : (
        <div className={styles.body}>
          {renderTabs()}
          <div
            className={classNames(
              currentTab !== PartnerSessionDetailsTabs.SESSION_DETAILS && styles.isHidden,
              styles.bodyContent
            )}
          >
            {renderSessionDetails()}
          </div>
          <div
            className={classNames(
              currentTab !== PartnerSessionDetailsTabs.MESSAGES && styles.isHidden,
              styles.bodyContent
            )}
          >
            <Messages />
          </div>
          <div
            className={classNames(
              currentTab !== PartnerSessionDetailsTabs.FILES_MANAGEMENT && styles.isHidden,
              styles.bodyContent
            )}
          >
            <FileUploadDropzone isAllowUpload={sessionStatus !== SessionStatus.CANCELLED} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerSessionDetails;
