export const genreOptions = [
  {
    label: 'Ambient',
    value: 'Ambient'
  },
  {
    label: 'Blues',
    value: 'Blues'
  },
  {
    label: 'Brazilian',
    value: 'Brazilian'
  },
  {
    label: 'Breakbeat',
    value: 'Breakbeat'
  },
  {
    label: 'Classical / Orchestral / Soundtrack',
    value: 'Classical / Orchestral / Soundtrack'
  },
  {
    label: 'Country',
    value: 'Country'
  },
  {
    label: 'Disco',
    value: 'Disco'
  },
  {
    label: 'Downtempo',
    value: 'Downtempo'
  },
  {
    label: 'Electronic Music',
    value: 'Electronic Music'
  },
  {
    label: 'Electronic Rock',
    value: 'Electronic Rock'
  },
  {
    label: 'Folk',
    value: 'Folk'
  },
  {
    label: 'Funk',
    value: 'Funk'
  },
  {
    label: 'Hip Hop / Rap',
    value: 'Hip Hop / Rap'
  },
  {
    label: 'Indian',
    value: 'Indian'
  },
  {
    label: 'Jazz',
    value: 'Jazz'
  },
  {
    label: 'Latin',
    value: 'Latin'
  },
  {
    label: 'Metal / Heavy Metal',
    value: 'Metal / Heavy Metal'
  },
  {
    label: 'Pop',
    value: 'Pop'
  },
  {
    label: 'Punk-Rock',
    value: 'Punk-Rock'
  },
  {
    label: 'Reggae',
    value: 'Reggae'
  },
  {
    label: 'Rock',
    value: 'Rock'
  },
  {
    label: 'R&B',
    value: 'R&B'
  },
  {
    label: 'Singer-songwriter',
    value: 'Singer-songwriter'
  },
  {
    label: 'Soul',
    value: 'Soul'
  },
  {
    label: 'World',
    value: 'World'
  }
];
