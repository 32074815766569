import React from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import InputError from '../../Atoms/InputError/InputError';
import RadioWide from '../../Atoms/RadioWide/RadioWide';
import { useDispatch } from 'react-redux';
import { setIsShowGlobalLoader } from '../../../store/slices/global.slice';
import { PartnerFeedbackOptions } from '../../../enums/partner-feedback-options.enum';
import { getSessions, getSingleSession } from '../../../store/actions/sessions.actions';
import SessionsService from '../../../services/sessions/sessions.service';
import styles from './FeedbackSessionForm.module.css';

export default function FeedbackSessionForm({ session, closeFeedbackForm }) {
  const dispatch = useDispatch();
  const feedback_notes = '';
  const formOptions = {
    defaultValues: {
      feedback_notes
    }
  };
  const { control, getValues, register, watch, handleSubmit, formState: { errors }, clearErrors } =
    useForm(formOptions);
  const watchSelection = watch('cancellation_reasons');

  const feedbackOptions = Object.values(PartnerFeedbackOptions);

  const showAdditionalNotes = reason =>
  [
    PartnerFeedbackOptions.CONTACT_SUPPORT
  ].includes(reason);

  const submitFeedbackReasons = reason =>
    ['approve', 'more-than-1-session', PartnerFeedbackOptions.MISSING_MATERIALS, 'poor-quality-materials'].includes(reason);

  const feedbackTextAndPlaceholders = {
    [PartnerFeedbackOptions.MISSING_MATERIALS]: [
      'Request a file update',
      'Change session status to "Files update required"'
    ],
    [PartnerFeedbackOptions.CONTACT_SUPPORT]: [
      'Report an issue',
      'Send a message to our support team reporting an issue with this session',
      'Provide details about the issue. Our support team will answer shortly.'
    ]
  };

  const onSubmit = async data => {
    dispatch(setIsShowGlobalLoader(true));
    const info = {
      session_id: session.id,
      feedback_type: data.cancellation_reasons,
      cancellation_reasons: data.cancellation_reasons,
      feedback_notes: data.feedback_notes
    };
    try {
      await SessionsService.sendFeedback(info);
      await dispatch(getSessions());
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }
    closeFeedbackForm();
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.form}>
        <label className={styles.cancellationFormTitle + ' ' + styles.label + ' ' + styles.labelInline}>
          What do you want to report about the session?
        </label>
        <div className={styles.sessionForm__radioInputGroup}>
          {feedbackOptions.map((option, index) => (
            <div className={styles.optionWrapper} key={index}>
              <RadioWide
                defaultChecked={index === 0}
                name='cancellation_reasons'
                control={control}
                id={index}
                value={option}
                label={feedbackTextAndPlaceholders[option][0]}
                description={feedbackTextAndPlaceholders[option][1]}
              />
            </div>
          ))}
        </div>

        {showAdditionalNotes(watchSelection) && (
          <div className={styles.cancellationForm__feedback}>
            <label className={styles.sessionForm__notes}>
              <textarea
                className={styles.input}
                id='feedback_notes'
                name='feedback_notes'
                type='textarea'
                placeholder={feedbackTextAndPlaceholders[PartnerFeedbackOptions.CONTACT_SUPPORT][2]}
                onClick={() => clearErrors()}
                {...register('feedback_notes',
                  watchSelection !== 'approve'
                    ? {
                      required: 'Required'
                    }
                    : {}
                )}
              />
              {errors.feedback_notes && <InputError message={errors.feedback_notes.message} />}
            </label>
          </div>
        )}

        {errors.server && (
          <>
            <InputError message={errors.server.message} />
            <p>Please use typeform instead: https://form.typeform.com/to/Sn5U3IxX</p>
          </>
        )}
      </div>
      <div
        className={styles.sessionForm__actions + ' ' + styles.sessionForm__actionsMultiple + ' ' + styles.isActive}
      >
        <a className={styles.sessionForm__actionsButtonOutline} onClick={() => closeFeedbackForm()}>
          {'<- Back'}
        </a>
        <button
          className={classNames(styles.sessionForm__actionsButton, !submitFeedbackReasons(watchSelection) && styles.isDanger)}
          type='submit'
        >
          {` ${submitFeedbackReasons(watchSelection) ? 'Submit Feedback' : ' Request Support'}`}
        </button>
      </div>
    </form>
  );
}
