import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import { RootState } from 'app/store/store';

import { ReferralDataInterface } from '../../interfaces/referral-data.interface';
import { ReferralInterface } from '../../services/referral/referral.interface';
import { createReferral, getReferralData, getReferrals, sendReminder } from '../actions/referral.actions';
import { SlicesNames } from './slices-names.enum';

type SliceState = {
  referrals: ReferralInterface[];
  referralData: ReferralDataInterface;
};

export const referral = createSlice({
  name: SlicesNames.REFERRAL_SLICE,
  initialState: {
    referrals: [],
    referralData: null
  },
  reducers: {},
  extraReducers: {
    [getReferrals.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<ReferralInterface[]>): void => {
      state.referrals = payload;
    },
    [createReferral.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<ReferralInterface>): void => {
      state.referrals = [...state.referrals, payload];
    },
    [sendReminder.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<ReferralInterface>): void => {
      state.referrals = state.referrals.map(ref => (ref.id === payload.id ? merge(ref, payload) : ref));
    },
    [getReferralData.fulfilled.type]: (state: SliceState, { payload }: PayloadAction<ReferralDataInterface>): void => {
      state.referralData = payload;
    }
  }
});

export const selectReferralData = (state: RootState) => state.referralSlice.referralData;
export const selectReferrals = (state: RootState) => state.referralSlice.referrals;

export default referral.reducer;
