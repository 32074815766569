import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Routes } from '../../../enums/routes.enum';
import { ModalInterface } from '../../../interfaces/modal.interface';
import { setIsShowBlockingModal } from '../../../store/slices/modals.slice';
import Button from '../../Atoms/Button/Button';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import styles from './BlockingModal.module.scss';

const BlockingModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onRequestClose = (): void => {
    dispatch(setIsShowBlockingModal(false));
  };

  const onOpenBillingAreaClick = (): void => {
    history.push(Routes.BILLING);
    dispatch(setIsShowBlockingModal(false));
  };

  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onRequestClose} className={styles.modal}>
      <h1>Oops!</h1>
      <p className={styles.description}>
        You’ve reached your credit limit. To continue booking, upgrade your plan or contact{' '}
        <a href='mailto:support@musiversal.com.'>support@musiversal.com</a>.
      </p>
      <Button onClick={onOpenBillingAreaClick} className={styles.button} value={'Open Billing Area'} />
    </ModalWrapper>
  );
};

export default BlockingModal;
