import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import React, { ReactElement, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { localStorageKeys } from '../../../../../constants/localStorageKeys.const';
import { PaymentProviders } from '../../../../../enums/payment-providers.enum';
import { ModalWarningTypes } from '../../../../../enums/modal-types.enum';
import { UserStatuses } from '../../../../../enums/user-statuses.enum';
import { StrapiIcons } from '../../../../../enums/strapi-icons.enum';
import { getUserCreditsLeftHelper } from '../../../../../helpers/get-user-credits-left.helper';
import { useIsMobileResolution } from '../../../../../hooks/useIsMobileResolution';
import SubscriptionService from '../../../../../services/subscriptions/subscriptions.service';
import { getOffboardingModalData } from '../../../../../store/actions/modals.actions';
import { selectCurrentPlan, setIsShowGlobalLoader } from '../../../../../store/slices/global.slice';
import {
  setIsShowOffboardingModal,
  setIsShowPlansModal,
  setIsUpsellPlansModal,
  setIsShowTopUpModal,
  setIsShowBillingAddressModal
} from '../../../../../store/slices/modals.slice';
import { selectUser } from '../../../../../store/slices/user.slice';
import Icon from '../../../../Atoms/Icon/Icon';
import { WARNING_MESSAGE } from './BillingDetails.const';
import styles from './BillingDetails.module.scss';

interface BillingDetailsInterface {
  creditsUsed: number;
  creditsTotal: number;
  isTrial: boolean;
  finishTrial: () => void;
  restartBillingCycle: () => void;
  redirectToPortal: () => void;
  paymentsProvider: PaymentProviders;
}

const restartTooltipContent =
  'Need more credits? Pay now to restart your billing cycle<br> and get your credits instantly.';
const startTooltipContent =
  'Need more credits? Finish off your trial and get your<br> credits instantly by initiating your membership.';

const showInvoiceStatuses = [UserStatuses.PENDING_CANCELLATION, UserStatuses.SUBSCRIBER, UserStatuses.WIX_USER];

const BillingDetails = ({
  isTrial,
  finishTrial,
  restartBillingCycle,
  paymentsProvider = PaymentProviders.STRIPE,
  redirectToPortal
}: BillingDetailsInterface): ReactElement => {
  const user = useSelector(selectUser, shallowEqual);
  const history = useHistory();
  const dispatch = useDispatch();
  const currentPlan = useSelector(selectCurrentPlan, shallowEqual);
  const { restartYourBillingCycleNow, allowTrialCancellations, blackFridaySale } = useFlags();
  const isMobileResolution = useIsMobileResolution();

  const timezone = user.timezone || moment.tz.guess();

  const nextBillingDate = user.subscription?.nextBillingDate;
  const isLegacy = user.subscription?.legacy;
  const isPendingCancellation = user.status === UserStatuses.PENDING_CANCELLATION;
  const isCancelled = user.status === UserStatuses.CANCELLED;
  const isShowTopUpButton = user.allow_topups;
  const allowTopups = user.allow_topups;

  const { country } = user;

  const { zipcode, address, city, company_name } = user.billing_address || {};

  const showCancelSubscriptionStatuses = useMemo((): UserStatuses[] => {
    const statuses = [UserStatuses.SUBSCRIBER, UserStatuses.WIX_USER];
    if (allowTrialCancellations) {
      statuses.push(UserStatuses.TRIAL);
    }
    return statuses;
  }, [allowTrialCancellations]);

  const resumeSubscription = async (): Promise<void> => {
    dispatch(setIsShowGlobalLoader(true));
    localStorage.setItem(localStorageKeys.USER_STATUS, user.status);
    try {
      await SubscriptionService.renewSubscription();
      history.push('/profile#billing?session_id=true');
    } catch (e) {
      dispatch(setIsShowGlobalLoader(false));
      console.error(e);
    }
  };

  const cancelSubscription = async (): Promise<void> => {
    dispatch(setIsShowGlobalLoader(true));
    await dispatch(getOffboardingModalData());
    dispatch(setIsShowGlobalLoader(false));
    dispatch(setIsShowOffboardingModal(true));
  };

  const topUpCredits = (): void => {
    if (!allowTopups) {
      return;
    }
    if (getUserCreditsLeftHelper(user) == 0) {
      dispatch(setIsShowTopUpModal({ isShown: true, warningType: ModalWarningTypes.WARNING, message: WARNING_MESSAGE }));
    } else {
      dispatch(setIsShowTopUpModal({ isShown: true }));
    }
  };

  const changeBillingAddress = (): void => {
    dispatch(setIsShowBillingAddressModal(true));
  };

  const openPlansModal = (): void => {
    if (!isLegacy) {
      // if (blackFridaySale) {
      //   dispatch(setIsUpsellPlansModal(true));
      // } else {
      //   dispatch(setIsShowPlansModal(true));
      // }
      dispatch(setIsUpsellPlansModal(true));
    } else {
      dispatch(setIsUpsellPlansModal(true));
    }
  };

  const renderActionButton = (): ReactElement => {
    switch (user.status) {
      case UserStatuses.TRIAL:
        return (
          <div className={styles.buttonContainer}>
            <div className={styles.tooltip} data-tip={!isMobileResolution ? startTooltipContent : ''}>
              <Icon name={'question-mark'} />
            </div>
            <button className={styles.renewSubscriptionButton} onClick={finishTrial}>
              {'Start membership now'}
            </button>
          </div>
        );
      case UserStatuses.SUBSCRIBER:
        return (
          restartYourBillingCycleNow && (
            <div className={styles.buttonContainer}>
              <div className={styles.tooltip} data-tip={!isMobileResolution ? restartTooltipContent : ''}>
                <Icon name={'question-mark'} />
              </div>
              <button className={styles.renewSubscriptionButton} onClick={restartBillingCycle}>
                {'Restart Billing Cycle now'}
              </button>
            </div>
          )
        );
      case UserStatuses.PENDING_CANCELLATION:
        return (
          <>
            {paymentsProvider === PaymentProviders.STRIPE && !user.subscription?.legacy && (
              <div className={styles.buttonContainer}>
                <button className={styles.renewSubscriptionButton} onClick={resumeSubscription}>
                  {'Resume membership'}
                </button>
              </div>
            )}
          </>
        );
      case UserStatuses.CANCELLED:
        return (
          <div className={styles.buttonContainer}>
            <button className={styles.renewSubscriptionButton} onClick={openPlansModal}>
              {'Start membership'}
            </button>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.group}>
        <h3 className={styles.title}>
          MANAGE MEMBERSHIP
          {isTrial && <span> (14 day free trial)</span>}
          {isLegacy && <span> (legacy)</span>}
          {isCancelled && <span> (CANCELED)</span>}
        </h3>

        <div className={styles.item}>
          {isCancelled ? (
            <span>Previous Plan</span>
          ) : (
            <div className={styles.flexItemTitle}>
              Current Plan
              <a onClick={openPlansModal} className={styles.smallLink}>
                Edit
              </a>
            </div>
          )}
          <div className={styles.planNameContainer}>
            {isLegacy ? (
              <strong>{user.status === UserStatuses.ONDEMAND ? 'On demand' : (user.subscription?.currentProduct || 'Unknown Plan')}</strong>
            ) : (
              <strong>{user.status === UserStatuses.ONDEMAND ? 'On demand' : (currentPlan?.title ||  'Not Subscribed')}</strong>
            )}
            {user.status === UserStatuses.ONDEMAND && (
              <div onClick={openPlansModal} className={styles.planNameContainerComment}>
                Become a member
              </div>
            )}
          </div>
        </div>

        {!isCancelled && user.status !== UserStatuses.ONDEMAND &&  (
          <div className={styles.item}>
            <div className={styles.flexItemTitle}>
              Payment Method:
              <a onClick={redirectToPortal} className={styles.smallLink}>
                Edit
              </a>
            </div>
            <div className={styles.paymentMethod}>
              <div className={styles.paymentProvider}>
                <span>{paymentsProvider === PaymentProviders.PAYPAL ? 'PayPal' : 'Credit Card'}</span>
                <Icon name={paymentsProvider === PaymentProviders.PAYPAL ? 'paypalInactive' : 'creditCardInactive'} />
              </div>
              {nextBillingDate && (
                <div className={styles.smallItem}>
                  <span>{isPendingCancellation ? 'Cancelation Date' : 'Next Billing Date'}:</span>
                  <span>{moment(nextBillingDate).tz(timezone).format('MM/DD/YYYY')}</span>
                </div>
              )}
            </div>
          </div>
        )}

        {!isCancelled && (
          <div className={styles.item}>
            <div className={styles.flexItemTitle}>Available Credits</div>
            <strong>{getUserCreditsLeftHelper(user)}</strong>
          </div>
        )}

        <div className={styles.item}>
          {showCancelSubscriptionStatuses.includes(user.status) && (
            <a onClick={cancelSubscription} className={styles.smallLink}>
              Cancel membership
            </a>
          )}
          <div className={styles.actionButtons}>
            {renderActionButton()}
            {isShowTopUpButton && (
              <button
                className={classnames(
                  styles.renewSubscriptionButton,
                  !allowTopups && styles.disabledButton,
                  styles.topUpButton
                )}
                onClick={topUpCredits}
                data-tip={!allowTopups ? 'For members only' : ''}
              >
                {user.status === UserStatuses.ONDEMAND ? 'Buy credits on demand' : 'Get more credits'}
              </button>
            )}
          </div>
        </div>
      </div>

      <div className={styles.group}>
        <h3 className={styles.title}>BILLING INFORMATION</h3>

        <div className={styles.item}>
          <div className={styles.flexItemTitle}>
            Billing Address:
            <a onClick={changeBillingAddress} className={styles.smallLink}>
              Edit
            </a>
          </div>
          <div className={styles.paymentMethod}>
            <div className={styles.paymentProvider}>
              <span>{company_name}</span>
            </div>
            {nextBillingDate && (
              <div className={styles.smallItem}>
                <span>{`${address}, ${zipcode} ${city}, ${country}`}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        {showInvoiceStatuses.includes(user.status) && (
          <p>
            Do you need an invoice?{' '}
            <span>
              <a rel='noreferrer' href='https://musiversal.typeform.com/to/qTzTovUE' target='_blank'>
                Please fill out this form
              </a>
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default BillingDetails;
