import mixpanel from 'mixpanel-browser';

import { MixPanelEventTypes } from '../enums/mixpanel-events.enum';
import { MixPanelParamMap } from '../interfaces/mixpnale-event-params.interface';

function trackEvent(
  type: MixPanelEventTypes,
  params?: MixPanelParamMap[MixPanelEventTypes],
  callback?: (args?: any) => void
): void {
  mixpanel.track(type, params, callback);
}

export const Mixpanel = {
  track: trackEvent
};
