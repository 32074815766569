export const attendanceLivestream = {
  true: 'Attending livestreaming',
  false: 'Not attending livestreaming',
  'offline': 'No livestream'
};

export const slotSessionTypes = {
  ALL: 'all',
  OFFLINE: 'offline',
  LIVE: 'live'
};