import React, { forwardRef, MutableRefObject, ReactElement } from 'react';

import Icon from '../Icon/Icon';
import styles from './DateRangeInput.module.scss';

interface RangeInputInterface {
  onClear: () => void;
  onClick?: () => void;
  value?: string;
}

const DateRangeInput = forwardRef(
  ({ value, onClick, onClear }: RangeInputInterface, ref: MutableRefObject<HTMLInputElement>): ReactElement => (
    <div className={styles.container}>
      <input
        className={styles.input}
        defaultValue={value}
        onClick={onClick}
        placeholder='Date Range'
        readOnly
        ref={ref}
      />
      <div className={styles.icons}>
        <Icon name={'xClose'} className={styles.closeIcon} onClick={onClear} />
        <Icon name={'dropdownArrow'} className={styles.dropdownIcon} onClick={onClick} />
      </div>
    </div>
  )
);

DateRangeInput.displayName = 'RangeInput';
export default DateRangeInput;
