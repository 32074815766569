import classNames from 'classnames';
import cookieCutter from 'cookie-cutter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Avatar from '../Avatar/Avatar.js';
import CreditsDisplay from '../CreditsDisplay/CreditsDisplay';
import styles from './Navbar.module.scss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { selectBannerHeight, selectIsPartnersDashboard } from '../../../store/slices/global.slice';
import { useIsMobileResolution } from '../../../hooks/useIsMobileResolution';
import { selectUser } from '../../../store/slices/user.slice';
import Icon from '../Icon/Icon';
import { isAllowedReferralHelper } from '../../../helpers/is-allowed-referral.helper';

const billingAreaPath = '/profile#billing';

const Navbar = props => {
  const user = useSelector(selectUser);
  const bannerHeight = useSelector(selectBannerHeight);
  const isMobileResolution = useIsMobileResolution();
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => setIsActive(!isActive);
  const { getFullAccessNavButton } = useFlags();
  const history = useHistory();
  const location = useLocation();
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);

  useEffect(() => {
    setIsActive(false);
  }, [location]);

  const logout = async () => {
    // We delete all cookies
    cookieCutter.set('userToken', null, { expires: new Date(0) });
    localStorage.removeItem('avatar');
    history.push('/logout');
  };

  const isGetFullAccessButtonVisible = useMemo(
    () => getFullAccessNavButton && props.isTrial,
    [props.isTrial, getFullAccessNavButton]
  );

  useEffect(() => {
    const pageClickEvent = e => {
      // If the active element exists and is clicked outside of
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);

  const onGetFullAccessButtonClick = () => history.push(billingAreaPath);

  const contactSupportLink = useMemo(() =>
    `https://share.hsforms.com/18FoFHA41TZ-c-Dku-Wpcpg4zn1a?__hstc=22948580.d84bcc8487e7c4952ad4187adc305fc0.1637051653108.1641209517908.1641290813033.70&__hssc=22948580.1.1641290813033&__hsfp=7147803&your_name=${user.name}&email=${user.email}`, [user.name, user.email]);

  return (
    <>
      <div
        className={classNames(
          styles.navbar,
          props.spaceBottom === false && styles.noMargin,
          isGetFullAccessButtonVisible && styles.mobileMargin
        )}
        style={{ top: `${bannerHeight}px` }}
      >
        <Link to='/explore' className={styles.navbarLogo}>
          <Icon name={'navbarLogo'} />
        </Link>
        <nav className={styles.navbarNav}>
          <Link
            to='/sessions'
            className={styles.navbarNavLink + ' ' + (props.currentPath === 'sessions' ? styles.isActive : '')}
          >
            My Sessions
          </Link>
          {!isPartnersDashboard && (
            <Link
              to='/explore'
              className={styles.navbarNavLink + ' ' + (props.currentPath === 'musicians' ? styles.isActive : '')}
            >
              Explore
            </Link>
          )}
        </nav>
        <div className={styles.dropdown__container}>
          {!isPartnersDashboard && !isMobileResolution && (
            <div className={styles.creditsDisplay}>
              <CreditsDisplay
                useIsMobileResolution={useIsMobileResolution}
              />
            </div>
          )}
          {!isPartnersDashboard && isGetFullAccessButtonVisible && (
            <button onClick={onGetFullAccessButtonClick} className={styles.getFullAccessButton}>
              Get full access
            </button>
          )}
          <button onClick={onClick} className={styles.dropdown__trigger}>
            <Avatar />
          </button>
          <nav ref={dropdownRef} className={styles.dropdown__menu + ' ' + (isActive ? styles.active : styles.inactive)}>
            <ul>
              {!isPartnersDashboard && isMobileResolution && (
              <li>
                <div className={styles.mobileCreditsDisplay}>
                  <CreditsDisplay
                    isMobileResolution={isMobileResolution}
                  />
                </div>
              </li>
              )}
              <li>
                <Link to='/profile#profile'>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'profile'} />
                  </span>
                  Profile
                </Link>
              </li>
              {isAllowedReferralHelper(user.status, isPartnersDashboard) && <li>
                <Link to='/referral'>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'referral'} />
                  </span>
                  Referral
                </Link>
              </li>}
              <li>
                <a href='https://help.musiversal.com/' target='_blank' rel='noreferrer'>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'helpCenter'} />
                  </span>
                  Help Center
                </a>
              </li>
              <li>
                <a href={contactSupportLink} target='_blank' rel='noreferrer'>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'contactSupport'} />
                  </span>
                  Contact Support
                </a>
              </li>
              <li>
                <a onClick={logout}>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'logout'} />
                  </span>
                  Logout
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Navbar;
