import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import styles from './StarRating.module.scss';

const MAX_STARS = 5;

interface StarRatingInterface {
  rating: any;
  onOpenRatingButtonClick: any;
  isMobileResolution: boolean;
}

const StarRating = ({ rating, onOpenRatingButtonClick, isMobileResolution }: StarRatingInterface): ReactElement => {
  const { rating: ratingScore = 0, status } = rating || {};
  const STAR_SIZE = isMobileResolution ? 30 : 18;
  const STAR_POS = isMobileResolution ? 30 : 18;
  return (
    <>
      {status == 'rated' && (
        <div className={classNames(styles.content, status)}>
          <div className={styles.wrapper}>
            {[...Array(ratingScore)].map((e, i) => <Icon key={i} name='star-on' width={STAR_SIZE} height={STAR_SIZE} viewBox={`0 0 ${STAR_POS} ${STAR_SIZE}`} />)}
            {[...Array(MAX_STARS - ratingScore)].map((e, i) => <Icon key={i+STAR_SIZE} name='star-off' width={STAR_SIZE} height={STAR_SIZE} viewBox={`0 0 ${STAR_POS} ${STAR_SIZE}`} />)}
          </div>
        </div>
      )}
      {status == null && (
        <div data-tip={'Rate this session'}>
          <div className={styles.contentUnrated}>
            <button
              onClick={onOpenRatingButtonClick}
              className={styles.wrapper}
            >
                {[...Array(MAX_STARS)].map((e, i) => <Icon key={i} name='star-off' width={STAR_SIZE} height={STAR_SIZE} viewBox={`0 0 ${STAR_POS} ${STAR_SIZE}`} />)}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default StarRating;
