import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import styles from './PlanSelectionModal.module.scss';
import { useHistory } from 'react-router-dom';
import { selectUser } from '../../../store/slices/user.slice';
import { translateUserSubscription } from '../../../helpers/translate-user-subscription';
import SubscriptionsService from '../../../services/subscriptions/subscriptions.service';
import SubscriptionPlanList from '../../Atoms/SubscriptionPlanList/SubscriptionPlanList';
import { setIsShowBillingModal } from '../../../store/slices/modals.slice';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import { finishTrialHelper } from '../../../helpers/finish-trial.helper';
import { useToast } from '../../../hooks/useToast';

let stripePromise = null;
if (process.env.REACT_APP_STRIPE_ID) {
  import('@stripe/stripe-js').then(({ loadStripe }) => (stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ID)));
}

const PlanSelectionModal = ({ isOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);

  const { sendToast } = useToast();

  const {
    isTrial,
    tierNumber
  } = translateUserSubscription(user);

  const [customerPortalURL, setCustomerPortalURL] = useState(null);

  const closeBillingModal = () => {
    dispatch(setIsShowBillingModal(false));
  };

  const getCustomerPortalUrl = async () => {
    try {
      const returnUrl = `${window.location.origin}/profile#billing`;
      const res = await SubscriptionsService.createCustomerPortalSession(returnUrl);
      setCustomerPortalURL(res.data.dashboard_url);
    } catch (err) {
      console.error(err);
    }
  };

  const finishTrial = async () => {
    await finishTrialHelper({ dispatch, user, sendToast, history });
  };

  useEffect(() => {
    getCustomerPortalUrl().finally();
  }, []);

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={closeBillingModal}
      className={styles.modal}
    >
      <div className={styles.container}>
        <h1 className={styles.title}>You need to upgrade your account</h1>
        <p className={styles.description}>
          You’ve reached your credits limit. To continue booking more sessions, please upgrade your plan or contact <a
          href='mailto:support@musiversal.com.'>support@musiversal.com</a>.
        </p>

        <SubscriptionPlanList
          user={user}
          customerPortalURL={customerPortalURL}
          isLegacy={user.subscription.legacy}
          isTrial={isTrial}
          tierNumber={Number(tierNumber)}
          finishTrial={finishTrial}
          stripePromise={stripePromise}
          isPlanSelectionModal
        />
      </div>
    </ModalWrapper>
  );
};

export default PlanSelectionModal;
