import axios, { AxiosPromise } from 'axios';

import { CancelReasonInterface } from '../../interfaces/cancel-reason.interface';

const SUBSCRIPTIONS_API = `${process.env.REACT_APP_API_URL}/api/v2/subscriptions/paypal`;

const axiosInstance = axios.create({
  baseURL: SUBSCRIPTIONS_API,
  withCredentials: true
});

const createPaypalSubscription = (priceId: string): AxiosPromise =>
  axiosInstance.post('/create-subscription', { priceId });

const enablePaypalSubscription = (priceId: string): AxiosPromise =>
  axiosInstance.post('/start-subscription', { priceId });

const upgradeSubscription = (priceId: string): AxiosPromise => axiosInstance.post('/upgrade-subscription', { priceId });

const downgradeSubscription = (priceId: string): AxiosPromise =>
  axiosInstance.post('/downgrade-subscription', { priceId });

const cancelSubscription = (reason: CancelReasonInterface): AxiosPromise =>
  axiosInstance.post('/cancel-subscription', { reason });

const checkSubscription = (subscriptionId: string): AxiosPromise<{ isActive: boolean }> =>
  axiosInstance.post('/check-subscription', { subscriptionId });

const createTopUp = (amount: number): AxiosPromise => axiosInstance.post('/create-topup', { amount });

const purchaseBundle = (bundle_id: number, country: string): AxiosPromise => axiosInstance.post('/purchase-bundle', { bundle_id, country });

export default {
  createPaypalSubscription,
  enablePaypalSubscription,
  upgradeSubscription,
  downgradeSubscription,
  cancelSubscription,
  checkSubscription,
  createTopUp,
  purchaseBundle
};
