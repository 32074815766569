import React, { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Button_v2 from '../../components/Atoms/Button_v2/button';
import Icon from '../../components/Atoms/Icon/Icon';
import SignUpFlowNavbar from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar';
import { ButtonSizes } from '../../enums/button-sizes.enum';
import styles from './ThankYou.module.scss';
import { bookSessionText, paragraphText } from './thank-you.const';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/user.slice';
import useIsPartnerGuard from '../../guards/useIsPartnerDashboard.guard';
import { getMe } from '../../store/actions/user.actions';

const ThankYou = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);
  const { studioTrialsOnSignup } = useFlags();

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  const proceedToStudio = async () => {
    dispatch(getMe());
    console.log('--> ', user);
    history.push('/explore');
  };

  useIsPartnerGuard();

  return (
    <div className={styles.container}>
      <SignUpFlowNavbar isFinalStep />
      <div className={styles.leftImage}>
        <div className={styles.bg}/>
      </div>
      <div className={styles.rightImage}>
        <div className={styles.bg}/>
      </div>
      <div className={styles.content}>
        <Icon name={'green-tick'} />
        <h2>Nice, {user.name.split(' ')[0]}!</h2>
        <p>{paragraphText}</p>
        <Button_v2
          onClick={proceedToStudio}
          buttonSize={ButtonSizes.MEDIUM}
          buttonText={bookSessionText(studioTrialsOnSignup)}
        />
      </div>
    </div>
  );
};

export default ThankYou;
