import { PaymentProviders } from '../../../enums/payment-providers.enum';
import { UserStatuses } from '../../../enums/user-statuses.enum';

export const showPaymentMethodModalStatuses = [UserStatuses.WIX_USER, UserStatuses.CANCELLED];

export const paymentMethodsData = [
  {
    name: 'Credit Card',
    icon: 'creditCardInactive',
    provider: PaymentProviders.STRIPE
  },
  {
    name: 'PayPal',
    icon: 'paypalInactive',
    provider: PaymentProviders.PAYPAL
  }
];
