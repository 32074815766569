import React, { useState } from 'react';

export default function MusicianImage({ id, avatar, name, altUrl, width = 64, height = 64 }) {
  const [imgSrc, setImgSrc] = useState(null);

  const useCacheOnURL = url => url.replace(/musiversal-cms.s3.eu-west-2.amazonaws.com/, 'dfulleue7uvkm.cloudfront.net');

  return (
    <>
      {imgSrc ? (
        <img
          key={id}
          src={imgSrc}
          alt={name}
          width={width}
          height={height}
          loading='eager'
          onError={() => {
            setImgSrc('/images/musicians/avatar.png');
          }}
        />
      ) : (
        <img
          key={id}
          src={avatar}
          alt={name}
          width={width}
          height={height}
          loading='eager'
          onError={() => {
            setImgSrc(altUrl);
          }}
        />
      )}
    </>
  );
}
