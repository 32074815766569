import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from '../../../Atoms/Button/Button.js';
import InputError from '../../../Atoms/InputError/InputError';
import styles from './BasicInfoForm.module.css';
import { useDispatch } from 'react-redux';
import avatarImg from '../../../../../assets/images/avatar.png';
import UserService from '../../../../services/user/user.service';
import { hideToast, showToast } from '../../../../store/slices/toast.slice';
import { setUser } from '../../../../store/slices/user.slice';
import TimezoneSelect from '../../../Atoms/TimezonePicker/v2/TimezoneSelect';
import XRegExp from 'xregexp';

export default function BasicInfoForm({ user }) {
  const dispatch = useDispatch();
  const { register, handleSubmit, setError, control, formState: { errors } } = useForm({
    defaultValues: {
      timezone: { value: user.timezone, label: user.timezone }
    }
  });

  const sendToast = (message, toastType, delay = 4000) => {
    const payload = {
      message,
      toastType
    };
    dispatch(showToast(payload));
    setTimeout(() => {
      dispatch(hideToast());
    }, delay);
  };

  const [avatar, setAvatar] = useState(user.avatar ?? avatarImg);

  const allowedImageFormats = ['image/jpeg', 'image/png', 'image/jng', 'image/webp', 'image/svg+xml'];
  const handleImageChange = async event => {
    if (allowedImageFormats.includes(event.target.files[0]?.type) && event.target.files[0].size <= 5000000) {
      setAvatar(URL.createObjectURL(event.target.files[0]));
      try {
        if (event.target.files[0].length !== 0 && event.target.files[0]) {
          const image = new FormData();
          image.append('avatar', event.target.files[0]);
          const resImage = await UserService.uploadAvatar(image);
          setAvatar(resImage.data.avatar);
          await dispatch(setUser({ ...user, avatar: resImage.data.avatar }));
          sendToast('Image updated.', 'success');
        }
      } catch (err) {
        console.error(err);
        setError('avatar', {
          type: 'manual',
          message: 'We couldn\'t upload your avatar. Please try again later.'
        });
      }
    } else {
      setError('avatar', {
        type: 'manual',
        message:
          'Sorry, that format is not allowed. You can upload images <5MB and of .png, .jpg, .jpeg, .webp and .svg format.'
      });
    }
  };

  const onSubmit = async data => {
    const info = {
      user: {
        timezone: data.timezone.value,
        name: data.name
      }
    };

    try {
      const res = await UserService.updateMe(info);
      await dispatch(setUser({ ...user, ...res.data.user }));
      sendToast('Changes saved.', 'success');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.profileBasic__avatar}>
          <img src={avatar} />

          <label className={styles.profileBasic__avatarIcon}>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
              <g strokeLinecap='round' strokeLinejoin='round' fill='#ffffff' stroke='#ffffff'>
                <line
                  datacap='butt'
                  datacolor='color-2'
                  x1='12'
                  y1='23'
                  x2='12'
                  y2='10'
                  fill='none'
                  strokeMiterlimit='10'
                />
                <polyline datacolor='color-2' points='8 14 12 10 16 14' fill='none' strokeMiterlimit='10' />
                <path
                  d='M16,18.777A9,9,0,1,0,5.046,9.1,5,5,0,0,0,6,19H8'
                  fill='none'
                  stroke='#ffffff'
                  strokeMiterlimit='10'
                />
              </g>
            </svg>
            <input
              className={styles.profileBasic__avatarInput}
              type='file'
              name='avatar'
              id='avatar'
              onChange={handleImageChange}
            />
          </label>
        </div>
        {errors.avatar && (
          <>
            <InputError message={errors.avatar.message} />
          </>
        )}

        <label className={styles.label + ' ' + styles.mbMedium + ' ' + styles.mtMedium}>
          {' '}
          Name
          <input
            className={styles.input}
            name='name'
            type='text'
            {...register('name', {
              required: 'This field is required',
              pattern: {
                // eslint-disable-next-line new-cap
                value: XRegExp('^(?!\\s)([\\pL\'-]+\\s)+([\\pL\'-])+$'),
                message: 'Your first name and last name are required'
              }
            })}
            defaultValue={user.name}
          />
          {errors.name?.message && <span className={styles.error}>{errors.name?.message}</span>}
        </label>
        <label className={styles.label + ' ' + styles.mbMedium}>
          {' '}
          Email
          <input
            className={styles.input + ' ' + styles.inputDisabled}
            name='email'
            type='email'
            {...register('email')}
            disabled
            defaultValue={user.email}
            required
          />
          {errors.email && <span className={styles.error}>This field is required</span>}
        </label>

        <TimezoneSelect control={control} containerClassName={styles.timezoneContainer} />
        {errors.timezone && <span className={styles.error}>This field is required</span>}

        <Button value='Save changes' />
      </form>
    </>
  );
}
