import React, { ReactElement } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectOnboardingModalData } from '../../../../../store/slices/modals.slice';
import BackButton from '../../../../Atoms/BackButton/BackButton';
import Button from '../../../../Atoms/Button/Button';
import RadioWide from '../../../../Atoms/RadioWide/RadioWide';
import { OnboardingStepInterface, Steps } from '../../OnboardingModal';
import styles from './PersonaLevelStep.module.scss';

const PersonaLevelStep = ({ goToStep, control, getValues }: OnboardingStepInterface): ReactElement => {
  const {
    Step4: { options, title }
  } = useSelector(selectOnboardingModalData, shallowEqual);

  return (
    <>
      <p className={styles.title}>{title}</p>
      {options.map(({ id, label, value }) => (
        <div key={id}>
          <RadioWide required name='personaLevel' control={control} id={id} value={value} label={label} />
        </div>
      ))}
      <div className={styles.buttons}>
        <BackButton onClick={goToStep(Steps.PERSONA_TYPE)} />
        <Button
          disabled={!getValues().personaLevel}
          className={styles.nextButton}
          onClick={goToStep(Steps.PERSONA_DIY)}
          value={'Next'}
        />
      </div>
    </>
  );
};

export default PersonaLevelStep;
