import classNames from 'classnames';
import React, { ReactElement } from 'react';

import styles from './Input.module.scss';

interface InputInterface {
  label?: string;
  className?: string;
  containerClassName?: string;
  isDisabled?: boolean;
  register?: any;
}

const Input = ({ label, className, containerClassName, isDisabled, register }: InputInterface): ReactElement => (
  <label className={classNames(styles.label, containerClassName)}>
    {label}
    <input className={classNames(styles.input, className)} disabled={isDisabled} {...register} />
  </label>
);
export default Input;
