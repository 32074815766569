import React, { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import TagManager from 'react-gtm-module';

import { MixPanelSignUpEvents } from '../../../enums/mixpanel-events.enum';
import { MixPanelChoseSignUpMethodProperties } from '../../../enums/mixpanel-properties.enum';
import { SignupMethods } from '../../../enums/signup-methods.enum';
import { Mixpanel } from '../../../helpers/mixpanel.helper';
import Icon from '../Icon/Icon';
import styles from './GoogleLoginButton.module.scss';

const GoogleLoginButton = props => {
  const [error, setError] = useState(null);

  const handleGoogleLogin = async googleData => {
    Mixpanel.track(MixPanelSignUpEvents.CHOSE_SIGN_UP_METHOD, {
      [MixPanelChoseSignUpMethodProperties.METHOD]: SignupMethods.GOOGLE
    });

    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v2/auth/google`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        token: googleData.tokenId,
        referral_code: props.referral_code
      }),
      withCredentials: true
    });
    try {
      const response = await res.json();
      if (response.status === 422) {
        props.setError('provider', {
          type: 'manual',
          message: 'You need to use a different login provider.'
        });
      }
      // We want to fire it only when user is creating an account
      if (props.isSignUp) {
        Mixpanel.track(MixPanelSignUpEvents.COMPLETED_SIGN_UP);

        TagManager.dataLayer({
          dataLayer: {
            event: 'createAccountGoogle',
            signupType: 'user'
          }
        });
      }

      props.callback();
    } catch (err) {
      console.error(err);
    }
  };

  const handleError = error => {
    setError({ error });
  };

  const onButtonClick = () => {
    if (!props.buttonText) {
      Mixpanel.track(MixPanelSignUpEvents.CHOSE_SIGN_UP_METHOD, {
        [MixPanelChoseSignUpMethodProperties.METHOD]: SignupMethods.GOOGLE
      });
    }
  };

  return (
    <div onClick={onButtonClick}>
      <GoogleLogin
        render={renderProps => (
          <button onClick={renderProps.onClick} className={styles.button} disabled={renderProps.disabled}>
            <div className={styles.buttonIcon}>
              <Icon name='google' />
            </div>
            {props.buttonText ? props.buttonText : 'Sign Up with Google'}
          </button>
        )}
        clientId={process.env.REACT_APP_GOOGLE_ID}
        buttonText='Sign In with Google'
        onSuccess={handleGoogleLogin}
        onFailure={handleGoogleLogin}
        cookiePolicy={'single_host_origin'}
      />
    </div>
  );
};

export default GoogleLoginButton;
