import classNames from 'classnames';
import React, { ReactElement } from 'react';

import Icon from '../Icon/Icon';
import styles from './Chip.module.scss';

interface ChipInterface {
  content: string;
  className?: string;
  onClear?: () => any;
}

const Chip = ({ content, onClear, className }: ChipInterface): ReactElement => (
  <div className={classNames(styles.container, onClear && styles.clearable, className)}>
    <span>{content}</span>
    {onClear && <Icon name={'xClose'} onClick={onClear} className={styles.clearIcon} />}
  </div>
);

export default Chip;
