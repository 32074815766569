import { useForm } from 'react-hook-form';
import styles from './NotificationsForm.module.css';
import { useDispatch, useSelector } from 'react-redux';
import UserService from '../../../../services/user/user.service';
import { selectUser, setUser } from '../../../../store/slices/user.slice';
import { comingSoon, requiredSettings, settingsLabels, settingsOrder } from './notifications-form.const';
import { useToast } from '../../../../hooks/useToast';
import { ToastTypes } from '../../../../enums/toast-types.enum';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { selectIsPartnersDashboard } from '../../../../store/slices/global.slice';

export const NotificationsForm = () => {
  const user = useSelector(selectUser);
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard);
  const dispatch = useDispatch();
  const { register, getValues, setError, formState: { errors } } = useForm();

  const { sendToast } = useToast();

  const userType = isPartnersDashboard ? 'partner' : 'user';

  const onSubmit = useCallback(async data => {
    const info = {
      user: {
        notification_settings: data
      }
    };

    try {
      await UserService.updateMe(info);
      const updatedUser = { ...user, notification_settings: data };
      await dispatch(setUser(cloneDeep(updatedUser)));
      sendToast({ message: 'Preference saved.', toastType: ToastTypes.SUCCESS });
    } catch (err) {
      console.error(err);
      setError('server', {
        type: 'manual',
        message: 'We have some issues connecting to our servers. Please try again later.'
      });
    }
  }, [dispatch, sendToast, setError, user]);

  const onFormChange = () => {
    onSubmit(getValues()).finally();
  };

  return (
    <>
      <form onChange={onFormChange}>
        <div className={styles.profileNotifications}>
          {settingsOrder[userType]?.map((k, n) => (
            <div key={n} className={styles.profileNotifications__group}>
              <h3 className={styles.profileNotifications__title}>{settingsLabels[userType][k[0]]?.text}</h3>
              {k[1]?.map((item, index) => (
                <div key={index} className={styles.profileNotifications__item}>
                  {requiredSettings.includes(item) ? (
                    <>
                      <label className={styles.toggleContainer}>
                        {settingsLabels[userType][k[0]][item]}
                        <input key={index} type='hidden' name={`${k[0]}.${item}`} {...register(`${k[0]}.${item}`)}
                               value />
                        <span className={styles.profileNotifications__requiredItem}>Required</span>
                      </label>
                    </>
                  ) : (
                    <label className={styles.toggleContainer}>
                      {settingsLabels[userType][k[0]][item]}
                      <input
                        key={index}
                        className={styles.toggle}
                        type='checkbox'
                        {...register(`${k[0]}.${item}`)}
                        defaultChecked={user?.notification_settings?.[k[0]]?.[item]}
                      />
                    </label>
                  )}
                </div>
              ))}
            </div>
          ))}
          {errors.server && <span>{errors.server?.message}</span>}
        </div>
      </form>
    </>
  );
};

export default NotificationsForm;
