import axios, { AxiosPromise } from 'axios';

import { ReferralCodeResponse, ReferralInterface } from './referral.interface';

const REFERRAL_API = `${process.env.REACT_APP_API_URL}/api/v2/referral`;

const axiosReferralInstance = axios.create({
  baseURL: REFERRAL_API,
  withCredentials: true
});

const createReferral = (email: string): AxiosPromise<ReferralInterface> => axiosReferralInstance.post('', { email });

const sendReminder = (referralId: number): AxiosPromise<ReferralInterface> =>
  axiosReferralInstance.post('/snooze', { referral_id: referralId });

const checkCode = (code: string): AxiosPromise<ReferralCodeResponse> =>
  axiosReferralInstance.post('/check_code', { code });

const getReferrals = (): AxiosPromise<ReferralInterface[]> => axiosReferralInstance.get('');

export default {
  createReferral,
  getReferrals,
  sendReminder,
  checkCode
};
