import { UserStatuses } from '../enums/user-statuses.enum';
import { TranslatedUserSubscription, UserInterface } from '../interfaces/user.interface';

export const translateUserSubscription = (user: UserInterface): TranslatedUserSubscription => ({
  sessionsLeft: user.sessions_left ?? 0,
  concurrency: user?.subscription?.concurrency ?? 0,
  currentSessions: user?.subscription?.currentSessions ?? 0,
  isAnnual: user?.subscription?.isAnnual,
  sessionAllowance: user.sessions_limit ?? 0,
  currentPrice: user?.subscription?.currentPrice,
  currentProduct: user?.subscription?.currentProduct,
  nextBillingDate: user?.subscription?.nextBillingDate,
  isSubscribed: user?.subscription?.isSubscribed || false,
  isTrial: user?.subscription?.isTrial || user.status === UserStatuses.TRIAL || false,
  paymentsProvider: user?.subscription?.paymentsProvider,
  subscriptionId: user?.subscription?.subscriptionId,
  planName: user?.subscription?.planName,
  tierNumber: user?.subscription?.tierNumber,
  creditsBank: user?.subscription?.creditsBank ?? 0,
  creditsBankMax: user?.subscription?.creditsBankMax ?? 0
});
