import React, { ReactElement } from 'react';

import Button from '../../../../Atoms/Button/Button';
import Icon from '../../../../Atoms/Icon/Icon';
import TimezoneSelect from '../../../../Atoms/TimezonePicker/v2/TimezoneSelect';
import { OnboardingStepInterface, Steps } from '../../OnboardingModal';
import styles from './TimezoneStep.module.scss';

const TimezoneStep = ({ goToStep, control }: OnboardingStepInterface): ReactElement => (
  <>
    <div className={styles.icon}>
      <Icon name='bigGlobe' />
    </div>
    <span className={styles.subtitle}>
      All the displayed times are based on your timezone.
      <br />
      Please select your timezone so all the calendars get
      <br />
      configured accordingly.
    </span>
    <TimezoneSelect control={control} className={styles.timezoneSelect} isNoLabel />
    <Button onClick={goToStep(Steps.PERSONA_TYPE)} value={'Next'} />
  </>
);

export default TimezoneStep;
