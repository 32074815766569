import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PaymentStatus } from '../../../enums/payment-status.enum';
import { useIsMobileResolution } from '../../../hooks/useIsMobileResolution';
import { selectBannerContent, setBannerHeight } from '../../../store/slices/global.slice';
import { selectUser } from '../../../store/slices/user.slice';
import styles from './StudioBanner.module.scss';

const billingAreaPath = '/profile#billing';

const StudioBanner = (): ReactElement => {
  const user = useSelector(selectUser, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  const bannerElement = useRef<HTMLDivElement | null>(null);
  const isMobileResolution = useIsMobileResolution();

  const { studioBanner, paymentFailedBanner } = useFlags();
  const studioBannerContent = useSelector(selectBannerContent);

  const onUpdateInfoClick = (): void => history.push(billingAreaPath);

  const isShowPaymentFailedBanner = useMemo(
    (): boolean => paymentFailedBanner && user.subscription?.lastPaymentStatus === PaymentStatus.FAILED,
    [paymentFailedBanner, user.subscription]
  );

  useEffect((): void => {
    // for unknown reason height is 1px bigger on mobile devices so we have to wrap it in setTimeout
    setTimeout((): void => {
      const height = bannerElement?.current?.scrollHeight || 0;
      dispatch(setBannerHeight(height));
    }, 100);
  }, [isShowPaymentFailedBanner, studioBanner, paymentFailedBanner, studioBannerContent, dispatch, isMobileResolution]);

  return studioBanner || isShowPaymentFailedBanner ? (
    <div className={styles.bannersContainer} ref={bannerElement}>
      {isShowPaymentFailedBanner && (
        <div className={styles.paymentFailedContainer}>
          <p>
            Your recent payment has failed. Please update your info <u onClick={onUpdateInfoClick}>here</u>.
          </p>
        </div>
      )}
      {studioBanner && studioBannerContent && (
        <div className={styles.container}>
          <div dangerouslySetInnerHTML={{ __html: studioBannerContent }} />
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default StudioBanner;
