import React from 'react';

import { config } from '../../../config/musicianConfigs';
import MusicianImage from '../../Atoms/MusicianImage/MusicianImage.js';
import styles from './MusicianCard.module.css';

export default function MusicianCard({
  id,
  avatar,
  instrument,
  name,
  musician,
  bio,
  onClick,
  externalUrl,
  musicianUrl
}) {
  return (
    <>
      <div className={styles.musician}>
        {instrument !== '' && config.offlineServices.includes(instrument) && (
          <span className={styles.musician__addonLabel}>Offline Session</span>
        )}
        {instrument !== '' && config.addonServices.join(' ').includes(instrument) && (
          <span className={styles.musician__addonLabel}>Add-on</span>
        )}

        <div className={styles.musicianIntro}>
          <div className={styles.musicianAvatar}>
            <MusicianImage id={id} avatar={avatar} name={name} width={64} height={64} altUrl={musician.image_url} />
          </div>

          <div className={styles.musicianMeta}>
            <h3 className={styles.musicianInstrument}>{instrument}</h3>
            <p className={styles.musicianName}>{name}</p>
          </div>
        </div>
        <div className={styles.musicianBio + ' ' + styles.ellipsis}>
          <span className={styles.musicianBioText} dangerouslySetInnerHTML={{ __html: bio }} />
        </div>
        <div className={styles.musicianActions}>
          {musician.slug && (
            <a href={musicianUrl} target='_blank' className={styles.musicianSecondaryButton} rel='noreferrer'>
              View profile
            </a>
          )}
          <button className={styles.musicianButton} onClick={onClick}>
            Book a session
          </button>
        </div>
      </div>
    </>
  );
}
