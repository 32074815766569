import { PaymentProviders } from '../enums/payment-providers.enum';
import { PricingPlanInterface } from '../views/payment-details/pricing-slider/pricing-slider.interface';

export const getPlanPriceIdByEnvHelper = (plan: PricingPlanInterface, paymentProvider: PaymentProviders): string => {
  const { stripeProdPriceId, stripeDevPriceId, paypalProdPriceId, paypalDevPriceId } = plan || {};
  if (paymentProvider === PaymentProviders.PAYPAL) {
    return process.env.REACT_APP_NODE_ENV === 'production' ? paypalProdPriceId : paypalDevPriceId;
  }
  return process.env.REACT_APP_NODE_ENV === 'production' ? stripeProdPriceId : stripeDevPriceId;
};
