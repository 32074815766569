import { createAsyncThunk } from '@reduxjs/toolkit';

import { ToastTypes } from '../../enums/toast-types.enum';
import { sendToastHelper } from '../../hooks/useToast';
import { ReferralDataInterface } from '../../interfaces/referral-data.interface';
import CmsService from '../../services/cms/cms.service';
import { ReferralInterface } from '../../services/referral/referral.interface';
import ReferralService from '../../services/referral/referral.service';
import { SlicesNames } from '../slices/slices-names.enum';

export const getReferrals = createAsyncThunk(
  `${SlicesNames.REFERRAL_SLICE}/getReferrals`,
  async (): Promise<ReferralInterface[]> => {
    try {
      return (await ReferralService.getReferrals()).data;
    } catch (e) {
      console.error(e);
    }
  }
);

export const createReferral = createAsyncThunk(
  `${SlicesNames.REFERRAL_SLICE}/createReferral`,
  async (email: string, { rejectWithValue }) => {
    try {
      return (await ReferralService.createReferral(email)).data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const sendReminder = createAsyncThunk(
  `${SlicesNames.REFERRAL_SLICE}/sendReminder`,
  async (referralId: number, { dispatch }) => {
    try {
      const referral = (await ReferralService.sendReminder(referralId)).data;
      sendToastHelper(dispatch)({ message: 'Invitation sent!', toastType: ToastTypes.SUCCESS });
      return referral;
    } catch (e) {
      sendToastHelper(dispatch)({ message: 'We were unable to send your invitation.', toastType: ToastTypes.DANGER });
      console.error(e);
    }
  }
);

export const getReferralData = createAsyncThunk(
  `${SlicesNames.REFERRAL_SLICE}/getReferralData`,
  async (): Promise<ReferralDataInterface> => {
    try {
      return (await CmsService.getReferralDataRequest()).data;
    } catch (e) {
      console.error(e);
    }
  }
);
