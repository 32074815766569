import React, { ReactElement } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Icon from '../Icon/Icon';
import { selectUser } from '../../../store/slices/user.slice';
import { setIsShowPlansModal, setIsShowTopUpModal } from '../../../store/slices/modals.slice';
import { ModalWarningTypes } from '../../../enums/modal-types.enum';
import { WARNING_MESSAGE } from './CreditsDisplay.const';
import styles from './CreditsDisplay.module.scss';

interface CreditsDisplayInterface {
  isMobileResolution: boolean;
}
const CreditsDisplay = ({ isMobileResolution }: CreditsDisplayInterface): ReactElement => {
  const user = useSelector(selectUser, shallowEqual);
  const dispatch = useDispatch();

  const openTopupsModal = () => {
    if (totalCredits() === 0) {
      dispatch(setIsShowTopUpModal({ isShown: true, warningType: ModalWarningTypes.WARNING, message: WARNING_MESSAGE }));
    } else {
      dispatch(setIsShowTopUpModal({ isShown: true }));
    }
  };

  const totalCredits = () => {
    try {
      return user.sessions_left + user.credits_bank;
    } catch (err) {
      console.log('--> ', err);
      return 0;
    }
  };
  return (
    <>
      {isMobileResolution ? (
        <div className={styles.mobileContent} onClick={openTopupsModal}>
          <div className={styles.mobileLeftPill}>
            Credits
            <Icon name={'squared-plus-grey'} className={styles.alertIcon} />
            {totalCredits() === 0 && (
              <Icon name={'alert-triangle'} className={styles.alertIcon} />
            )}
          </div>

          <div className={styles.creditsPill}>
            {totalCredits() === 0 && (
              <Icon name={'alert-triangle-dark'} className={styles.alertIcon} />
            )}
            <div className={styles.mobileWrapper} onClick={openTopupsModal}>
            {totalCredits()}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.content} onClick={openTopupsModal}>
          {totalCredits() === 0 && (
            <Icon name={'alert-triangle'} className={styles.alertIcon} />
          )}
          <div className={styles.wrapper} onClick={openTopupsModal}>
            {totalCredits()} credits
          </div>
          <Icon name={'squared-plus'} className={styles.alertIcon} />
        </div>
      )}
    </>
  );
};

export default CreditsDisplay;
