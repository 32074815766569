import React, { Dispatch, ReactElement, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Button_v2 from '../../../../components/Atoms/Button_v2/button';
import Icon from '../../../../components/Atoms/Icon/Icon';
import QAContainer from '../../../../components/Atoms/QAContainer/QAContainer';
import { ButtonSizes } from '../../../../enums/button-sizes.enum';
import { getReferralData } from '../../../../store/actions/referral.actions';
import { selectReferralData } from '../../../../store/slices/referral.slice';
import QAStyles from './qa.styles.module.scss';
import styles from './referral-welcome.module.scss';

interface ReferralWelcomeInterface {
  firstName: string;
  setIsReferralMode: Dispatch<boolean>;
}

const ReferralWelcome = ({ firstName, setIsReferralMode }: ReferralWelcomeInterface): ReactElement => {
  const dispatch = useDispatch();
  const referralData = useSelector(selectReferralData, shallowEqual);
  const { studioTrialsOnSignup } = useFlags();
  const faqData = referralData?.SignupFAQs?.faq;

  useEffect((): void => {
    if (!faqData) {
      dispatch(getReferralData());
    }
  }, [faqData, dispatch]);

  const onSignupButtonClick = () => {
    setIsReferralMode(false);
  };

  return (
    <>
      <h1 className={styles.header}>{'Welcome to your\nnew musical family!'}</h1>
      <div className={styles.subheader}>
        <p>{`Your friend ${firstName} has invited you to give Musiversal a try.`}</p>
        <p>{`All you need to do now is sign up and book your first session!\n
        ${studioTrialsOnSignup ? 'Your first credit is on us.' : ''} Once you become a Musiversal member,\n
        both you and ${firstName} will earn 5 credits.`}</p>
        <p>Sounds too good to be true? Wait until you hear our artists in action!</p>
      </div>
      <div className={styles.button}>
        <Button_v2
          width={'100%'}
          height={'60px'}
          buttonSize={ButtonSizes.MEDIUM}
          buttonText={'SIGN UP'}
          onClick={onSignupButtonClick}
        />
      </div>
      <div className={styles.qaContainer}>
        <div className={styles.headerContainer}>
          <Icon name={'faq'} />
          <h1>{'Frequently\nasked questions'}</h1>
        </div>
        {faqData && <QAContainer items={faqData} QAStyles={QAStyles} />}
      </div>
    </>
  );
};

export default ReferralWelcome;
