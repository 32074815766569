import axios, { AxiosPromise } from 'axios';

import { UserInterface } from '../../interfaces/user.interface';
import {
  ChangePasswordDataInterface,
  SendOnboardingDataInterface,
  UpdateUserInterface,
  UpdateBillingAddressInterface
} from './user.interface';

const USERS_API = `${process.env.REACT_APP_API_URL}/api/users`;

const BASE_API = `${process.env.REACT_APP_API_URL}/api/v2`;

export const MESSAGES_PAGE_SIZE = 1000;

const axiosBaseInstance = axios.create({
  baseURL: BASE_API,
  withCredentials: true
});

const axiosInstance = axios.create({
  baseURL: USERS_API,
  withCredentials: true
});

const getMeRequest = (): AxiosPromise<{ user: UserInterface }> => axiosInstance.get('/me');

const updateBillingAddress = (payload: UpdateBillingAddressInterface): AxiosPromise<{ user: UserInterface }> =>
  axiosBaseInstance.put('/users/billing-information', payload);

const updateMe = (user: UpdateUserInterface): AxiosPromise<{ user: UserInterface }> => axiosInstance.put('/me', user);

const uploadAvatar = (image: FormData): AxiosPromise => axiosInstance.post('/avatar', image);

const changePassword = (changePasswordData: ChangePasswordDataInterface): AxiosPromise =>
  axiosInstance.post('/password', changePasswordData);

const sendOnboardingData = (data: SendOnboardingDataInterface): AxiosPromise =>
  axiosBaseInstance.post('/onboarding', data);

export default {
  getMeRequest,
  uploadAvatar,
  updateMe,
  changePassword,
  sendOnboardingData,
  updateBillingAddress
};
