import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectSession, selectTracks, setSession } from '../../../store/slices/sessions.slice';
import { selectUser } from '../../../store/slices/user.slice';
import { selectLimitations } from '../../../store/slices/limitations.slice';
import { uploadFile } from '../../../store/actions/uploads.actions';
import { removeUpload, selectUploads } from '../../../store/slices/uploads.slice';
import { timeParserHelper } from '../../../helpers/time-parser.helper';
import SingleFileUpload from './SingleFileUpload/SingleFileUpload';
import { SessionFilesFolder } from '../../../enums/session-files-folder.enum';
import { selectIsPartnersDashboard } from '../../../store/slices/global.slice';
import Spinner from '../Spinner/Spinner';
import { UploadStatus } from '../../../enums/upload-status.enum';
import { formatBytes } from '../../../helpers/format-bytes';
import { setShowFilesModal } from '../../../store/slices/modals.slice';
import SessionsService from '../../../services/sessions/sessions.service';
import TagManager from 'react-gtm-module';
import { selectCurrentlyBookingArtistConfig } from '../../../store/slices/musicians.slice';
import { getSingleSession } from '../../../store/actions/sessions.actions';
import { SessionType } from '../../../enums/session-type.enum';
import { SessionFilesStatus } from '../../../enums/session-files-status.enum';
import { SessionStatus } from '../../../enums/session-status.enum';
import Icon from '../Icon/Icon';
import styles from './FileUploadDropzone.module.scss';

// https://react-dropzone.js.org/#section-styling-dropzone

const baseStyle = {};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const MAX_SIZE_IN_BYTES = 5368709120;

const FileUploadDropzone = ({ isAllowUpload = true }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const session = useSelector(selectSession, shallowEqual);
  const user = useSelector(selectUser, shallowEqual);
  const limitations = useSelector(selectLimitations, shallowEqual);
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);
  const artistConfig = useSelector(selectCurrentlyBookingArtistConfig, shallowEqual);

  const tracks = useSelector(selectTracks, shallowEqual);
  const uploads = useSelector(selectUploads, shallowEqual);
  const [isLoading, setIsLoading] = useState(false);

  const fileUploadRules = isPartnersDashboard ? null : artistConfig?.fileUploadRules;

  const partnerName = session?.partner.name;

  const afterPartnerUpload = useCallback(async () => {
    try {
      const { id } = session;
      await SessionsService.closeSession(id);
      await dispatch(getSingleSession(id));
    } catch (err) {
      console.error(err);
    }
  }, [session, dispatch]);

  const sessionUploads = useMemo(() =>
      uploads
        ?.filter(upload => upload.sessionId.toString() === session.id.toString())
        ?.sort((a, b) => a.uploadTimestamp < b.uploadTimestamp ? -1 : 1),
    [uploads, session.id]);

  const sessionFolderName = useMemo(() => {
    const track_id = session?.track_id;
    const sessionId = session?.id;
    const filteredTrack = tracks?.tracks?.filter(({ id }) => id === track_id);
    const title = filteredTrack?.[0]?.title;
    return title
      ? `${sessionId} - ${title} - Session for ${partnerName}`
      : `${sessionId} - Session for ${partnerName}`;
  }, [partnerName, session?.id, session?.track_id, tracks?.tracks]);

  const clearUploadsList = () => {
    const finishedUploads = uploads.filter(({ status, sessionId }) =>
      status !== UploadStatus.UPLOADING && sessionId === session.id);
    finishedUploads.forEach(({ id }) => dispatch(removeUpload(id)));
  };

  const endOfDeliveryRestriction = useMemo(() => (
    moment(session?.start_date).add(parseInt(limitations?.express_files_delivery_time), 'minutes')
  ), [session?.id, user?.id, limitations]);

  const endOfDownloadRestriction = useMemo(() => (
    moment(session.start_date).add(parseInt(limitations?.files_expiration_time), 'days')
  ), [session?.id, user?.id, limitations]);

  const onDrop = useCallback(
    async acceptedFiles => {
      setIsLoading(true);
      const payload = {
        session_id: session.id,
        files: acceptedFiles.map(({ path, type }) => ({ fileName: path, filePath: path, fileType: type }))
      };
      try {
        const { urls } = (await SessionsService.createFileUploadLink(payload)).data;
        setIsLoading(false);

        const uploadsRes = await Promise.allSettled(
          urls.map(async ({ url, key }) => {
            const fileToUpload = (acceptedFiles.filter(({ path }) => path === key) || [])[0];
            if (!fileToUpload) {
              return;
            }
            await dispatch(uploadFile({ fileToUpload, url, session }));
          })
        );

        const isUploadSuccess = uploadsRes.some(({ status }) => status === 'fulfilled');

        const sessionIdFromParams = window.location.href.split('sessions/')[1];
        const { id: sessionId } = session;
        if (isUploadSuccess && sessionId.toString() === sessionIdFromParams?.toString()) {
          await dispatch(getSingleSession(sessionId));
        }

        if (isPartnersDashboard) {
          await afterPartnerUpload();
        }
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    },
    [session, isPartnersDashboard, dispatch, afterPartnerUpload]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({ onDrop, maxSize: MAX_SIZE_IN_BYTES });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const openFileManager = () => {
    dispatch(setShowFilesModal({
      isShown: true,
      folder: isPartnersDashboard ? SessionFilesFolder.USER_FILES : SessionFilesFolder.OUTPUT
    }));
  };

  const isShowClearUploadListButton = useMemo(() => uploads.some(({ status }) =>
    status === UploadStatus.COMPLETED || status === UploadStatus.FAILED), [uploads]);

  return (
    <div className={styles.container}>
      {/* Upload Zone */}
      {isAllowUpload && <div className={styles.uploadedZone}>
        <div className={styles.fileUploadZone} {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {isLoading ? <Spinner className={styles.spinner} isBlue /> : <>
          <span className={styles.fileUploadZone__icon}>
            <svg width='24' height='22' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M23 4H12.5L9.5 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 19C0 19.7956 0.31607 20.5587 0.87868 21.1213C1.44129 21.6839 2.20435 22 3 22H21C21.7956 22 22.5587 21.6839 23.1213 21.1213C23.6839 20.5587 24 19.7956 24 19V5C24 4.73478 23.8946 4.48043 23.7071 4.29289C23.5196 4.10536 23.2652 4 23 4ZM16 14.414L13 11.414V19H11V11.414L8 14.414L6.586 13L11.293 8.293C11.4805 8.10553 11.7348 8.00021 12 8.00021C12.2652 8.00021 12.5195 8.10553 12.707 8.293L17.414 13L16 14.414Z'
                fill='#DE6139'
              />
            </svg>
          </span>
            <p className={styles.fileUploadZone__text}>Upload Files</p>
          </>}
        </div>
        {!!fileRejections?.length &&
        <span
          className={styles.tooLargeFileError}><strong>Error: </strong>Some files were larger than {formatBytes(MAX_SIZE_IN_BYTES)}</span>
        }
      </div>}

      {/* Uploaded Items */}
      {!isAllowUpload && user.has_limitations ? (
        <>
          { endOfDeliveryRestriction > moment() && (
            <div className={styles.limitationContainer}>
            <Icon name='pending-download' />
            <div className={styles.limitationContainerText}>
              Processing… (ready in {timeParserHelper(moment.duration(endOfDeliveryRestriction.diff(moment())).asMinutes().toFixed(0))})
            </div>
          </div>
          )}
          { endOfDownloadRestriction < moment() && (
            <div className={styles.limitationContainer}>
              <Icon name='folder-x' />
              <div className={styles.limitationContainerText}>
                Files expired
              </div>
            </div>
          )}
          { endOfDeliveryRestriction < moment() && endOfDownloadRestriction > moment() && (
            <>
              <div className={styles.uploadedZone__item}>
                <span className={styles.uploadedZone__itemInfo}>
                  <span className={styles.uploadedZone__itemIcon}>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M1 9H23'
                        stroke='#9CA3AF'
                        strokeWidth='2'
                        strokeMiterlimit='10'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M21 22H3C2.46957 22 1.96086 21.7893 1.58579 21.4142C1.21071 21.0391 1 20.5304 1 20V2H9L11 5H23V20C23 20.5304 22.7893 21.0391 22.4142 21.4142C22.0391 21.7893 21.5304 22 21 22Z'
                        stroke='#9CA3AF'
                        strokeWidth='2'
                        strokeMiterlimit='10'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                  <p className={styles.uploadedZone__itemName}>{sessionFolderName}</p>
                </span>

                <span className={styles.uploadedZone__itemStatus}>
                  {
                    <button
                      className={styles.uploadedZone__itemDelete}
                      onClick={openFileManager}
                    >
                      View Files
                    </button>
                  }
                </span>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className={styles.uploadedZone__item}>
            <span className={styles.uploadedZone__itemInfo}>
              <span className={styles.uploadedZone__itemIcon}>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M1 9H23'
                    stroke='#9CA3AF'
                    strokeWidth='2'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M21 22H3C2.46957 22 1.96086 21.7893 1.58579 21.4142C1.21071 21.0391 1 20.5304 1 20V2H9L11 5H23V20C23 20.5304 22.7893 21.0391 22.4142 21.4142C22.0391 21.7893 21.5304 22 21 22Z'
                    stroke='#9CA3AF'
                    strokeWidth='2'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </span>
              <p className={styles.uploadedZone__itemName}>{sessionFolderName}</p>
            </span>

            <span className={styles.uploadedZone__itemStatus}>
                  {
                    <button
                      className={styles.uploadedZone__itemDelete}
                      onClick={openFileManager}
                    >
                      View Files
                    </button>
                  }
                </span>
          </div>
        </>
      )}

      {!!sessionUploads?.length && <div className={styles.uploads}>
        {sessionUploads?.map(upload => <SingleFileUpload key={upload.id} {...upload} />)}
        {isShowClearUploadListButton &&
        <span className={styles.clearUploadsList} onClick={clearUploadsList}>Hide finished uploads</span>}
      </div>}

      {!isPartnersDashboard && isAllowUpload && (
        <div className={styles.uploadZone__instructions}>
          {fileUploadRules?.map(({ value }, index) => (
            <span key={index} className={styles.uploadZone__instructionsItem}>
                  <span className={styles.uploadZone__instructionsItemIcon}>
                    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M1 9L5 13L15 3'
                        stroke='#9CA3AF'
                        strokeWidth='2'
                        strokeMiterlimit='10'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                  <p key={index} className={styles.uploadZone__instructionsItemText}>
                    {value}
                  </p>
                </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUploadDropzone;
