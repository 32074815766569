import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AuthService from '../../../services/auth/auth.service';
import { setIsReferralCodeValid } from '../../../store/slices/global.slice';
import { resetSessions } from '../../../store/slices/sessions.slice';
import { clearUploads } from '../../../store/slices/uploads.slice';
import { resetUser } from '../../../store/slices/user.slice';

const Logout = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect((): void => {
    const logout = async (): Promise<void> => {
      await dispatch(clearUploads());
      await dispatch(resetUser());
      await dispatch(resetSessions());
      dispatch(setIsReferralCodeValid(null));
      AuthService.logout().finally();
    };
    logout().then(() => history.push('/login'));
  }, [dispatch, history]);

  return <></>;
};

export default Logout;
