import classNames from 'classnames';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { QAInterface } from '../../../interfaces/referral-data.interface';
import Icon from '../Icon/Icon';

interface QuestionAnswerInterface extends QAInterface {
  isOpened?: boolean;
  onItemOpened?: () => void;
  styles: Record<string, any>;
  bgColor: string;
}

const QuestionAnswer = ({
  id,
  Answer: answer,
  Title: title,
  isOpened,
  onItemOpened,
  styles,
  bgColor,
  customContent,
  isExpandable = true,
  elementsCount
}: QuestionAnswerInterface): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const contentElement = useRef<HTMLDivElement | null>(null);
  const [elementHeight, setElementHeight] = useState(contentElement.current?.scrollHeight + 'px');

  useEffect((): void => {
    setElementHeight(contentElement.current?.scrollHeight + 'px');
  }, [elementsCount]);

  const toggleIsOpen = (): void => {
    if (!isExpandable) {
      return;
    }
    setIsOpen(!isOpen);
    if (!isOpen) {
      onItemOpened?.();
    }
  };

  useEffect((): void => {
    setIsOpen(!!isOpened);
  }, [isOpened]);

  return (
    <div
      key={id}
      className={classNames(styles.container, isOpen && styles.isOpen, !isExpandable && styles.disabled)}
      style={{ backgroundColor: bgColor }}
    >
      <h3 className={styles.header} onClick={toggleIsOpen}>
        <span>{title}</span>
        {isExpandable && (
          <>
            {isOpen ? (
              <Icon name={'plus'} className={styles.closeIcon} />
            ) : (
              <Icon name={'plus'} className={styles.closeIconRevert} />
            )}
          </>
        )}
      </h3>
      {customContent ? (
        <div ref={contentElement} className={styles.content} style={{ maxHeight: isOpen ? elementHeight : 0 }}>
          {customContent}
        </div>
      ) : (
        <div
          ref={contentElement}
          className={styles.content}
          style={{ maxHeight: isOpen ? contentElement.current?.scrollHeight + 'px' : 0 }}
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      )}
    </div>
  );
};

export default QuestionAnswer;
