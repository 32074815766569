import { createAsyncThunk } from '@reduxjs/toolkit';

import { UserInterface } from '../../interfaces/user.interface';
import UserService from '../../services/user/user.service';
import { SlicesNames } from '../slices/slices-names.enum';

export const getMe = createAsyncThunk(
  `${SlicesNames.USER_SLICE}/getMe`,
  async (): Promise<UserInterface> => {
    try {
      return (await UserService.getMeRequest()).data.user;
    } catch (e) {
      return;
    }
  }
);
