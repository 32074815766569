import axios, { AxiosPromise } from 'axios';

import { SessionInterface } from '../../interfaces/session.interface';
import { PrebookSlotsPayloadInterface } from '../../interfaces/slot.interface';

const BASE_API = `${process.env.REACT_APP_API_URL}/api/v2/slots`;

const axiosInstance = axios.create({
  baseURL: BASE_API,
  withCredentials: true
});

const prebook = (payload: PrebookSlotsPayloadInterface): AxiosPromise<SessionInterface> =>
  axiosInstance.post('/prebook', payload);

export default {
  prebook
};
