import { SessionStatus } from '../../../../enums/session-status.enum';
import { isPartnersDashboard } from '../../../../utils/scopeLimit';

export const DEFAULT_CONFIG = {

  bookingOptions: null,
  fileUploadRules: null,
  cancellationRecreditLimit: 48,
  deliverablesOptions: {
    default: 'Stems to be imported in a different DAW',
    options: ['Stems to be imported in a different DAW']
  }
};

export const customerStatusMapping = {
  [SessionStatus.CLIENT_FILES_UPLOAD_REQUIRED]: ['isFilesRequired', 'Files upload required'],
  [SessionStatus.CLIENT_FILES_UPDATE_REQUIRED]: ['isUpdatesRequired', 'Files update required'],
  [SessionStatus.PARTNER_DELIVERABLES_READY]: ['isReadyToDownload', 'Files ready to download!'],
  [SessionStatus.CANCELLED]: ['isCanceled', 'Cancelled']
};

export const partnerStatusMapping = {
  [SessionStatus.CLIENT_FILES_UPLOAD_REQUIRED]: ['isCanceled', 'Awaiting client upload'],
  [SessionStatus.CLIENT_FILES_PENDING_REVIEW]: ['isFilesRequired', 'Pending your review'],
  [SessionStatus.CLIENT_FILES_UPDATE_REQUIRED]: ['isUpdatesRequired', 'Awaiting client update'],
  [SessionStatus.PARTNER_READY_TO_GO]: ['isReadyToGo', 'Ready to go!'],
  [SessionStatus.PARTNER_UPLOAD_REQUIRED]: ['isFilesRequired', 'Files upload required'],
  [SessionStatus.PARTNER_DELIVERABLES_READY]: ['isReadyToDownload', 'Files uploaded'],
  [SessionStatus.CANCELLED]: ['isCanceled', 'Cancelled']
};
