import cookieCutter from 'cookie-cutter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import * as queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import XRegExp from 'xregexp';

import Button_v2 from '../../components/Atoms/Button_v2/button';
import FacebookLogin from '../../components/Atoms/FacebookLogin/FacebookLogin';
import GoogleLoginButton from '../../components/Atoms/GoogleLoginButton/GoogleLoginButton';
import StyledInput from '../../components/Atoms/StyledInput/StyledInput';
import SignUpFlowNavbar from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar';
import { loginText } from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar.const';
import { ButtonSizes } from '../../enums/button-sizes.enum';
import { MixPanelSignUpEvents } from '../../enums/mixpanel-events.enum';
import { MixPanelChoseSignUpMethodProperties } from '../../enums/mixpanel-properties.enum';
import { Routes } from '../../enums/routes.enum';
import { SignupMethods } from '../../enums/signup-methods.enum';
import { SignupSteps } from '../../enums/signup-steps.enum';
import { StyledInputTypes } from '../../enums/styled-input-types.enum';
import useIsPartnerGuard from '../../guards/useIsPartnerDashboard.guard';
import { Mixpanel } from '../../helpers/mixpanel.helper';
import { SignupDataInterface } from '../../services/auth/auth.interface';
import AuthService from '../../services/auth/auth.service';
import { getMe } from '../../store/actions/user.actions';
import styles from './Signup.module.scss';
import ReferralWelcome from './components/referral-welcome/referral-welcome';
import {
  buttonSubmittingText,
  buttonText,
  orText,
  referralErrorSubheader,
  referralHeader,
  referralUsedSubheader,
  signUpHeader
} from './signup.const';
import { useCheckCodeHook } from './useCheckCode.hook';

const Signup = (): ReactElement => {
  const { loginWithFacebook, loginWithGoogle, signupFlowCcFirst, studioTrialsOnSignup } = useFlags();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    watch,
    setValue
  } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [artist, setArtist] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [timezone, setTimezone] = useState(null);
  const [referralMode, setReferralMode] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const redirectRoute = signupFlowCcFirst ? '/payment-details' : '/explore';
  const dispatch = useDispatch();

  const { isLoading, referral, referralError, referralFirstName } = useCheckCodeHook({ referralCode, setValue });

  useEffect((): void => {
    if (!referral) {
      return;
    }
    setReferralMode(true);
  }, [referral]);

  watch(['email', 'name']);

  useEffect((): void => {
    const params = queryString.parse(location.search) as { email?: string; artist?: string; code?: string };
    setArtist(params?.artist ?? '');
    setReferralCode(params?.code);
    setValue('email', params?.email ?? '');
    history.push(Routes.SIGNUP);
  }, []);

  useIsPartnerGuard();

  const redirectToPrivateArea = () => {
    setSubmitting(false);
    history.push(redirectRoute);
  };

  const onSubmit = async data => {
    setSubmitting(true);

    Mixpanel.track(MixPanelSignUpEvents.CHOSE_SIGN_UP_METHOD, {
      [MixPanelChoseSignUpMethodProperties.METHOD]: SignupMethods.EMAIL
    });

    const signUpData = {
      user: {
        name: data.name,
        email: data.email?.toLowerCase(),
        timezone,
        password: data.password
      },
      method: 'official',
      referral_code: referralCode
    } as SignupDataInterface;

    try {
      await AuthService.logout();
      const res = await AuthService.signup(signUpData);
      if (res.status === 200) {
        await dispatch(getMe());
        localStorage.setItem('timezone', timezone);
        Mixpanel.track(MixPanelSignUpEvents.COMPLETED_SIGN_UP);
        cookieCutter.set('showOnboarding', true, { httpOnly: true });

        TagManager.dataLayer({
          dataLayer: {
            event: 'createAccountEmail',
            signupType: 'user'
          }
        });

        await redirectToPrivateArea();
      }
    } catch (err) {
      if (err.response?.status === 401) {
        setSubmitting(false);
        setError('email', {
          type: 'manual',
          message: 'That email already exists in Musiversal'
        });
      } else {
        setError('server', {
          type: 'manual',
          message: 'We have some issues connecting to our servers. Please try again later.'
        });
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const tz = moment.tz.guess();
    setTimezone(tz);
  }, []);

  return (
    <div className={styles.container}>
      {!isLoading && (
        <>
          <SignUpFlowNavbar signUpStep={referralMode ? SignupSteps.WELCOME : SignupSteps.SIGN_UP} />
          <div className={styles.leftImage}>
            <div className={styles.bg} />
          </div>
          <div className={styles.rightImage}>
            <div className={styles.bg} />
          </div>
          {referralMode ? (
            <ReferralWelcome firstName={referralFirstName} setIsReferralMode={setReferralMode} />
          ) : (
            <>
              <h1>{referral || referralError ? referralHeader : signUpHeader(artist, studioTrialsOnSignup)}</h1>
              {referralError?.invalid && <p className={styles.referralErrorSubheader}>{referralErrorSubheader}</p>}
              {referralError?.isUsed && <p className={styles.referralErrorSubheader}>{referralUsedSubheader}</p>}
              <div className={styles.form}>
                {loginWithFacebook && (
                  <FacebookLogin timezone={timezone} callback={redirectToPrivateArea} setError={setError} isSignUp />
                )}
                {loginWithGoogle && (
                  <GoogleLoginButton
                    timezone={timezone}
                    callback={redirectToPrivateArea}
                    setError={setError}
                    isSignUp
                  />
                )}
                <form onSubmit={handleSubmit(onSubmit)} className={styles.formInner}>
                  {(loginWithFacebook || loginWithGoogle) && (
                    <div className={styles.divider}>
                      <span>{orText}</span>
                    </div>
                  )}
                  <StyledInput
                    width={'100%'}
                    height={'51px'}
                    label={'Email Address'}
                    type={StyledInputTypes.EMAIL}
                    name={'email'}
                    id={'email'}
                    registerRef={register('email', {
                      required: 'Required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Invalid email address'
                      }
                    })}
                    errors={[errors.email?.message]}
                    clearErrors={() => clearErrors()}
                  />
                  <StyledInput
                    width={'100%'}
                    height={'51px'}
                    label={'Full Name'}
                    type={StyledInputTypes.TEXT}
                    name={'name'}
                    id={'name'}
                    registerRef={register('name', {
                      required: 'Required',
                      pattern: {
                        // eslint-disable-next-line new-cap
                        value: XRegExp("^(?!\\s)([\\pL'-]+\\s)+([\\pL'-])+$"),
                        message: 'Your first name and last name are required'
                      }
                    })}
                    errors={[errors.name?.message]}
                    clearErrors={() => clearErrors()}
                  />
                  <StyledInput
                    width={'100%'}
                    height={'51px'}
                    label={'Password'}
                    type={StyledInputTypes.PASSWORD}
                    name={'password'}
                    id={'password'}
                    registerRef={register('password', {
                      required: 'Required',
                      minLength: {
                        value: 8,
                        message: 'Password must have at least 8 characters'
                      }
                    })}
                    errors={[errors.password?.message, errors.provider?.message, errors.server?.message]}
                    clearErrors={() => clearErrors()}
                  />
                  <div className={styles.button}>
                    <Button_v2
                      isSubmit
                      disabled={submitting}
                      width={'100%'}
                      height={'60px'}
                      buttonSize={ButtonSizes.MEDIUM}
                      buttonText={submitting ? buttonSubmittingText : buttonText}
                    />
                  </div>
                </form>

                <p className={styles.disclaimer}>
                  Signing up for a Musiversal account means you agree to the{' '}
                  <a href='https://www.musiversal.com/privacy-policy' target='_blank' rel='noreferrer'>
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href='https://www.musiversal.com/terms-of-use' target='_blank' rel='noreferrer'>
                    Terms of Use
                  </a>
                  .
                </p>

                <Link to={'/login'}>
                  <span className={styles.loginMobile}>{loginText}</span>
                </Link>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Signup;
