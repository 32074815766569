import React, { ReactElement } from 'react';

import Icon from '../Icon/Icon';
import styles from './Warning.module.scss';

interface WarningInterface {
  children: ReactElement;
}

const Warning = ({ children }: WarningInterface): ReactElement => (
  <div className={styles.externalNote}>
    <div className={styles.externalNoteIcon}>
      <Icon name='flag-diagonal' />
    </div>
    <div className={styles.externalNoteBody}>{children}</div>
  </div>
);

export default Warning;
